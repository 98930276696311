import React from "react";
import { Link } from "react-router-dom";
import { Input, Container, Row, Col } from "reactstrap";
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import footerMFIIcon from "../../assets/images/footerMFIIcon.svg";
import locationPinIcon from "../../assets/images/locationPin.png";
import mailNavbarIcon from "../../assets/images/mailIcon.svg";
import sloganWhiteIcon from "../../assets/images/sloganWhite.png";
import footerMainImageIcon from "../../assets/images/footerMainImage.png";
import footerLeftDialogueBoxIcon from "../../assets/images/footerLeftDialogueBox.png";
import footerRightDialogueBoxIcon from "../../assets/images/footerRightDialogueBox.png";
import instaIcon from "../../assets/images/instaWhite.png";
import facebookIcon from "../../assets/images/facebookWhite.png";
import twitterIcon from "../../assets/images/twitterWhite.png";
import youtubeIcon from "../../assets/images/ytWhite.png";
import linkedinIcon from "../../assets/images/linkedin.svg"
import "../styles/footer.scss";
import {subscribeNewsletter} from "../../utils/apiUtils"
import zIndex from "@mui/material/styles/zIndex";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

class Footer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email:"",
      successOpen:false,
      errorOpen: false,
    };
  }

  subscribeNewsLetter =async (e)=>{
    e.preventDefault();
    try{
      let body={"subscriberEmail":this.state.email}
      let res= await subscribeNewsletter(body)
      if(res.status==="success"){
        this.setState({successOpen:true});
      }
    } catch(err){
      this.setState({errorOpen:true});
      console.log(err);
    }
  }

  handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({
      successOpen:false,
      errorOpen:false,
    })
  };

  render() {
    return (
      <Container style={{position:'relative'}} fluid className="footer-container">
        <Row>
          <Col className="pt-3 pb-2 px-0" xs={3} sm={3} md={6} lg={4} xl={3} style={{backgroundColor:"white", borderTopRightRadius:"14px", borderBottomRightRadius:"14px"}}>
            <img className="me-4 pe-4 my-2 footer-icon" src={footerMFIIcon}></img>
          </Col>
          <Col md={12} className="my-auto mail-1">
            <div>
              <div style={{display:'inline-block', width:'46px', verticalAlign:'top'}}><img className="my-auto" src={mailNavbarIcon}></img></div>
              <a style={{display:'inline-block', width:'80%', textDecoration:"none", color:"white"}} href='mailto:contact@microfinance.ai' className="d-inline-flex">
                <p className="my-auto">contact@microfinance.ai</p>
              </a>
            </div>
          </Col>
          <Col className="my-auto footer-address-1"md={12}>
            <div className="text-left">
            <div style={{display:'inline-block', width:'46px', verticalAlign:'top'}}><img className="my-auto" src={locationPinIcon}></img> </div>
            <div style={{display:'inline-block', width:'80%'}}>Uthaan Technologies Pvt. Ltd. 202, S/F Meghdoot, Nehru Place, New Delhi DL 110019 IN  </div>
            </div>
          </Col>
          <Col className="my-auto footer-address-2" lg={6} md={12} style={{textAlign: "right", paddingRight: "6%"}}>
            <div className="mx-2">
              <img className="my-auto mx-2" src={locationPinIcon}></img> 
              Uthaan Technologies Pvt. Ltd. 202, S/F Meghdoot, Nehru Place, New Delhi DL 110019 IN  
            </div>
          </Col>
          <Col lg={3} md={12} className="my-auto mail-2" style={{textAlign:"right"}}>
            <div className="mx-2" style={{paddingRight:'2.5rem'}}>
              <img className="my-auto mx-2" src={mailNavbarIcon}></img>
              <a href='mailto:contact@microfinance.ai' className="d-inline-flex" style={{textDecoration:"none", color:"white"}}>
                <p className="my-auto">contact@microfinance.ai</p>
              </a>  
            </div>
          </Col>
        </Row>
        <Row className=" footer-row-2">
          <Col xl={3} lg={12} />
          <Col xl={9} lg={12} >
            <Row style={{marginLeft:'2rem'}} className="input-row">
              <Col xl={7} className="footer-form-1">
                <form onSubmit={this.subscribeNewsLetter}>
                  <input type="email"
                    className="px-4"
                    style={{height:"57px", width:"100%", borderRadius:"5px", border:"none"}}
                    value={this.state.email}
                    onChange={(e)=>{this.setState({email:e.target.value})}}
                    placeholder="Your E-Mail" />
                  <input type="submit" 
                    value="SUBSCRIBE"
                    className="mt-4 submit"
                    style={{backgroundColor:"#EA334D", border:"1px solid #FFFFFF", borderRadius:"5px", color:"white", height:"56px", width:"180px", boxShadow:"0px 4px 4px rgba(0, 0, 0, 0.25)"}}/>
                </form>
              </Col>
              {/* <Col xl={3}></Col> */}
              <Col xl={1}></Col>
              <Col className="made-4-bharat" xl={4} lg={12}>
                <p className="madeForBharat" style={{marginRight:'10px'}}> Made for Bharat</p>
                <Row>
                  {/* <Col lg={1} md={1} sm={1} xs={1} className="px-0"></Col> */}
                  <Col xl={4} lg={2} sm={2} xs={2} className="px-0">
                    <a href="https://www.instagram.com/vittofin/" target="_blank" rel="noopener noreferrer"><img className="socialMediaIcon" src={instaIcon} /></a>
                  </Col>
                  <Col xl={1} lg={2} sm={2} xs={2} className="px-0">
                    <a href="https://www.facebook.com/VittoFinance" target="_blank" rel="noopener noreferrer"><img className="socialMediaIcon" src={facebookIcon} /></a>
                  </Col>
                  <Col xl={2} lg={2} sm={2} xs={2} className="px-0">
                    <a href="https://twitter.com/MicrofinanceAi" target="_blank" rel="noopener noreferrer"><img className="socialMediaIcon" src={twitterIcon} /></a>
                  </Col>
                  <Col xl={2} lg={2} sm={2} xs={2} className="px-0">
                    <a href="https://www.youtube.com/channel/UC4Pm30VhwXY3-3phnkWIrVg/featured" target="_blank" rel="noopener noreferrer"><img className="socialMediaIcon" src={youtubeIcon} /></a>
                  </Col>
                  <Col xl={3} lg={2} sm={2} xs={2} className="px-0">
                    <a href="https://www.linkedin.com/company/56422789"  target="_blank" rel="noopener noreferrer" ><img src={linkedinIcon} className="socialMediaIcon linkedin"></img></a>
                  </Col>
                </Row>
              </Col>
              <Col lg={12} className="footer-form-2 pl-67">
                <form onSubmit={this.subscribeNewsLetter}>
                  <input type="email"
                    className="email-input"
                    style={{height:"57px", width:"100%", borderRadius:"5px", border:"none"}}
                    value={this.state.email}
                    onChange={(e)=>{this.setState({email:e.target.value})}}
                    placeholder="Your E-Mail" />
                  <input type="submit" 
                    value="SUBSCRIBE"
                    className="submit-btn"
                    style={{backgroundColor:"#EA334D", border:"1px solid #FFFFFF", borderRadius:"5px", color:"white", height:"56px", width:"180px", boxShadow:"0px 4px 4px rgba(0, 0, 0, 0.25)"}}/>
                </form>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="pt-4" style={{borderTop:"4px solid white", maxHeight:"60px", zIndex:9}}>
          <Col xl={4} lg={6} md={6} sm={6} xs={6} />
          <Col xl={8} lg={6} md={6} sm={6} xs={6} style={{zIndex:9}}>
            <Row className="copyright" style={{zIndex:9}}>
              <Col xl={5} lg={12} md={12} sm={12} xs={12} style={{zIndex:9}}>
                © Uthaan Technologies Pvt. Ltd. 2020 | All Rights Reserved
              </Col>
              <Col className="policy" xl={2} lg={3} md={3} sm={3} xs={4} style={{zIndex:9}}>
                <Link to="/privacy" style={{textDecoration:"none", color:"white", zIndex:9}}>
                  PRIVACY POLICY
                </Link>
              </Col>
              <Col className="policy" xl={2} lg={3} md={3} sm={3} xs={4} style={{zIndex:9}}>
                <Link to="/refund-policy" style={{textDecoration:"none", color:"white", zIndex:9}}>
                  REFUND POLICY
                </Link>
              </Col>
              <Col className="policy" xl={3} lg={3} md={5} sm={5} xs={12} style={{zIndex:9}}>
              <Link to="/terms-and-conditions" style={{textDecoration:"none", color:"white", zIndex:9}}>
                  TERMS AND CONDITIONS
                </Link>
              </Col>
            </Row>  
          </Col>
        </Row>
        <Snackbar open={this.state.successOpen} autoHideDuration={5000} onClose={this.handleClose}>
          <Alert onClose={this.handleClose} severity="success" sx={{ width: '100%' }}>
            Subscription successfull!
          </Alert>
        </Snackbar>
        <Snackbar open={this.state.errorOpen} autoHideDuration={5000} onClose={this.handleClose}>
          <Alert onClose={this.handleClose} severity="error" sx={{ width: '100%' }}>
            Please provide valid email!
          </Alert>
        </Snackbar>
        <div className="footer-pic-slogan" style={{zIndex:0}}>
          <img src={footerMainImageIcon}  style={{zIndex:0}}></img>
        </div>
      </Container>
    );
  }
}

export default Footer;
