import React from "react";
import { Container } from "reactstrap";

class PrivacyPolicyPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <Container fluid className="p-3" style={{ maxWidth: "1000px" }}>
        <center><h3><b>Privacy Policy | Vitto – Microfinance.AI</b></h3></center><br/><br/>
        <p>
          The purpose of this Privacy Policy ("Policy") is to inform the users, members, unregistered 
          visitors, etc. (hereinafter collectively referred to as "Users") of the personal information 
          and data being collected, and to protect the personal information of the Users, provided by 
          them to Vitto through the website “https://www.microfinance.ai/ / ("Website") and mobile 
          application “ Vitto ("App") (hereinafter referred to as "Platform") from time-to-time.
        </p>
        <br />
        <p>
          This Privacy Policy explains our policy regarding the collection, use, disclosure and transfer 
          of your information by Uthaan Technologies Private Limited and/or its subsidiary(ies) and/or 
          affiliate(s) (collectively referred to as the Company), which operates the website ("Site"), 
          mobile applications (Vitto App) and other services including but not limited to recording 
          credit/payment, communicating dues/balances via the site and application, any mobile or 
          internet connected device or otherwise (collectively the "Vitto App Service"). 
        </p>
        <br />
        <p>
          Vitto through this Policy aims to demonstrate its commitment towards the Information Technology
          Act, 2000 and Information Technology (Reasonable Security Practices and Procedures and 
          Sensitive Personal Data or Information), Rules, 2011 ("Rules").
        </p>
        <br/>
        <p>
          This Policy is applicable to all the information collected, received, possessed, owned, 
          controlled, stored, dealt with or handled by Vitto in respect of a User.
        </p>
        <br/>
        <ol>
          <li><b>USER ACKNOWLEDGEMENT AND CONSENT</b>
          
            <ul>
              <li>User acknowledges that by accessing the Platform, Users expressly consent and confirm to collection, maintenance, usage, handling and disclosures of User information including the sensitive personal data by Vitto in accordance with this Policy.</li>
              <li>User acknowledges that the Platform may contain links to other websites. If a User clicks on a third-party link, the User will be directed to that website. Note that these external sites are not operated by Vitto. Therefore, it is strongly advised that Users review the privacy policy of these websites/external links. Vitto has no control over and assumes no responsibility for the content, privacy policies, or practices of any third-party sites or services.</li>
              <li>Users acknowledge that they have the option not to provide or agree to the collection of information. If a User chooses not to agree with the Policy, they will not be entitled to avail financial services of Vitto.</li>
            </ul>
          </li>
          <li>
            <b>COLLECTION OF INFORMATION</b>
            <p>When you use our Platform, whether our Website or our App, we collect and store your information which is provided by you from time to time.</p>
            <br></br>
            <p>Our primary goal in doing so is to provide you a safe, efficient, smooth and customized experience financial services. This allows us to provide services and features that meets your needs, and to customize our Platform to make your experience safer and easier and to improve the services provided by us. More importantly, we collect personal information from you that we consider necessary for achieving the aforementioned purpose.</p>
            <br/>
            <p>In general, you can browse the Website of Microfinance.AI or App Vitto without telling us who you are or revealing any personal information about yourself. However, to create an account on the App or the Website, as the case may be, you must provide us with certain basic information required to provide customized services.</p>
            <br/>
            <p>Vitto collects various types of information from and about Users of the Platform, including but not limited to the following:</p>
            <ol>
              <li>Personal Data and Information collected directly 
                <ol>
                  <li><b>Personal Information</b>
                  <br/>
                    <p>Personal information includes any information concerning the personal or material circumstances of an identified or identifiable User, e.g. name, e-mail address, gender, date of birth, postal address, phone number, a unique login name, password, password validation, marital status, family details, business information and other details shared via application form or via email or via any other medium.</p>
                    <p>It can also include information such as username, account number, password and any other personally identifiable information. It includes identification documents issued by government or other authorities, and includes details of or pertaining to Aadhaar, PAN card, voter ID, and ration card. It includes your past credit history, income details, details of loans issued, or otherwise applied for through the Platform, payments and repayments thereof, bank account details and bank account statements.</p>
                    <p>While you can browse some sections of our Website without being a registered member as mentioned above, most activities (such as availing of loans and other financial services on the Website and the App) requires registration and for you to provide the above details.</p>
                    <p>We will indicate the mandatory and the optional fields against the information requested from you. You always have the option to not provide your personal information by choosing not to use a particular service or feature on the Platform.</p>
                  </li>
                  <li>
                    <b>Collection of Device Information:</b>
                    <p>The information the App collects, and how that information is used, depends on how you manage your privacy controls on your device.</p>
                    <p>When you install the App, we store the information we collect with unique identifiers tied to the device you are using. We collect information from the device when you download and install the App and explicitly seek permissions from you to get the required information from the device.</p>
                    <p>The information we collect from your device includes the unique ID i.e. IMEI number, information on operating system, SDK version and mobile network information including carrier name, SIM Serial and SIM Slot, your profile information, list of installed apps, Wi-Fi information.</p>
                    <p>We collect information about your device to undertake fraud detection, provide automatic updates and additional security so that your account is not used in other people’s devices. In addition, the information provides us valuable feedback on your identity as a device holder as well as your device behavior, thereby allowing us to improve our services and provide an enhanced customized user experience to you. You can change the device access you provide to us in your device settings. The key information collected from the device is further detailed below:</p>
                      <ul>
                        <li>
                          <b>SMS</b>
                          <p>We don’t collect your personal SMSs from your inbox.</p>
                          
                          <p>We may collect financial SMS (5-6 digit Financial SMS) from your inbox which helps us in identifying the various accounts that you are holding and the cash flow patterns that you have as a user to help us determine your risk profile and to provide you with the appropriate credit analysis to enable you to take financial facilities from us. While using the App, it periodically sends the financial SMS information to our servers.</p>

                        </li>
                        <li>
                          <b>Location</b>
                          <p>We collection location information from your device to reduce the risk associated with your account.</p>
                        </li>
                        <li>
                          <b>Contacts</b>
                          <p>As a part of our loan journey, we may require references from the loan applicant. In this regard, we may collect your contact information to detect close contacts to enable you to autofill the data during the loan application process.</p>
                        </li>
                        <li>
                          <b>Storage</b>
                          <p>We require the storage information permission to enable the App to enable you to upload photos and/or documents to complete the application form, if and as required, during your loan application journey.</p>

                        </li>
                        <li>
                          <b>List of Installed Applications</b>
                          <p>We collect this information for it to help us determine your risk profile and to provide you with the appropriate credit analysis to enable you to take financial facilities from us.</p>

                        </li>
                        <li>
                          <b>Device Information</b>
                          <p>We collect IMEI information from your device to reduce the risk associated with your account.</p>

                        </li>
                      </ul>
                  </li>
                  <li><b>Collection of Other Non-Personal Information</b>
                    <ul>
                      <li>
                        <b>Additional Information</b>
                        <p>We automatically track certain information about you based upon your behavior on our Platform. We use this information to do research on our users' demographics, interests, and behavior to better understand, protect and serve our users and improve our services. This information is compiled and analyzed on an aggregated basis.</p>
                        <p>We also collect your Internet Protocol (IP) address and the URL used by you to connect your computer to the internet, etc. This information may include the URL that you just came from (whether this URL is on our Website or not), which URL you next go to (whether this URL is on our Website or not), your computer browser information, and your IP address.</p>
                      </li>
                      <li>
                        <b>Cookies</b>
                        <p>Platform may use "cookies" as required on the Website. "Cookies" is a term generally used for small text files a web site uses to recognize repeat users, facilitate the user's ongoing access to and use of the site, allow a site to track usage behaviour and compile aggregate data that will allow content improvements and targeted advertising, preferences etc.</p>
                        <p>Cookies themselves do not personally identify the User, but it identifies User device. Generally, cookies work by assigning a unique number to the computer that has no meaning outside the assigning site.</p>
                        <p>For the purpose of this Policy Users are informed that cookies also exist within mobile applications when a browser is needed to view certain content or display an ad within the application.</p>
                        <p>Users are being made aware that Vitto cannot control the use of cookies or the resulting information by advertisers or third parties hosting data on Vitto’s Platform. If Users do not want information collected through the use of cookies, they may change the settings in the browsers that allows to deny or accept the cookie feature as per User discretion and in the manner agreed by them.</p>

                      </li>
                      <li>
                        <b>Web Beacons</b>
                        <p>The web pages of the website contain electronic images known as "web beacons", sometimes called single-pixel gifs and are used along with cookies to compile aggregated statistics to analyse how the website is used. Web beacons may also be used in some of Vitto’s emails so as to know which emails and links recipients have opened, allowing it to gauge the effectiveness of its customer communications and marketing campaigns.</p>
                      </li>
                    </ul>
                  </li>
                </ol>
              </li>
            </ol>
          </li>
          <li>
            <b>MODE OF COLLECTION OF INFORMATION</b>
            <ol>
              <li>Information that the User provides to Vitto by filling in registration / enrollment / signing up forms on the Platform and/or applying for a financial product through the Platform.</li>
              <li>Information that the User provides when Users write directly to Vitto by way of e-mail, letters, messages, etc.</li>
              <li>Information that the User provides to Vitto over telephone. Vitto may make and keep a record of the information shared by the Users with Vitto.</li>
              <li>Information that the Users provide by completing surveys, poll, sweepstakes, contest or promotion scheme;</li>
              <li>Information that the User provides merely by accessing/navigating the Platform.</li>
              <li>Information collected by Vitto through independent third-party sources.</li>
              <li>Information collected through Your mobile / hand-held device.</li>
            </ol>
          </li>
          <li>
            <b>USE OF USER INFORMATION</b>
            <p>By submitting User information to Vitto, User expressly acknowledge and consent to use of such information in a manner specified under this Policy. User information can be used for various purposes including without limitation the following:</p>
            <ol>
              <li>
                Verifying User identity;
              </li>
              <li>Facilitate User with usage of Platform;</li>
              <li>To obtain credit reports and evaluate your creditworthiness for availing the loan / facility from Vitto;</li>
              <li>For extension of loan and financial services to the User from regulated entities including but not limited to Banks, NBFC, NBFC-P2P, other financial partners, etc;</li>
              <li>To ensure compliance with all legal obligations of lending partners, vis-a -vis Know-your Customer, Prevention of Money Laundering, CKYRC requirements, etc for the purposes of facilitation of the loan / financial services;</li>
              <li>For fraud prevention and detection;</li>
              <li>To send User surveys and marketing communications that Vitto believes may be of User interest;</li>
              <li>To diagnose technical problems, provide support and help Users in addressing troubleshoot problems;</li>
              <li>To send and received communications, show advertisements, notifications and make promotional offers;</li>
              <li>To prepare reports, review and filing as per applicable laws;</li>
              <li>To contact Users regarding lenders and the various financial services being facilitated through Vitto, third party services and offers;</li>
              <li>To understand User preferences, requirements;</li>
              <li>To permit User to participate in interactive features offered through the Platform; Verifying User identity;</li>
              <li>To improve the content and protecting the integrity of the Platform;</li>
              <li>To increase / improve the products / services offered on the Platform;</li>
              <li>To ensure compliance with all applicable laws.</li>

            </ol>
          </li>
          <li>
            <b>DISCLOSURE OF INFORMATION</b>
            <p>Except as required under applicable laws or pursuant to a court / government order, any information or data shared by us with third parties shall be disclosed pursuant to a confidentiality agreement which inter alia restricts such third parties from using the said data only for the purpose of provisioning the services to us to enable us to provide Services to you and also restrict further disclosure of the information unless such disclosure is for the purpose as detailed under that confidentiality agreement. We will share your information with third parties to facilitate provisioning of services by us to you only in such manner as described below:</p>
            <ol>
              <li>We may disclose your information to our regulated partners and lenders, including but not limited to Banks, NBFC, NBFC-P2P on the Platform to facilitate the financial services for You;</li>
              <li>We may disclose your information to our third-party service providers for providing the Services as detailed under the Terms & Conditions;</li>
              <li>We may share your information with our third-party partners in order to conduct data analysis in order to serve you better and provide services on our Platform;</li>
              <li>We may disclose your information, without prior notice, if required under any law or if we are under a duty to do so in order to comply with any legal obligation or an order from the government and/or a statutory authority, or in order to enforce or apply our terms of use or assign such information in the course of corporate divestitures, mergers, or to protect the rights, property, or safety of us, our users, or others. This includes exchanging information with other companies and organizations for the purposes of fraud protection and credit risk reduction.</li>
              <li>We may also share the data / information with our technology service providers and outsourcing partners to perform credit and know your customer checks to facilitate a loan for the User as per applicable laws;</li>
              <li>We may share your data with our affiliates and/or group companies for data processing and analysis;</li>
              <li>We and our affiliates may share your information with another business entity should we (or our assets) merge with, or be acquired by that business entity, or re-organization, amalgamation, restructuring of business for continuity of business.</li>

            </ol>
          </li>
          <li>
            <b>DATA PROTECTION, RETENTION AND STORAGE</b>
            <p>Pursuant to Information and Technology Act, 2000 and Information Technology (Reasonable Security Practices and Procedures and Sensitive Personal Data or Information) Rules, 2011, Vitto endeavors to protect sensitive personal and personal information and data of the Users.</p>
            <p>You further understand and agree that repayment of a loan / financial facility does not automatically rescind the consents You have provided to us under the Privacy Policy nor does deletion of your account with the Platform.</p>
            <p>We shall store your information and/or data for such period as may be required by Vitto / financial partners to (i) enforce its legal rights and obligations against you; (ii) to ensure compliance with its obligations and responsibilities under applicable laws in specified by the Reserve Bank of India.</p>
            <p>We store and process your personal information on cloud servers. Some of the safeguards we use are firewalls and bit data encryption using SSL, and information access authorization controls. we use reasonable safeguards to preserve the integrity and security of your information against loss, theft, unauthorized access, disclosure, reproduction, use or amendment. To achieve the same, we use reasonable security practices and procedures as mandated under applicable laws for the protection of your information. Information you provide to us may be stored on our secure servers located within or outside India.</p>

          </li>
          <li>
            <b>UPDATE USER INFORMATION</b>
            <p>Users are required to update their information available with Vitto as and when there are any changes. Users are also entitled to review the information provided and ensure that any personal information or sensitive personal data or information found to be inaccurate or deficient be corrected or amended as feasible.</p>

          </li>
          <li>
            <b>INTELLECTUAL PROPERTY RIGHTS</b>
            <p>All content on the Platform, including graphics, text, icons, interfaces, audio clips, logos, images, reviews, comments and software is the property of Vitto and/or its content suppliers and is protected by Indian and international copyright laws and other applicable intellectual property laws. Any use, including the reproduction, modification, distribution, transmission, republication, display or performance, of the content on this Platform can only be made with the express written permission of Vitto. All other trademarks, brands and copyrights other than those belonging to Vitto, belong to their respective owners and are their property. Users shall not modify the paper or digital copies of any materials that are printed off or downloaded in any way on or from the Platform, and Users must not use any illustrations, photographs, video or audio sequences or any graphics separately from any accompanying text.</p>
            <p>USERS MUST NOT USE ANY PART OF THE CONTENT ON THE PLATFORM FOR COMMERCIAL PURPOSES WITHOUT OBTAINING A WRITTEN LICENCE TO DO SO FROM VITTO OR ITS LICENSORS. IF THE USER PRINTS OFF, COPY OR DOWNLOAD ANY PART OF THE PLATFORM IN BREACH OF THE TERMS HEREOF, THEIR RIGHT TO USE THE PLATFORM WILL CEASE IMMEDIATELY AND USER SHALL, AT ITS OPTION, RETURN OR DESTROY ANY COPIES OF THE MATERIALS MADE, THEREOF.</p>

          </li>
          <li>
            <b>CHANGES TO PRIVACY POLICY</b>
            <p>In the event Vitto modifies this Privacy Policy, the same will be updated on the Platform. In case of any material changes to the Policy, the Users will be notified by means of a notice on Platform prior to the change becoming effective. The Users are encouraged to periodically review this page for the latest information on Vitto's privacy practices.</p>
            <p>We may also make reasonable efforts to inform you via electronic mail. In any case, you are advised to review this Privacy Policy periodically on the Site to ensure that you are aware of the latest version.</p>

          </li>
          <li>
            <b>GRIEVANCE REDRESSAL OFFICER</b>
            <p>In accordance with the relevant provisions of the Information Technology Act, 2000 and Rules made thereunder, Vitto has designated the Grievance Officer. Users can contact the Grievance Redressal Officer with respect to any complaints or concerns regarding the handling, storage, disclosure of User Information.</p>
            <p>All queries, disputes, issues and questions regarding the Privacy Policy can be addressed at the following:</p>
            <p><b>Grievance Officer Name:</b> Poonam Goswami</p>
            <p><b>Email address:</b><a href="poonam@microfinance.ai">poonam@microfinance.ai</a></p>
            <p><b>Mob No:</b> +91 8595215061</p>
            <p>The Grievance Officer can be contacted between 10:30 a.m. to 6:00 p.m. from Monday to Friday except on public holidays.</p>
            <p>Uthaan Technologies Pvt. Ltd.</p>
            <p>202, S/F Meghdoot, Nehru Place, New Delhi DL 110019 India</p>
          </li>
        </ol>
        
        <br/>
        <br/>
        <br/>
      </Container>
    );
  }
}

export default PrivacyPolicyPage;
