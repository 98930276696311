import React from "react";
import { X } from "react-feather";
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import {contactus} from "../../utils/apiUtils"
import "../styles/contactUs.scss";
import {
  Form,
  Input,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

class OurProductDemoModal extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isProductDemoModalOpen: false,
      name:"",
      email:"",
      company:"",
      message:"",
      email:"",
      successOpen:false,
      errorOpen: false,
    };
  }

  contactUS =async (e)=>{
    e.preventDefault();
    try{
      document.getElementById("mySubmit2").disabled = true;
      document.getElementById("mySubmit2").style.backgroundColor = "#EC224E";
      let body={
        "recipients":[ "akash@uthaan.co.in","kapil@uthaan.co.in","swati@uthaan.co.in" ],
        "subject": "Contact Form",
        "body": `<div style="background-color: #f8f8f8;">
        <div style="max-width: 480px; background-color: #fff; margin: auto; position: relative;">
            <div style="padding-top: 22px; display: flex;">
                <span style="display: inline-block; padding-left: 41px; border-bottom: 4px solid #e5e5e5; font-family: 'Product Sans'; font-weight: 400; color: #EC224E; font-size: 20px;">Contact Us</span>
                <img style="height: 26px; margin-left: auto; margin-right: 9px; width: 76px;" src="https://s3.ap-south-1.amazonaws.com/microfinance.ai-website/mfiLogo.png" alt="">
            </div>
            <div style="padding-left: 40px; padding-right: 40px;">
                <p style="font-size: 18px; color: #000; margin-bottom: 0px;">Name: </p>
                <p style="font-size: 16px; color: #717171; margin-top: 0px;">${this.state.name}</p>
            </div>
            <div style="padding-left: 40px; padding-right: 40px;">
                <p style="font-size: 18px; color: #000; margin-bottom: 0px;">Email Id: </p>
                <p style="font-size: 16px; color: #717171; margin-top: 0px;">${this.state.email}</p>
            </div>
            <div style="padding-left: 40px; padding-right: 40px;">
                <p style="font-size: 18px; color: #000; margin-bottom: 0px;">Contact: </p>
                <a href="tel:${this.state.number}" style="font-size: 16px; color: #15c; margin-top: 0px;">${this.state.number}</a>
            </div>
            <div style="padding-left: 40px; padding-right: 40px;">
                <p style="font-size: 18px; color: #000; margin-bottom: 0px;">Company: </p>
                <p style="font-size: 16px; color: #717171; margin-top: 0px;">${this.state.company}</p>
            </div>
            <div style="padding-left: 40px; padding-right: 40px;">
                <p style="font-size: 18px; color: #000; margin-bottom: 0px;">Message: </p>
                <p style="font-size: 16px; color: #717171; margin-top: 0px; text-align: justify;">${this.state.message}</p>
            </div>
            <div style="height: 217px;"></div>
            <div style="height: 138px; border-right: 13px solid #EC224E;"></div>
        </div>
    </div>`,
        "isHtml": true ,
        "useCase": "mfai-web-contactUs",
      }
      let res= await contactus(body)
      if(res.status==="success"){
        this.setState({successOpen:true});
      }
      this.setState({
        company:"",
        email:"",
        message:"",
        name:"",
        number:""
      })
    } catch(err){
      document.getElementById("mySubmit2").disabled = false;
      document.getElementById("mySubmit2").style.backgroundColor = "rgb(48, 108, 181)";
      this.setState({errorOpen:true});
      console.log(err);
    }
    document.getElementById("mySubmit2").disabled = false;
  }

  handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({
      successOpen:false,
      errorOpen:false,
    })
  };

  render() {
    return (
      <Modal
        isOpen={this.props.ourProductModalIsOpen}
        toggle={this.props.productsDemoModalToggle}
        className={this.props.className}
        size={"xl"}
        centered
      >
        <Col md={8} lg={6} className="d-block m-auto">
          <ModalHeader
            toggle={this.props.productsDemoModalToggle}
            style={{ borderBottom: "0 none", fontSize: "18px" }}
            charCode={
              <div style={{ borderRadius: "10px", border: "0px" }}>
                <X />
              </div>
            }
          >
            {this.props.heading}
          </ModalHeader>
          <ModalBody className="d-flex flex-column py-0">
            <Form onSubmit={this.contactUS}>
              <Input
                className="py-2 my-3"
                type="text"
                minLength="2"
                placeholder="* Name" required
                title='Name should contain minimum 2 alphabets.'
                onInput="this.setCustomValidity('')"
                pattern="[\w]{2,}[a-zA-Z\s]{1,}"
                value={this.state.name}
                  onChange={(e)=>{this.setState({name:e.target.value})}}
                style={{ border:"2px solid #717171", borderRadius:"6px", height:"40px", fontSize:"18px"}}
              />
              <Input
                className="py-2 my-3"
                type="email"
                minLength
                placeholder="* Email Address" required
                value={this.state.email}
                onChange={(e)=>{this.setState({email:e.target.value})}}
                style={{ border:"2px solid #717171", borderRadius:"6px", height:"40px", fontSize:"18px", fontColor:"#306CB5"}}
              />
              <Input
                className="py-2 my-3"
                type="tel"
                pattern="[6-9][\d]{9}"
                title='Contact Number length should be 10 digits long and should begin with any digit between 6-9.'
                onInput="this.setCustomValidity('')"
                minLength
                placeholder="* Contact Number" required
                value={this.state.number}
                onChange={(e)=>{this.setState({number:e.target.value})}}
                style={{ border:"2px solid #717171", borderRadius:"6px", height:"40px", fontSize:"18px", fontColor:"#306CB5"}}
              />
              <Input
                className="py-2 my-3"
                type="text"              
                pattern="[\w]{1,}[a-zA-Z\d\s\.]{1,}"
                title='Company Name should begin with an alphabet and can contain only digits, alphabets, spaces and "." .'
                onInput="this.setCustomValidity('')"
                placeholder="* Company" required
                value={this.state.company}
                onChange={(e)=>{this.setState({company:e.target.value})}}
                style={{ border:"2px solid #717171", borderRadius:"6px", height:"40px", fontSize:"18px", fontColor:"#306CB5", }}
              />
              <Input
                type="text"
                id="message"
                className="my-2"
                placeholder="* Message" required
                value={this.state.message}
                onChange={(e)=>{this.setState({message:e.target.value})}}
                style={{ border:"2px solid #717171", borderRadius:"6px", height:"70px", width:"100%", fontSize:"18px",fontColor:"#306CB5"}}
              />
              <input 
                type="submit" 
                value="SUBMIT"
                id="mySubmit2"
                className="mt-5"
                style={{backgroundColor:"#EC224E", height:"56px", width:"100%", color:"white", border:"none", boxShadow:"0px 4px 4px rgba(0, 0, 0, 0.25)", borderRadius:"5px"}}
              />
            </Form>
          </ModalBody>
        </Col>
        <Snackbar open={this.state.successOpen} autoHideDuration={5000} onClose={this.handleClose}>
          <Alert onClose={this.handleClose} severity="success" sx={{ width: '100%' }}>
            Thank You for contacting us!
          </Alert>
        </Snackbar>
        <Snackbar open={this.state.errorOpen} autoHideDuration={5000} onClose={this.handleClose}>
          <Alert onClose={this.handleClose} severity="error" sx={{ width: '100%' }}>
            Please provide correct details!
          </Alert>
        </Snackbar>
      </Modal>
    );
  }
}

export default OurProductDemoModal;
