import React from "react";
import { Row } from "reactstrap";
import member1 from "../../assets/images/member1.png";
import member2 from "../../assets/images/member2.png";
import member3 from "../../assets/images/member3.png";
import member4 from "../../assets/images/member4.png";
import lnkdInBlack from "../../assets/images/lnkd-in-black.png";
import twitterBlack from "../../assets/images/twitter-icon-black.png";
import "../styles/our-team-members.scss";

class OurTeamMembers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      members: [
        {
          image: member1,
          name: "Swati Sinha",
          role: "Founder",
          institute: "LSE",
          lnkdInLink: "https://www.linkedin.com/in/swati-sinha-95304338/",
          twitterLink: null,
          comment:
            "Swati has more than 8 years of experience. She has been working for economic empowerment of women and women's equality. She led the cooperative movement in Bihar with more than 2,00,000 members.",
        },
        {
          image: member2,
          name: "Kapil Patil",
          role: "Founder",
          institute: "LSE",
          lnkdInLink: "https://www.linkedin.com/in/kapilpatil89/",
          twitterLink: "https://twitter.com/kapilpatil89",
          comment:
            "Kapil has more than 7 years of experience in Fintech, MSME, NFBC and Public Policy.",
        },
        {
          image: member3,
          name: "Ankit Prasad",
          role: "Advisor",
          institute: "IIT-D",
          lnkdInLink: "https://www.linkedin.com/in/prasadankit/",
          twitterLink: "https://twitter.com/TheBobbleGuy",
          comment:
            "Ankit comes from one of the most reputed university in India, he brings more than 10 years of his experience in building and scaling up a startup into the table.",
        },
        {
          image: member4,
          name: "Rahul Prasad",
          role: "Tech Advisor",
          institute: "SMU",
          lnkdInLink: "https://www.linkedin.com/in/therahulprasad/",
          twitterLink: null,
          comment:
            "Rahul comes from a very humble background. He has an experience of more than 10 years of building a tech startup and scaling it to very large scale.",
        },
      ],
    };
  }
  render() {
    return (
      <Row className="justify-content-evenly mt-5">
        {this.state.members.map((member, index) => {
          return (
            <div key={index} className="member-capsule">
              <div className="member-capsule-on-hover">
                <p className="mt-2 d-block mx-auto">{member.comment}</p>
                <Row className="mx-auto mt-4 mb-2">
                  <div
                    className="p-0 mx-1"
                    style={{
                      display: member.twitterLink ? "flex" : "none",
                      width: "40px",
                      height: "40px",
                    }}
                  >
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href={member.twitterLink}
                      className="d-flex"
                    >
                      <img
                        src={twitterBlack}
                        className="d-block m-auto"
                        alt="twitter"
                      />
                    </a>
                  </div>
                  <div
                    className="p-0 mx-1"
                    style={{
                      width: "40px",
                      height: "40px",
                      display: member.lnkdInLink ? "flex" : "none",
                    }}
                  >
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href={member.lnkdInLink}
                      className="d-flex"
                    >
                      <img
                        src={lnkdInBlack}
                        className="d-block m-auto"
                        alt="lnkdin"
                      />
                    </a>
                  </div>
                </Row>
              </div>
              <div className="member-capsule-without-hover">
                <img src={member.image} alt="member" />
                <p
                  className="mt-2 mb-0"
                  style={{ fontWeight: 600, fontSize: "16px", color: "black" }}
                >
                  {member.name}
                </p>
                <p className="mb-1">{member.role}</p>
                <p>{member.institute}</p>
              </div>
            </div>
          );
        })}
      </Row>
    );
  }
}

export default OurTeamMembers;
