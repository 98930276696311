import { Component } from "react";
import { Container } from "reactstrap";
import { Link } from "react-router-dom";

class CareerComponent extends Component {
  render() {
    return (
      <Container
        className="career-component-container m-0"
        style={{
          maxWidth: "320px",
          borderLeft: "3px solid #306CB5",
          paddingLeft:"10px",
          paddingRight:"0px"
        }}
        fluid
      >
        <Link to={this.props.link} style={{ textDecoration: "none" }}>
          <p className="my-2 carrerHeading">
            {this.props.careerHeading}
          </p>
        </Link>
        <p  className="careerBody" style={{ fontWeight: 600, color: "black" }}>
          {`${this.props.carrerRangeLow}-${this.props.carrerRangeHigh} years`}{" "}
          <span style={{ fontWeight: "100" }}>|</span>{" "}
          {`${this.props.carrerOpenings} Opening${
            this.props.carrerOpenings > 1 ? "s" : ""
          }`}
        </p>
        <p className="careerFooter" style={{ marginTop: "-10px", color:"#717171" }}>{`${this.props.location1} ${
          this.props.location2 ? "/ " + this.props.location2 : ""
        }`}</p>
      </Container>
    );
  }
}

export default CareerComponent;
