import axios from 'axios';
export let subscribeNewsletter = async (data) => {
  let url = `https://staging.microfinanceapi.com/notifications/api/v1/publicUsers/subscribeSubStack`;
  try {
    let response = await axios.post(url, data, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return response.data;
  } catch (err) {
    console.log('The error: ', err.response.data);
    throw err.response.data;
  }
};
export let contactus = async (data) => {
  let url = `https://staging.microfinanceapi.com/notifications/api/v1/publicUsers/sendEmail`;
  try {
    let response = await axios.post(url, data, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return response.data;
  } catch (err) {
    console.log('The error: ', err.response.data);
    throw err.response.data;
  }
  console.log(data);
};