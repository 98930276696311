import React from "react";
import { Container } from "reactstrap";

class RefundPolicyHI extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
        <Container fluid className="p-3" style={{ maxWidth: "1000px" }}>
            <div class="p-pdf_viewer_thumbnail">
<div data-page-number="1">
<div class="p-pdf_iframe__page" data-page-number="1">
<div class="textLayer">
<center><h3><b><u>REFUND POLICY - HINDI TRANSLATION</u></b></h3></center><br/><br/>
{/* <p class="p2">Uthaan Technologies Pvt. Ltd (<strong>&ldquo;UTPL&rdquo;</strong>), a company incorporated under the Companies Act, 2013, having its registered office at 202, S/F Meghdoot, Nehru Place, New Delhi - 110019, (&ldquo;<strong>Company&rdquo; or &ldquo;Our&rdquo; or &ldquo;We</strong>&rdquo;), operates <a href="http://www.microfinance.ai"><span class="s1">www.microfinance.ai</span></a> and mobile application (&ldquo;<strong>Platform</strong>&rdquo;), an intermediary between Lenders and Borrowers which requires Payment Gateway to receive EMI from our customers, who are the users of the Platform (&ldquo;<strong>User</strong>&rdquo;).</p> */}
<ol class="ol1">
<li class="li3">यह धनवापसी नीति ("धनवापसी नीति"), और (हाइपरलिंक सम्मिलित करें) पर उपलब्ध 'नियम और शर्तें', ऋणदाताओं और उधारकर्ताओं के बीच लेनदेन से संबंधित, यदि संभव हो तो, धनवापसी या रद्दीकरण के संबंध में निर्धारित प्रक्रिया और नीति को चित्रित करती है। , जिसमें प्लेटफार्म एक मध्यस्थ के रूप में कार्य करता है। उपयोगकर्ताओं को धनवापसी नीति की शर्तों को पढ़ना और समझना आवश्यक है।</li>
<li class="li3">धन-वापसी, धन-वापसी नीति के अंतर्गत निर्धारित शर्तों द्वारा नियंत्रित और उनके अधीन होगी।</li>
<li class="li3">धनवापसी नीति में इसके बाद उपयोग किए जाने वाले शब्द "व्यावसायिक दिन" का अर्थ एक दिन (सार्वजनिक अवकाश, शनिवार और रविवार को छोड़कर) होगा, जिस दिन सामान्य बैंकिंग व्यवसाय के लेन-देन के लिए नई दिल्ली में बैंक खुले होते हैं।</li>
<li class="li3">कंपनी अपने विवेक के अनुसार समय-समय पर धनवापसी नीति की शर्तों को संशोधित करने का अधिकार सुरक्षित रखती है।</li>
<li class="li3"><strong>वापसी और धनवापसी नीति की शर्तें</strong></li>
<ol type="i" class="ol2">
<li class="li3">हम धनवापसी और रद्दीकरण प्रदान नहीं करते हैं क्योंकि हम उधारदाताओं और उधारकर्ताओं के बीच मध्यस्थ हैं। इस प्रकार, हमें अपने उपयोगकर्ताओं से ईएमआई प्राप्त करने के लिए पेमेंट गेटवे की आवश्यकता होती है।</li>
<li class="li3">भुगतान गेटवे के माध्यम से एक बार भुगतान की गई राशि निम्नलिखित परिस्थितियों के अलावा वापस नहीं की जाएगी:</li>
<ol type="a" class="ol3">
<li class="li3">तकनीकी त्रुटि के कारण उपयोगकर्ता के कार्ड / बैंक खाते के कई डेबिट या तकनीकी त्रुटि के कारण एकल लेनदेन में उपयोगकर्ता के खाते से अधिक राशि डेबिट होने के कारण। ऐसे मामलों में, भुगतान गेटवे शुल्क को छोड़कर अतिरिक्त राशि उपयोगकर्ता को वापस कर दी जाएगी।</li>
<li class="li3">तकनीकी त्रुटि के कारण, उपयोगकर्ता के कार्ड/बैंक खाते पर भुगतान किया जा रहा है लेकिन लेनदेन असफल है।</li>
</ol>
<li class="li3">उपयोगकर्ता को भुगतान करते समय उत्पन्न लेनदेन संख्या और मूल भुगतान रसीद, यदि कोई हो, के साथ धनवापसी के लिए एक आवेदन करना होगा।</li>
<li class="li3">आवेदन निर्धारित प्रारूप में भेजा जाना चाहिए : <a href="mailto:contact@microfinance.ai"><span class="s1">contact@microfinance.ai</span></a>.</li>
<li class="li3">आवेदन को मैन्युअल रूप से संसाधित किया जाएगा और सत्यापन के बाद, यदि दावा वैध पाया जाता है, तो अधिक प्राप्त राशि भुगतान भागीदार द्वारा उधारकर्ता के पक्ष में इलेक्ट्रॉनिक मोड के माध्यम से वापस कर दी जाएगी और हमें दिए गए डाक पते पर पुष्टिकरण भेजा जाएगा, एक अवधि के भीतर इस तरह के दावे की प्राप्ति पर 7 कैलेंडर दिनों का। आपके बैंक की नीति के आधार पर आपके बैंक खाते में पैसा दिखने में 3-7 दिन लगेंगे।</li>
<li class="li3">कंपनी कोई जिम्मेदारी नहीं लेती है और यदि वह निम्नलिखित में से किसी एक या अधिक परिस्थितियों के कारण भुगतान तिथि पर किसी भुगतान निर्देश (निर्देशों) को प्रभावित करने में असमर्थ है तो कोई दायित्व नहीं होगा:</li>

<ol type="a" class="ol3">
<li class="li3">यदि आपके द्वारा जारी किया गया भुगतान निर्देश अधूरा, गलत, और अमान्य और विलंबित है/हैं।</li>
<li class="li3">यदि भुगतान खाते में भुगतान निर्देश (निर्देशों) में उल्लिखित राशि को कवर करने के लिए अपर्याप्त धनराशि/सीमा है।</li>
<li class="li3">यदि भुगतान खाते में उपलब्ध धनराशि किसी भी भार या शुल्क के अधीन है।</li>
<li class="li3">यदि आपका बैंक भुगतान निर्देशों का पालन करने से मना करता है या देरी करता है।</li>
<li class="li3">कंपनी के नियंत्रण से बाहर की परिस्थितियाँ (जिसमें आग, बाढ़, प्राकृतिक आपदाएँ, बैंक हड़तालें, बिजली की विफलता, सिस्टम की विफलता जैसे कंप्यूटर या टेलीफोन लाइनों का टूटना एक अप्रत्याशित कारण या बाहरी बल के हस्तक्षेप सहित, लेकिन इन्हीं तक सीमित नहीं है)</li>
<li class="li3">यदि किसी कारण से भुगतान प्रभावित नहीं होता है, तो आपको एक ई-मेल द्वारा विफल भुगतान के बारे में सूचित किया जाएगा।</li>
</ol>
<li class="li3">उपयोगकर्ता सहमत है कि कंपनी, अपने विवेकाधिकार में, किसी भी या बिना किसी कारण के, और दंड के बिना, उसके खाते (या उसके किसी भाग) को निलंबित या समाप्त कर सकती है या सेवाओं का उपयोग कर सकती है और उसके सभी या किसी भी हिस्से को हटा और त्याग सकती है। / उसका खाता, उपयोगकर्ता प्रोफ़ाइल, या उसकी प्राप्तकर्ता प्रोफ़ाइल, किसी भी समय।</li>
<li class="li3">उपयोगकर्ता इस बात से सहमत है कि कंपनी अपने विवेकाधिकार में और किसी भी समय, बिना किसी सूचना के या बिना, सेवाओं या उसके किसी भी हिस्से तक पहुंच प्रदान करना बंद कर सकती है। उपयोगकर्ता इस बात से सहमत है कि सेवाओं या उसके किसी भी खाते या उसके किसी भी हिस्से तक उसकी पहुंच को बिना किसी पूर्व सूचना के प्रभावित किया जा सकता है और यह भी सहमत है कि कंपनी ऐसी किसी भी समाप्ति के लिए उपयोगकर्ता या किसी तीसरे पक्ष के लिए उत्तरदायी नहीं होगी। किसी भी संदिग्ध, कपटपूर्ण, अपमानजनक या अवैध गतिविधि को उपयुक्त कानून प्रवर्तन अधिकारियों के पास भेजा जा सकता है। ये उपाय किसी भी अन्य उपायों के अतिरिक्त हैं जो कंपनी के पास कानून या इक्विटी में हो सकते हैं। किसी भी कारण से समाप्त होने पर, उपयोगकर्ता सेवाओं का उपयोग तुरंत बंद करने के लिए सहमत होता है।</li>
<li class="li3">कंपनी इस नीति के संबंध में प्रदान की गई इस धनवापसी नीति या सेवा से संबंधित किसी भी विवाद, विवाद, या दावे को हल करने का चुनाव कर सकती है, जो उपयोग की शर्तों में निहित विवाद समाधान खंड द्वारा शासित होगी।</li>
<li class="li3">उपयोगकर्ता रिफंड से संबंधित किसी भी प्रश्न के लिए कंपनी से संपर्क करेगा। उपयोगकर्ता संतुष्टि के हमारे लक्ष्य को ध्यान में रखते हुए, कंपनी तब ऋणदाता और उपयोगकर्ता के बीच संपर्क की सुविधा प्रदान करेगी।</li>
</ol>
</ol>
</div>
</div>
</div>
</div>
        </Container>
    );
  }
}

export default RefundPolicyHI;
