import React from "react";
import { Container } from "reactstrap";

class TermsAndConditions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <Container fluid className="p-3" style={{ maxWidth: "1000px" }}>
            <center><h3><b>Terms and Conditions | Vitto - Microfinance.AI</b></h3></center><br/><br/>
            <p>
                These terms and conditions of use (hereinafter the "Terms") establish a legally binding agreement 
                between Uthaan Technologies Pvt Ltd, having its principal place of business at 
                202, S/F Meghdoot, Nehru Place, New Delhi DL 110019 IN, (hereinafter referred to as "Microfinance.AI/Vitto", 
                which expression shall include where appropriate its affiliates, successors and assigns) and you and shall
                apply to the access and use of the website
                <a href="https://microfinance.ai"> https://www.microfinance.ai/ </a>
                the Vitto mobile application (hereinafter collectively referred to as the "App"), and the Services 
                (as defined below) rendered by Microfinance.AI/Vitto. Persons accessing or using our App or availing the 
                loan and facilities on our App (collectively the "Services") are hereinafter referred to as "Users" or "you". 
            </p>
            <br/>
            <p>
              Microfinance.AI/Vitto may, at its sole discretion and without any prior notice to the Users, amend, change or 
              otherwise modify any portion of these Terms from time to time and it is the User's responsibility to review 
              these Terms periodically for any updates/changes. Unless otherwise set out herein, amendments will be effective
              upon Microfinance.AI/Vitto posting of such updated Terms at this location or the amended policies or supplemental
              terms on the applicable Service(s). Your continued access or use of the App/Services after such posting 
              constitutes your consent to be bound by the Terms, as amended. 
            </p>
            <br/>
            <p>
              Supplemental terms may apply to certain Services, such as policies for a particular event, activity or promotion,
              and such supplemental terms will be disclosed to you in connection with the applicable Service(s). Supplemental 
              terms are in addition to, and shall be deemed a part of, these Terms for the purposes of the applicable Service(s).
              Supplemental terms shall prevail over these Terms in the event of a conflict with respect to the applicable
              Service(s).
            </p>
            <br/>
            <h><b>Eligibility</b></h>
            <br/>
            <p>
              If you are an individual, you must:
            </p>
            <ul>
              <li>Be a resident of India;</li>
              <li>Be at least 18 (eighteen) years old to agree to these Terms;</li>
              <li>Represent and warrant that you are capable of entering in to these Terms and performing the obligations set out hereunder.</li>
            </ul>
            <p>If you are a non-individual, you must: </p>
            <ul>
              <li>Be duly authorized (by such non-individual entity) to agree to these Terms;</li>
              <li>Represent and warrant that you are capable of entering in to these Terms and performing 
                  the obligations set out hereunder.</li>
            </ul>
            <br/>
            <p>
              Any use of the App by bots' or other automated tools or methods is not permitted under this Terms.
            </p>
            <br/>
            <h><b>Account Setup</b></h>
            <br/>
            <p>
              All required information is service dependent and the Company may use the above said user Information to, 
              maintain, protect, and improve the Microfinance.AI/Vitto App Services and for developing new services. 
              We may also use your phone number, email address or other personally identifiable information to send 
              commercial or marketing messages without your consent [with an option to subscribe / unsubscribe (where feasible)].
              We may, however, use your email address and phone number without further consent for non-marketing or 
              administrative purposes (such as notifying you of major changes, for customer service purposes, providing 
              information about updates to Microfinance.AI/Vitto app services etc.). 
            </p>
            <br/>
              <ol start="1">
                <li>In order to use the App and avail the Services, you must register for and maintain a service account 
                  ("Account"). Account registration requires you to submit certain information such as your name, address, 
                  mobile phone number, age and any other unique identifier that may be issued to you by Microfinance.AI/Vitto.</li>
				  <br/>
				<li>You agree to maintain accurate, complete, and up-to-date information in your Account. Your failure to 
                  maintain accurate, complete, and up-to-date Account information may result in your inability to access and use 
                  the App and/or the Services or result in Microfinance.AI/Vitto termination of this agreement with you. You are
                  responsible for all activity that occurs under your Account, and you agree to maintain the security and secrecy
                  of your Account username and password at all times. Unless otherwise permitted by Microfinance.AI/Vitto in 
                  writing, you shall only use and operate one Account. You acknowledge and agree that any misuse of your Account 
                  for reasons not attributable to Microfinance.AI/Vitto shall be to your account and you will be liable for any 
                  and all liabilities incurred as a result of such misuse.
                </li>
				<br/>
                <li>
                  You hereby consent that any information submitted by you for Account set-up may be used by 
                  Microfinance.AI/Vitto in accordance with its Privacy Policy published at https://www.microfinance.ai/ 
                  which is deemed to be part of these Terms
                </li>
				<br/>
                <li>
                  You agree and authorize Microfinance.AI/Vitto to share your information with its group companies and other 
                  third parties, in so far as required for joint marketing purposes/offering various services/report generations 
                  and/or to similar services to provide you with various value added services, in association with the Services 
                  selected by you or otherwise. You agree to receive communications through emails, telephone and/or sms, from 
                  Microfinance.AI/Vitto or its third party vendors/business partners regarding the Services/ancillary services 
                  updates, information/promotional emails and/or product announcements. In this context, you agree and consent 
                  to receive all communications at the mobile number provided (even if this mobile number is registered under 
                  DND/NCPR list under TRAI regulations), and for that purpose, you further authorize Microfinance.AI/Vitto to 
                  share/disclose the information to any third party service provider or any affiliates, group companies, their 
                  authorized agents or third party service providers.
                </li>
				<br/>
                <li>
                  You consent to allow Microfinance.AI/Vitto, its officers, representatives, consultants, affiliates and 
                  employees to access all documents uploaded, to analyse the information provided, to verify the same and to
                  use such Information to fulfil the Services. The information we will access when you access the App will 
                  include but will not be limited to credit information reports, personal information, identity documents, 
                  employment information bank statements and mobile data, we will also have the right to get access to your 
                  contacts, to use such contacts as information provided to Microfinance.AI/Vitto and to access and reach out 
                  to them in case a need arises. By applying for our Services, it will be deemed that you have consented to 
                  us accessing this information and have no objection to us contacting or following up with them on your 
                  whereabouts or to obtain other information in case you are not contactable or have changed your address after 
                  availing our Services.
                </li>
				<br/>
                <li>
                  Microfinance.AI/Vitto will retain and use your information as necessary to comply with our legal obligations, 
                  resolve disputes and enforce our agreements entered into for providing the Services and ancillary services.
                </li>
              </ol>
            <br/>
            <h><b>Customer Due Diligence Requirements</b></h>
              <ol start="1">
              <li>
                You agree and acknowledge that for undertaking any financial transaction through the App, 
                Microfinance.AI/Vitto may undertake client/customer due diligence measurers and seek mandatory information 
                required for KYC purpose which as a customer you are obliged to give, while facilitating your request of 
                loan/short term credit facility ("Advance") requirements with third party vendors/ business partners/ lender 
                partners/ banks/ financial institutions (hereinafter referred to as "Microfinance.AI/Vitto Partner") in 
                accordance with the applicable Prevention of Money Laundering Act, 2002 and Rules (as amended from time to time). 
                Microfinance.AI/Vitto may obtain sufficient information to establish, to its satisfaction or the Microfinance.AI/Vitto 
                Partner's the identity of each new customer, and the purpose of the intended nature of relationship between you 
                and the Microfinance.AI/Vitto Partner. You agree and acknowledge that Microfinance.AI/Vitto can undertake 
                enhanced due diligence measures (including any documentation), to satisfy itself relating to customer due 
                diligence requirements in line with the requirements and obligations under the applicable Prevention of Money 
                Laundering Act, 2002 and Rules.
              </li>
			  <br/>
              <li>
                You agree that Microfinance.AI/Vitto/Microfinance.AI/Vitto Partner are authorized to pull your credit report 
                from various credit bureaus including CRIF High Mark Credit Information Services, TransUnion CIBIL and similar 
                agencies.
              </li>
			  <br/>
              <li>
                Consent for HR validation : By agreeing to the terms and conditions of Microfinance.AI/Vitto You hereby give 
                your consent to Microfinance.AI/Vitto to validate current and past employment details from your employer and 
                future employers.
              </li>
              </ol>     
            <br/>
            <h><b>Service</b></h>
              <ol>
								<li>
									The Service constitutes of your access to App, the assessment by Microfinance.AI/Vitto of your credit 
									worthiness, and subject to Microfinance.AI/Vitto determining (in its sole and absolute discretion) that it 
									is appropriate, your ability to submit an application ("Credit Facility Application") for loan/short term 
									credit facility ("Advance") to a Microfinance.AI/Vitto Partner and use of such other functionalities of App 
									as permitted by Microfinance.AI/Vitto.When submitting a Credit Facility Application for an Advance on the App, 
									you agree to provide current, complete, and accurate information about yourself. If any information you provide 
									is untrue, inaccurate, not current, or incomplete, Microfinance.AI/Vitto has the right to terminate your Credit 
									Facility Application and to decline to provide any and all future use of the App. We reserve the right to 
									decline your Credit Facility Application for an Advance. For the avoidance of doubt, Microfinance.AI/Vitto is 
									under no obligation whatsoever to reveal to you (including your nominees, heirs and successors) its assessment 
									of your creditworthiness at any point in time.
								</li>
								<br/>
								<li>
									You acknowledge that Microfinance.AI/Vitto allows you to submit a Credit Facility Application on the basis of a 
									determination made by methodologies and algorithms in the App. The App undertake a dynamic evaluation of risk in 
									relation to each Credit Facility Application submitted by you based on various factors including the amount of 
									the Advance, the credit eligibility parameters and other requirements set out by the Microfinance.AI/Vitto Partner. 
									The App may undertake a separate determination in relation to each Credit Facility Application made by you. 
									Given the dynamic nature of the determination made by the App, you acknowledge that there is no assurance or 
									guarantee that Microfinance.AI/Vitto shall allow you to submit a Credit Facility Application for each and every 
									Advance that you seek to avail.
								</li>
								<br/>
								<li>
									You agree and accept that Microfinance.AI/Vitto or a Microfinance.AI/Vitto Partner may in its sole discretion, 
									by itself or through authorised persons, advocate, agencies, bureau, etc. verify any information given, check 
									credit references, employment details and obtain credit reports to determine your creditworthiness from time to 
									time.
								</li>
								<br/>
								<li>
									The Service does not guarantee that the Advance sought by you will be disbursed to you by the 
									Microfinance.AI/Vitto Partner. Microfinance.AI/Vitto will make a preliminary determination of your 
									creditworthiness in relation to every Advance sought by you and submit its determination in this regard 
									to the Microfinance.AI/Vitto Partner. The final decision on whether or not to grant you the Advance will rest 
									with Microfinance.AI/Vitto Partner and will be made using Microfinance.AI/Vitto Partner's credit decisioning 
									processes and methodologies. Microfinance.AI/Vitto Partner is free to reject any recommendation made by 
									Microfinance.AI/Vitto to it about processing of a Credit Facility Application or grant of Advance to you.
								</li>
								<br/>
								<li>
									Microfinance.AI/Vitto Partner will require you to submit further documentation, information and details as 
									required under applicable laws and its internal policies prior to taking any decision about grant of Advance 
									to you. When Microfinance.AI/Vitto Partner decides to grant an Advance to you, it shall require that you execute 
									a loan/credit facility agreement ("Loan Documentation") with it to record the terms of the arrangement.
								</li>
								<br/>
								<li>
									Repayment:You must repay the Advance along with all interest, charges and fees payable to Microfinance.AI/Vitto 
									Partner in such manner as prescribed by Microfinance.AI/Vitto Partner as and when it becomes due.
								</li>
								<br/>
								<li>
									Separate Transactions:You acknowledge that an Advance disbursed under the Loan Documentation is a distinct 
									transaction between you and Microfinance.AI/Vitto Partner, independent of Microfinance.AI/Vitto's Services. 
									Any dispute between Microfinance.AI/Vitto Partner and you with respect to any Advance disbursed to you, must 
									be directly settled between Microfinance.AI/Vitto Partner and you and that you will not include or seek to 
									include Microfinance.AI/Vitto in such dispute, failing which Microfinance.AI/Vitto shall be at liberty to take 
									any and all actions that may be available to it to protect its interests and you will be liable to indemnify 
									Microfinance.AI/Vitto for all cost, losses and damages incurred in this regard.
								</li>
							</ol>
            <br/>
            <h><b>Collection Authorisation</b></h>
            <ol>
							<li>
								You agree to allow Microfinance.AI/Vitto to send you payment reminders from time-to-time at such frequency and 
								in such manner as permissible under applicable law. You further permit Microfinance.AI/Vitto to use - 

								<ul>
									<li>any User Generated Content (as defined in Clause 8 below); and/or</li>
									
									<li>
										other information which you have granted Microfinance.AI/Vitto access to, for the purposes of enabling 
										Microfinance.AI/Vitto to send payment reminders to yourself or to other persons who can be contacted by 
										Microfinance.AI/Vitto through you whether or not such other persons are users of the App to the extent 
										permitted under applicable law.
									</li>
								</ul>
							</li>
							<br/>
							<li>
								You expressly permit Microfinance.AI/Vitto to use any or all of the information provided or generated by you for 
								Microfinance.AI/VITTOâ€™s collections agency services in respect of â€“ 
								<ul>
									<li>
										any credit facility you may have availed from any credit issuing entity, irrespective of whether or not 
										Microfinance.AI/Vitto facilitated the obtaining of such credit facility as provided for elsewhere in this 
										agreement; or 
									</li>
									<li>
										any credit facility availed of by a third party from any credit issuing agency where 
										Microfinance.AI/VITTOâ€™s analysis determines that the information you have provided Microfinance.AI/Vitto 
										will enable it to contact such person by either-
										<ul>
											<li>
												deriving contact information for such third party from the information provided by or generated by you 
												and then contacting them directly; or
											</li>
											<li>
												by contacting you and requesting if you will be willing to share contact information for such third 
												party or by requesting you to inform such third party that Microfinance.AI/Vitto is attempting to 
												contact her/him.
											</li>
										</ul>
									</li>
								</ul>
							</li>
						</ol>

            <h><b>Network Access And Devices</b></h>
            <p>
							You are responsible for obtaining the data network access necessary to use the App/Services. Your mobile 
							network's data usage rates and fees will apply if you access or use the App/Services from a wireless-enabled 
							device. You are responsible for acquiring and updating compatible hardware or devices necessary to access and 
							use the App/Services and any updates thereto. Microfinance.AI/Vitto does not guarantee that the App/Services, 
							or any portion thereof, will function on any particular hardware or devices. In addition, the App/Services may 
							be subject to malfunctions and delays inherent in the use of the internet and electronic communications.
						</p><br/>
            <h><b>License</b></h>
            <p>
							Subject to your compliance with these Terms, Microfinance.AI/Vitto grants you a limited, non-exclusive, revocable, 
							non-transferrable license in India to: (i) access and use the App through your personal computer system or other 
							mobile communication device solely in connection with your use of the Services; and (ii) access and use any content, 
							information and related materials that may be made available through the Services, in each case solely for your 
							personal, non-commercial use. Any rights not expressly granted herein are reserved by Microfinance.AI/Vitto and 
							Microfinance.AI/VITTOâ€™ licensors.
						</p><br/>
            <h><b>User Generated Content</b></h>
						<ol>
							<li>
								By sharing or submitting any content including any data and information on the App, you agree that you shall 
								be solely responsible for all content you post on the App and Microfinance.AI/Vitto shall not be responsible 
								for any content you make available on or through the App. At Microfinance.AI/Vitto`s sole discretion, such 
								content may be included in the Services and ancillary services (in whole or in part or in a modified form). 
								With respect to such content you submit or make available on the App, you grant Microfinance.AI/Vitto a 
								perpetual, irrevocable, non-terminable, worldwide, royalty-free and non-exclusive license to use, copy, 
								distribute, publicly display, modify, create derivative works, and sublicense such materials or any part 
								of such content. You agree that you are fully responsible for the content you submit.
							</li>
							<br/>
							<li>
								You are prohibited from posting or transmitting to or from this App: (i) any unlawful, threatening, libelous, 
								defamatory, obscene, pornographic, or other material or content that would violate rights of publicity and/or 
								privacy or that would violate any law; (ii) any commercial material or content (including, but not limited to, 
								solicitation of funds, advertising, or marketing of any good or services); and (iii) any material or content 
								that infringes, misappropriates or violates any copyright, trademark, patent right or other proprietary right 
								of any third party. You shall be solely liable for any damages resulting from any violation of the foregoing 
								restrictions, or any other harm resulting from your posting of content to the App.
							</li>
						</ol>
						<h><b>Limited Warranty</b></h>
						<ol>
							<li>
								You understand and acknowledge that certain risks are inherent in the transmission of information over the 
								internet. By entering in to this agreement, you have chosen to use the security measures provided by 
								Microfinance.AI/Vitto even though other security measures are available. While Microfinance.AI/Vitto uses 
								industry standard information security measures to protect the Services from viruses and malicious attacks, 
								Microfinance.AI/Vitto does not represent or guarantee that the Services will be free from loss, corruption, 
								attack, viruses, interference, hacking, or other security intrusion, and Microfinance.AI/Vitto disclaims any 
								liability relating thereto to the extent that such loss, corruption, attack, viruses, interference, hacking, 
								or other security intrusion occur despite Microfinance.AI/Vitto using the information security measures. 
								Microfinance.AI/Vitto warrants that, during the term of this agreement, Microfinance.AI/Vitto will employ 
								commercially reasonable system security measures. Except as expressly set forth in this Clause, 
								Microfinance.AI/Vitto makes no representation, warranty, covenant or agreement that its security measures will 
								be effective and neither Microfinance.AI/Vitto nor its affiliates shall have any liability for the breach of its 
								security measures, or the integrity of the systems or Microfinance.AI/VITTOâ€™s computer servers, unless caused 
								by the willful misconduct or gross negligence of its employees.
							</li>
							<br/>
							<li>
								The Services are provided to you on an "as-is" and "as available basis". Microfinance.AI/Vitto is not responsible 
								for any failure of the telecommunications network or other communications links utilised to gain access to the 
								Services. Microfinance.AI/Vitto does not represent that the Services will meet your requirements or that operation 
								of the Services will be uninterrupted or error free.
							</li>
							<br/>
							<li>
								You further acknowledge that the Service is not intended or suitable for use in situations or environments where 
								the failure or time delays of, or errors or inaccuracies in, the content, data or information provided by the 
								Service could lead to death, personal injury, or severe physical or environmental damage. 
							</li>
							<br/>
							<li>
								Microfinance.AI/Vitto AND ITS AFFILIATES, SUBSIDIARIES, OFFICERS, DIRECTORS, EMPLOYEES, AGENTS, PARTNERS AND 
								LICENSORS HEREBY DISCLAIMS AND EXPRESSLY WAIVES ALL OTHER, CONDITIONS, REPRESENTATIONS AND GUARANTEES, WHETHER 
								EXPRESS OR IMPLIED, ARISING BY LAW, CUSTOM, ORAL OR WRITTEN STATEMENTS OF Microfinance.AI/Vitto OR THIRD PARTIES 
								INCLUDING, BUT NOT LIMITED TO, ANY WARRANTY OF MERCHANTABILITY OR FITNESS FOR PARTICULAR PURPOSE OR OF ERROR-FREE 
								AND UNINTERRUPTED USE OR OF NON-INFRINGEMENT EXCEPT TO THE EXTENT EXPRESSLY PROVIDED ABOVE (LIMITED WARRANTY). 
								IN PARTICULAR, Microfinance.AI/Vitto AND ITS AFFILIATES, SUBSIDIARIES, OFFICERS, DIRECTORS, EMPLOYEES, AGENTS, PARTNERS 
								AND LICENSORS MAKE NO WARRANTY THAT (I) THE SERVICE WILL MEET YOUR REQUIREMENTS; (II) YOUR USE OF THE SERVICE WILL BE TIMELY, 
								UNINTERRUPTED, SECURE OR ERROR-FREE.
							</li>
						</ol>

            <h><b>Term And Termination</b></h>
            <ol>
							<li>
								These Terms and the right to use granted hereunder shall take effect on the date you create an account with 
								Microfinance.AI/Vitto, and shall continue in effect until either you or Microfinance.AI/Vitto terminates 
								this agreement in accordance with this Clause.
							</li>
							<br/>
							<li>
								Microfinance.AI/Vitto may terminate the agreement: 
								<ul>
									<li>
										after Microfinance.AI/Vitto gives you notice of your breach of any provision of the agreement 
										(other than your breach of its obligations under Clause 11 (Confidential Information) which breach shall 
										result in immediate termination) and which you do not cure within the specified day notice period; or
									</li>
									<li>
										Immediately for not repaying the Advance or any other charges payable by you pursuant to these Terms; or
									</li>
									<li>
										If you file for bankruptcy, become insolvent, or make an assignment for the benefit of creditors.
									</li>
									<li>
										All accrued rights and obligations of the parties shall survive any termination of these Terms and the 
										Terms shall continue to be valid till such rights and obligations are extinguished or fulfilled.
									</li>
								</ul>
							</li>
						</ol>
            <h><b>Confidential Information</b></h>
						<p>
							In course of your dealings with Microfinance.AI/Vitto, either party (Disclosing Party) may share and provide the 
							other (Receiving Party) with access to its confidential and proprietary information ("Confidential Information"). 
							Confidential Information may be disclosed either orally, visually, in writing (including graphic material) or by 
							way of consigned items. The Receiving Party agrees to take all reasonable security precautions, including 
							precautions at least as great as it takes to protect its own confidential information, to protect the secrecy 
							of the Confidential Information. Confidential Information shall be disclosed only on a need-to-know basis. 
							Except as provided herein, the parties agrees to treat the same as confidential and shall not divulge, directly 
							or indirectly, to any other person, firm, corporation, association or entity, for any purpose whatsoever, such 
							information, and shall not make use of such information, without the prior written consent of the Disclosing 
							Party. Confidential Information includes but is not limited to the Services, documentation, third party materials, 
							business plans, business forecasts, financial information, customer lists, development, design details, 
							specifications, patents, copyrights, trade secrets, proprietary information, methodologies, techniques, sketches, 
							drawings, models, inventions, know-how, processes, algorithms, software programs, and software source documents.
						</p>
						
						<br/>
						<h><b>Privacy</b></h><br/>
            <p>
							You understand that by using the Services, you consent and agree to the collection and use of certain information 
							about you and your use of the Service in accordance with Microfinance.AI/Vitto Privacy Policy and these Terms. 
							Microfinance.AI/Vitto Privacy Policy applies to all your information collected under these Terms. You further 
							understand and agree that this information may be transferred to the other countries for storage, processing and 
							use by Microfinance.AI/Vitto, its affiliates, and/or their service providers in accordance with applicable law.
						</p>
						<br/>
						<h><b>Indemnity</b></h><br/>
            <p>
							You shall defend, indemnify and hold harmless Microfinance.AI/Vitto, itâ€™s officers, directors, employees and 
							agents, from and against any and all claims, damages, obligations, losses, liabilities, debts and costs 
							(including reasonable attorneysâ€™ fees), brought against Microfinance.AI/Vitto by third parties alleging that 
						</p>
						<ul>
							<li>
								your use of and access of the Services;
							</li>
							<li>
								your violation of these Terms; 
							</li>
							<li>
								your violation of any third party right, including without limitation any copyright, property, or privacy 
								right; or 
							</li>
							<li>
							any claim that the User Generated Content you submitted caused damage.
							</li>
						</ul>
						<p>
							Your indemnification obligation under this Clause will survive termination of this Agreement and your use of the Services.
						</p>
						<br/>
						<h><b>Limitation Of Liability</b></h>
						<ul>
							<li>IN NO EVENT SHALL Microfinance.AI/Vitto BE LIABLE, WHETHER IN CONTRACT, TORT (INCLUDING NEGLIGENCE) OR OTHERWISE FOR ANY LOSS OF PROFITS, BUSINESS, CONTRACTS, OR REVENUES, LOSS OF OPERATION TIME, INCREASED COSTS OR WASTED EXPENDITURE, LOSS OF GOODWILL OR REPUTATION, SPECIAL, INDIRECT, INCIDENTAL PUNITIVE OR CONSEQUENTIAL DAMAGE OF ANY NATURE WHATSOEVER OR HOWSOEVER ARISING OUT OF THIS AGREEMENT.</li>
							<br/>
							<li>THE MAXIMUM AGGREGATE AMOUNT THAT YOU OR ANY PARTY CLAIMING THROUGH YOU CAN RECOVER FROM Microfinance.AI/Vitto AND ITS AFFILIATES, OFFICERS, EMPLOYEES, AGENTS AND PROFESSIONAL ADVISORS FOR ALL CLAIMS ARISING FROM, UNDER OR RELATING TO THIS AGREEMENT (WHETHER IN CONTRACT, TORT INCLUDING NEGLIGENCE OR OTHERWISE) WILL IN NO EVENT EXCEED SERVICE FEE CHARGED BY Microfinance.AI/Vitto</li>
						</ul>
						<h><b>Assignment</b></h>
						<p>
						You may not assign this agreement without the prior written consent of Microfinance.AI/Vitto. 
						</p>
						<br/>
						<h><b>Governing Law</b></h>
						<p>The Parties hereby agree that this agreement shall be governed by and construed in accordance with the laws of India, without regard to its conflict of laws principles. Any and all disputes that arise under this agreement shall be subject to the exclusive jurisdiction of the competent courts in GURGAON, India </p>
						<br/>
						<h><b>Force Majeure</b></h>
						<p>
							Microfinance.AI/Vitto shall not be liable to the other for failure or delay in the performance of Services, if such failure or delay is caused by strike, riot, fire, flood, natural disaster, or other similar cause beyond its control.
						</p>
						<br/>
						<h><b>Waiver</b></h>
						<p>No waiver of any breach of any provision of this agreement shall constitute a waiver of any prior, concurrent or subsequent breach of the same or any other provisions of this agreement. Further, no waiver shall be effective unless made in writing and signed by an authorised signatory of the waiving party. </p>
						<br/>
						<h><b>Severability</b></h>
						<p>If any of the terms, conditions or provisions contained in this agreement are determined by any court of competent jurisdiction to be invalid, unlawful or unenforceable to any extent, such term, condition or provision shall to that extent be severed from the remaining terms, conditions and provisions which shall continue to be valid to the fullest extent permitted by law.</p>
						<br/>
						<h><b>Notices</b></h>
						<p>All notices and other communications made or required to be given under this agreement shall be in writing and shall be deemed given upon receipt when sent through email, registered post acknowledgement due, or through personal service to the address specified below: </p>
						<br/>
						<p>If to Microfinance.AI/Vitto:</p>
						<p>Email: <a href="contact@microfinance.ai">contact@microfinance.ai</a></p>
						<p>Address: Uthaan Technologies Pvt Ltd, 202, S/F Meghdoot, Nehru Place, New Delhi DL 110019 IN </p>
						<br/>
						<h><b>Entire Agreement</b></h>
						<p>These Terms along with any supplementary terms or addendum as may be prescribed by Microfinance.AI/Vitto/Microfinance.AI/Vitto Partner constitute the entire agreement between the parties pertaining to the subject matter contained herein and any written or oral agreements existing between the parties or modifications to these Terms shall have no force or effect unless expressly agreed to in writing or acknowledged in writing by Microfinance.AI/Vitto. </p>
						<br/>
						<h><b>Questions/Grievance Redressal</b></h>
						<p>In the event you have any grievance relating to the processing of information provided by you, you may contact our Grievance Department, </p>
						<p>Email: <a href="contact@microfinance.ai">contact@microfinance.ai</a></p>			
						<p>Address: Uthaan Technologies Pvt Ltd, 202, S/F Meghdoot, Nehru Place, New Delhi DL 110019 IN </p>
            <h><u><b></b></u></h>
              <p style={{marginLeft:"30px"}}></p>
            <p style={{marginLeft:"30px"}}></p><br/>
        </Container>
    );
}
}

export default TermsAndConditions;