import React from "react";
import CareerComponent from "./components/careerComponent";
import { Container, Row, Col } from "reactstrap";
import "./styles/careers.scss";

class Careers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      careers: {
        Marketing: {
          jobs: [
            {
              careerHeading: "Content Marketing Manager",
              carrerRangeLow: "3",
              carrerRangeHigh: "5",
              carrerOpenings: "2",
              redirectionPath: "/content-marketing-manager",
              location1: "New Delhi",
              location2: "Chennai",
            },
            {
              careerHeading: "Customer Marketing and Advocacy Manager",
              carrerRangeLow: "1",
              carrerRangeHigh: "3",
              carrerOpenings: "1",
              redirectionPath: "/customer-marketing-advocacy-manager",
              location1: "New Delhi",
              location2: "Bangalore",
            },
            {
              careerHeading: "Customer Marketing and Advocacy Manager",
              carrerRangeLow: "1",
              carrerRangeHigh: "3",
              carrerOpenings: "1",
              redirectionPath: "/customer-marketing-advocacy-manager",
              location1: "New Delhi",
              location2: "Bangalore",
            },
          ],
        },
        Engineering: {
          jobs: [
            {
              careerHeading: "Android Developer",
              carrerRangeLow: "3",
              carrerRangeHigh: "5",
              carrerOpenings: "2",
              redirectionPath: "/android-developer",
              location1: "Remote",
            },
            {
              careerHeading: "Senior Android Developer",
              carrerRangeLow: "3",
              carrerRangeHigh: "5",
              carrerOpenings: "2",
              redirectionPath: "/senior-android-developer",
              location1: "Remote",
            },
            {
              careerHeading: "Backend Engineer",
              carrerRangeLow: "1",
              carrerRangeHigh: "3",
              carrerOpenings: "1",
              redirectionPath: "/backend-engineer",
              location1: "New Delhi",
              location2: "Bangalore",
            },
            {
              careerHeading: "Senior Backend Engineer",
              carrerRangeLow: "1",
              carrerRangeHigh: "3",
              carrerOpenings: "1",
              redirectionPath: "/senior-backend-engineer",
              location1: "New Delhi",
              location2: "Bangalore",
            },
            {
              careerHeading: "Data Engineer",
              carrerRangeLow: "1",
              carrerRangeHigh: "3",
              carrerOpenings: "1",
              redirectionPath: "/data-engineer",
              location1: "Remote",
              location2: "Bangalore",
            },
            {
              careerHeading: "VP Engineering",
              carrerRangeLow: "1",
              carrerRangeHigh: "3",
              carrerOpenings: "1",
              redirectionPath: "/vp-engineering",
              location1: "New Delhi",
              location2: "Bangalore",
            },
          ],
        },
      },
    };
  }
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    return (
      <Container fluid className="d-flex flex-column p-0" style={{marginTop:"300px", marginBottom:"100px", maxWidth:"1440px"}}>
        <section>
          <p className="careers-home-heading mx-5 px-5">
            Explore A Career At Microfinance.AI
          </p>
          <p className="careers-description mt-2 mx-5 px-5">
            We are on a mission to disrupt the micro-lending ecosystem by
            introducing a mobile first platform to help millions of
            borrowers out there who are not very well versed with using
            smartphones or applications with complex UI.
          </p>
          <Row className="my-5 py-5" style={{marginTop:"40px"}}>
            <Col style={{maxWidth:"350px"}}>
              <p className="weMFI mb-0" style={{marginLeft:"3rem", paddingLeft:"3rem"}}>MARKETING</p>
            </Col>
            <Col className="my-auto" style={{backgroundColor:"#306CB5", height:"6px", maxHeight:"6px"}}>
            </Col>
          </Row>
          <Row className="justify-content-between careers-row mx-5 px-5">
            {this.state.careers.Marketing.jobs.map((oppurtunity, index) => {
              return (
                <Col key={index} md={4}>
                  <CareerComponent
                    careerHeading={oppurtunity.careerHeading}
                    carrerRangeLow={oppurtunity.carrerRangeLow}
                    carrerRangeHigh={oppurtunity.carrerRangeHigh}
                    carrerOpenings={oppurtunity.carrerOpenings}
                    link={`/careers${oppurtunity.redirectionPath}`}
                    location1={oppurtunity.location1}
                    location2={oppurtunity.location2}
                  />
                </Col>
              );
            })}
          </Row>
          <br />
          <br />
          <br />
          <Row className="my-5 py-5" style={{marginTop:"40px"}}>
            <Col style={{maxWidth:"350px"}}>
              <p className="weMFI mb-0" style={{marginLeft:"3rem", paddingLeft:"3rem"}}>ENGINEERING</p>
            </Col>
            <Col className="my-auto" style={{backgroundColor:"#306CB5", height:"6px", maxHeight:"6px"}}>
            </Col>
          </Row>
          <Row className="justify-content-between careers-row mx-5 px-5 my-5 py-3">
            {this.state.careers.Engineering.jobs.map((oppurtunity, index) => {
              return (
                // <>
                //   {index > 0 ? (
                //     <hr className={index % 3 === 0 ? "d-block" : "d-none"} />
                //   ) : null}
                <Col key={index} md={4} className="my-2">
                  <CareerComponent
                    careerHeading={oppurtunity.careerHeading}
                    carrerRangeLow={oppurtunity.carrerRangeLow}
                    carrerRangeHigh={oppurtunity.carrerRangeHigh}
                    carrerOpenings={oppurtunity.carrerOpenings}
                    link={`/careers${oppurtunity.redirectionPath}`}
                    location1={oppurtunity.location1}
                    location2={oppurtunity.location2}
                  />
                </Col>
              );
            })}
          </Row>
        </section>
      </Container>
    );
  }
}

export default Careers;
