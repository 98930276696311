import React from "react";
import { Container } from "reactstrap";

class RefundPolicy extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
        <Container fluid className="p-3" style={{ maxWidth: "1000px" }}>
            <div class="p-pdf_viewer_thumbnail">
<div data-page-number="1">
<div class="p-pdf_iframe__page" data-page-number="1">
<div class="textLayer">
<center><h3><b>Refund Policy | Vitto - Microfinance.AI</b></h3></center><br/><br/>
  <p>
    Uthaan Technologies Pvt. Ltd (UTPL) does not provide refund & cancellation as we are an intermediary between Lenders and Borrowers, thus we require payment Gateway to receive EMI from our customers. 
  </p> 
  <br/>
  <ol>
    <li>
      Amount once paid through the payment gateway shall not be refunded other than in the following circumstances:
      <br/>
      <ul>
        <li>
          Multiple times debiting of Customer’s Card/Bank Account due to technical error OR Customer's account being debited with excess amount in a single transaction due to technical error. In such cases, excess amount excluding Payment Gateway charges would be refunded to the Customer. 
        </li>
        <li>Due to technical error, payment being charged on the Customer’s Card/Bank Account but the transaction is unsuccessful.</li>
      </ul> 
    </li>
    <br/>
    <li>The Customer will have to make an application for refund along with the transaction number and original payment receipt if any generated at the time of making payments.</li>
    <br/>
    <li>The application in the prescribed format should be sent to contact@microfinance.ai. </li>
    <br/>
    <li>The application will be processed manually and after verification, if the claim is found valid, the amount received in excess will be refunded by UTPL through electronic mode in favor of the borrower and confirmation sent to the mailing address given to us, within a period of 7 calendar days on receipt of such claim. It will take 3-7 days for the money to show in your bank account depending on your banks policy.</li>
    <br/>
    <li>The company assumes no responsibility and shall incur no liability if it is unable to affect any Payment Instruction(s) on the Payment Date owing to any one or more of the following circumstances:
      <ol style={{ listStyleType: "lower-alpha" }}>
        <li>If the Payment Instruction(s) issued by you is/are incomplete, inaccurate, and invalid and delayed. </li>
        <li>If the Payment Account has insufficient funds/limits to cover for the amount as mentioned in the Payment Instruction(s). </li>
        <li>If the funds available in the Payment Account are under any encumbrance or charge.</li>
        <li>If your Bank refuses or delays honoring the Payment Instruction(s).</li>
        <li>Circumstances beyond the control of Company (including, but not limited to, fire, flood, natural disasters, bank strikes, power failure, systems failure like computer or telephone lines breakdown due to an unforeseeable cause or interference from an outside force)</li>
        <li>In case the payment is not effected for any reason, you will be intimated about the failed payment by an e-mail.</li>
      </ol>
    </li>
    <br/>
    <li>User agrees that the Company, in its sole discretion, for any or no reason, and without penalty, may suspend or terminate his/her account (or any part thereof) or use of the Services and remove and discard all or any part of his/her account, user profile, or his/her recipient profile, at any time. Company may also in its sole discretion and at any time discontinue providing access to the Services, or any part thereof, with or without notice. User agrees that any termination of his/her access to the Services or any account he/she may have or portion thereof may be effected without prior notice and also agrees that Company will not be liable to User or any third party for any such termination. Any suspected, fraudulent, abusive, or illegal activity may be referred to appropriate law enforcement authorities. These remedies are in addition to any other remedies Company may have at law or in equity. Upon termination for any reason, the user agrees to immediately stop using the Services.</li>
    <br/>
    <li>The company may elect to resolve any dispute, controversy, or claim arising out of or relating to this Agreement or Service provided in connection with this Agreement by binding arbitration in accordance with the provisions of the Indian Arbitration & Conciliation Act, 1996. Any such dispute, controversy or claim shall be arbitrated on an individual basis and shall not be consolidated in any arbitration with any claim or controversy of any other party.</li>
  </ol>   
</div>
</div>
</div>
</div>
<br/><br/><br/>
        </Container>
    );
  }
}

export default RefundPolicy;
