import "./App.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import DefaultLayout from "./pages/layout";
import HomePage from "./pages/home";
import AboutUs from "./pages/aboutUs";
import Careers from "./pages/careers";
import CareerDetails from "./pages/careerDetails";
import PrivacyPolicyPage from "./pages/privacyPolicy";
import PrivacyPolicyHIPage from "./pages/privacyPolicyHI";
import LendboxAgreement from "./pages/lendboxAgreement";
import LendboxAgreementHindi from "./pages/lendboxAgreementHindi";
import PlatformPage from "./pages/thePlatform";
import ContactUs from "./pages/contactUs";
import TermsAndConditions from "./pages/termsAndConditions"
import TermsAndConditionsHI from "./pages/termsAndConditionsHI"
import RefundPolicy from "./pages/refundPolicy"
import RefundPolicyHI from "./pages/refundPolicyHI"
import React from "react";
import AOS from 'aos';
import 'aos/dist/aos.css';

class App extends React.Component {
  componentDidMount() {
    window.location.href = "https://www.vitto.money"
  }
  render() {
    AOS.init();
    return (
      <Router>
        <Switch>
          <Route path="/aboutUs">
            <DefaultLayout>
              <AboutUs />
            </DefaultLayout>
          </Route>
          <Route path="/platform">
            <DefaultLayout>
              <PlatformPage />
            </DefaultLayout>
          </Route>
          <Route path="/careers/*">
            <DefaultLayout>
              <CareerDetails />
            </DefaultLayout>
          </Route>
          <Route path="/careers">
            <DefaultLayout>
              <Careers />
            </DefaultLayout>
          </Route>
          <Route path="/contactUS">
            <DefaultLayout>
              <ContactUs />
            </DefaultLayout>
          </Route>
          <Route path="/privacy">
            <PrivacyPolicyPage />
          </Route>
          <Route path="/privacy-policy-hi-app">
            <PrivacyPolicyHIPage />
          </Route>
          <Route path="/partners/lendbox-agreement">
            <LendboxAgreement />
          </Route>
          <Route path="/partners/lendbox-agreement-hindi">
            <LendboxAgreementHindi />
          </Route>
          <Route path="/ourBlog">
            <DefaultLayout>
              <HomePage section="ourBlog" />
            </DefaultLayout>
          </Route>
          <Route path="/terms-and-conditions">
            <TermsAndConditions />
          </Route>
          <Route path="/terms-and-conditions-hi">
            <TermsAndConditionsHI />
          </Route>
          <Route path="/refund-policy">
            <RefundPolicy />
          </Route>
          <Route path="/refund-policy-hi">
            <RefundPolicyHI />
          </Route>
          <Route path="/">
            <DefaultLayout>
              <HomePage />
            </DefaultLayout>
          </Route>
        </Switch>
      </Router>
    );
  }
}

export default App;
