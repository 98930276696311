import React, {Component} from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col, Button } from "reactstrap";
import Slider from "react-slick";
import OurProductDemoModal from "./components/ourProductDemoModal";
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import {contactus} from "../utils/apiUtils"
import axios from "axios";
import LYTslogan from "../assets/images/vittosaath.svg";
import bgArrow from "../assets/images/bgArrow.svg";
import phoneMockup from "../assets/images/homepageMobile.png";
import phoneLowerText from "../assets/images/phoneLowerText.svg";
import phoneText from "../assets/images/phoneText.svg";
import loanHindiText from "../assets/images/loanHindiText.svg";
import loanTeluguText from "../assets/images/loanTeluguText.svg";
import leftDialogueBox from "../assets/images/leftDialogueBox.svg";
import rightDialogueBox from "../assets/images/rightDialogueBox.svg";
import leftMobileScreen from "../assets/images/leftMobileScreen.svg";
import leftMobileScreenText from "../assets/images/leftMobileScreenText.svg";
import rightMobileScreen from "../assets/images/rightMobileScreen.svg";
import rightMobileScreenText from "../assets/images/rightMobileScreenText.svg";
import getOnGoogle from "../assets/images/GetOnGoogle.png";
import nextIcon from "../assets/images/nextIcon.svg";
import prevIcon from "../assets/images/prevIcon.svg";
import LYTfeatures from "../assets/images/LYTfeatures.svg";
import whiteCircle from "../assets/images/whiteCircle.svg";
import step1Icon from "../assets/images/step1Icon.svg";
import step2Icon from "../assets/images/step2Icon.svg";
import step3Icon from "../assets/images/step3Icon.svg";
import rupeeSign from "../assets/images/rupeeSign.svg";
import whyLYT from "../assets/images/whyLYT.svg";
import rocketIcon from "../assets/images/rocketIcon.svg";
import inAppVideoIcon from "../assets/images/inAppVideoIcon.svg";
import simpleToUseIcon from "../assets/images/simpleToUseIcon.svg";
import minimalDocumentationIcon from "../assets/images/minimalDocumentationIcon.svg";
import tfHoursApprovalIcon from "../assets/images/24HoursApprovalIcon.svg";
import easyRepaymentsIcon from "../assets/images/easyRepaymentsIcon.svg";
import paymentReminderIcon from "../assets/images/paymentReminder.svg";
import rewardsIcon from "../assets/images/rewardsIcons.svg";
import educationalVideoIcon from "../assets/images/educationalVideo.svg";
import userImage from "../assets/images/userImage.svg"
import fiveStar from "../assets/images/fiveStar.svg"
import googlePlay from "../assets/images/googlePlay.png"
import lytLogo from "../assets/images/lyt-logo.svg";
import LYTwhite from "../assets/images/LYTwhite.svg";
import LYTapp from "../assets/images/LYTapp.png";
import groupImage1 from "../assets/images/groupimg1.png";
import groupImage2 from "../assets/images/groupimg2.png";
import groupImage3 from "../assets/images/groupimg3.png";
import groupImage4 from "../assets/images/groupimg4.png";
import assitiveIcon1 from "../assets/images/assistiveCard1.svg";
import assitiveIcon2 from "../assets/images/assistiveCard2.svg";
import assitiveIcon3 from "../assets/images/assistiveCard3.svg";
import saveTimeIcon1 from "../assets/images/saveCard1.svg";
import saveTimeIcon2 from "../assets/images/saveCard2.svg";
import saveTimeIcon3 from "../assets/images/saveCard3.svg";
import reduceIcon1 from "../assets/images/reduceCard1.svg";
import controlIcon1 from "../assets/images/controlCard1.svg";
import controlIcon2 from "../assets/images/controlCard2.svg";
import controlIcon3 from "../assets/images/controlCard3.svg";
import educationalIcon1 from "../assets/images/educationalCard1.svg";
import educationalIcon2 from "../assets/images/educationalCard2.svg";
import educationalIcon3 from "../assets/images/educationalCard3.svg";
import laptopIcon from "../assets/images/laptop.png";
import dashboardScreenshot from "../assets/images/dashboardScreenShot.png";
import liveTrackingIcon1 from "../assets/images/liveTrackingCard1.svg";
import liveTrackingIcon2 from "../assets/images/liveTrackingCard2.svg";
import liveTrackingIcon3 from "../assets/images/liveTrackingCard3.svg";
import fraudDetectionIcon1 from "../assets/images/fraudDetectionCard1.svg";
import fraudDetectionIcon2 from "../assets/images/fraudDetectionCard2.svg";
import fraudDetectionIcon3 from "../assets/images/fraudDetectionCard3.svg";
import inAppCRMIcon1 from "../assets/images/inAppCard1.svg";
import backers1 from "../assets/images/backers1.png";
import backers2 from "../assets/images/backers2.png";
import backers3 from "../assets/images/backers3.png";
import backers4 from "../assets/images/backers4.png";
import backers5 from "../assets/images/backers5.png";
import backers6 from "../assets/images/backers6.png";
import backers7 from "../assets/images/backers7.png";
import backers8 from "../assets/images/lets_venture.png"
import ourPartnersIcon1 from "../assets/images/ourPartners1.png";
import ourPartnersIcon2 from "../assets/images/ourPartners2.png";
import ourPartnersIcon3 from "../assets/images/ourPartners3.png";
import ourPartnersIcon4 from "../assets/images/indusFortune.jpg";
import ourPartnersIcon5 from "../assets/images/aspl.jpeg";
import featuredInIcon1 from "../assets/images/featuredIn1.png";
import featuredInIcon2 from "../assets/images/featuredIn2.png";
import featuredInIcon3 from "../assets/images/featuredIn3.png";
import featuredInIcon4 from "../assets/images/featuredIn4.png";
import featuredInIcon5 from "../assets/images/featuredIn5.png";
import featuredInIcon6 from "../assets/images/inc42.svg"
import ourFoundersIcon1 from "../assets/images/ourFounders1.png";
import ourFoundersIcon2 from "../assets/images/ourFounders2.png";
import ourFoundersIcon3 from "../assets/images/ourFounders3.png";
import ourFoundersIcon4 from "../assets/images/ourFounders4.png";
import blueLinkedinIcon from "../assets/images/blueLinkedin.png"
import redTwitterIcon from "../assets/images/redTwitter.png"
import MDigiFinTalkIcon from "../assets/images/MDigiFinTalk.png"
import FintechIcon from "../assets/images/Fintech.png"
import { width } from "@mui/system";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function addScrollToTop() {
  const showOnPx = 700;
  const backToTopButton = document.getElementById("back-to-top");
  const pageProgressBar = document.getElementById("progress-bar");
  let scrollButtonProgress = document.getElementById("scroll-button-progress")
  // console.log(pageProgressBar, backToTopButton)
  const scrollContainer = () => {
    return document.documentElement || document.body;
  };

  const goToTop = () => {
    document.body.scrollIntoView({
      behavior: "smooth"
    });
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0;
  };

  document.addEventListener("scroll", () => {
    // console.log("Scroll Height: ", scrollContainer().scrollHeight);
    // console.log("Client Height: ", scrollContainer().clientHeight);

    const scrolledPercentage =
      (scrollContainer().scrollTop /(scrollContainer().scrollHeight - scrollContainer().clientHeight)) * 100;

    pageProgressBar.style.width = `${scrolledPercentage}%`;

    if (scrollContainer().scrollTop > showOnPx) {
      scrollButtonProgress.classList.remove("hidden");
    } else {
      scrollButtonProgress.classList.add("hidden");
    }
  });

  backToTopButton.addEventListener("click", goToTop);
}

const settings = {
  dots: true,
  infinite: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 8000,
  rtl: true
};

const settings_ = {
  // dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  initialSlide: 0,
  autoplay: true,
  autoplaySpeed: 10000,
  responsive: [
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        initialSlide: 0,
        infinite: true,
      }
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        initialSlide: 0,
        infinite: true,
      }
    }
  ]
};

class HomePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isProductDemoModalOpen: false,
      name:"",
      email:"",
      company:"",
      message:"",
      number:"",
      email:"",
      successOpen:false,
      errorOpen: false,
      blogs: '',
    };
  }

  contactUS =async (e)=>{
    e.preventDefault();
    try{
      document.getElementById("mySubmit").disabled = true;
      document.getElementById("mySubmit").style.backgroundColor = "#EC224E";
      let body={
        "recipients":[ "akash@uthaan.co.in","kapil@uthaan.co.in","swati@uthaan.co.in" ],
        "subject": "Contact Form",
        "body": `<div style="background-color: #f8f8f8;">
        <div style="max-width: 480px; background-color: #fff; margin: auto; position: relative;">
            <div style="padding-top: 22px; display: flex;">
                <span style="display: inline-block; padding-left: 41px; border-bottom: 4px solid #e5e5e5; font-family: 'Product Sans'; font-weight: 400; color: #EC224E; font-size: 20px;">Contact Us</span>
                <img style="height: 26px; margin-left: auto; margin-right: 9px; width: 76px;" src="https://s3.ap-south-1.amazonaws.com/microfinance.ai-website/mfiLogo.png" alt="">
            </div>
            <div style="padding-left: 40px; padding-right: 40px;">
                <p style="font-size: 18px; color: #000; margin-bottom: 0px;">Name: </p>
                <p style="font-size: 16px; color: #717171; margin-top: 0px;">${this.state.name}</p>
            </div>
            <div style="padding-left: 40px; padding-right: 40px;">
                <p style="font-size: 18px; color: #000; margin-bottom: 0px;">Email Id: </p>
                <p style="font-size: 16px; color: #717171; margin-top: 0px;">${this.state.email}</p>
            </div>
            <div style="padding-left: 40px; padding-right: 40px;">
                <p style="font-size: 18px; color: #000; margin-bottom: 0px;">Contact: </p>
                <a href="tel:${this.state.number}" style="font-size: 16px; color: #15c; margin-top: 0px;">${this.state.number}</a>
            </div>
            <div style="padding-left: 40px; padding-right: 40px;">
                <p style="font-size: 18px; color: #000; margin-bottom: 0px;">Company: </p>
                <p style="font-size: 16px; color: #717171; margin-top: 0px;">${this.state.company}</p>
            </div>
            <div style="padding-left: 40px; padding-right: 40px;">
                <p style="font-size: 18px; color: #000; margin-bottom: 0px;">Message: </p>
                <p style="font-size: 16px; color: #717171; margin-top: 0px; text-align: justify;">${this.state.message}</p>
            </div>
            <div style="height: 217px;"></div>
            <div style="height: 138px; border-right: 13px solid #EC224E;"></div>
        </div>
    </div>`,
        "isHtml": true ,
        "useCase": "mfai-web-contactUs",
      }
      let res= await contactus(body)
      if(res.status==="success"){
        this.setState({successOpen:true});
      }
    } catch(err){
      document.getElementById("mySubmit").disabled = false;
      document.getElementById("mySubmit").style.backgroundColor = "#EC224E";
      this.setState({errorOpen:true});
      console.log(err);
    }
    document.getElementById("mySubmit").disabled = false;
  }

  handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({
      successOpen:false,
      errorOpen:false,
    })
  };

  componentDidMount() {
    axios.get('https://api.rss2json.com/v1/api.json?rss_url=https://microfinanceai.medium.com/feed/')
    .then((response)=> {
      // console.log(response);
      var i=0
      const blogsItems = response.data.items.map((post)=> {
        return (
          <div className="blog-col">
            <div className="blog-card" style={{background: `url(${post.thumbnail})`}}>
              <div className="blog-button-wrap">
                <button className="blog-redirect-button" onClick={(e)=>{window.open(post.link, '_blank')}} ><span style={{display:'inline-block', width: '234px'}}>{post.title.substring(0,50)+'...'}</span><img style={{display:'inline-block', verticalAlign:'top', marginLeft:'4px'}} src={nextIcon}/></button>
              </div>
            </div>
          </div>
          )
      })
      this.setState({
        blogs: <Slider className="blogs-row" {...settings_}>{blogsItems}</Slider>
      })
      console.log(this.state.blogs);
    })
    .catch((err)=>{
      console.log(err);
    })
    if(window.location.pathname.length > 1){
      const sectionID= window.location.pathname.slice(1, window.location.pathname.length);
      if(sectionID=="ourBlog"){
        const anchor = document.querySelector(`#${sectionID}`)
        anchor.scrollIntoView({ behavior: 'smooth', block: 'center' })
      }
    }
    let pastPathName=window.location.pathname;
    setInterval(() => {
      let currPathName=window.location.pathname;
      if(! (pastPathName===currPathName)){
        if(window.location.pathname.length > 1){
          const sectionID= window.location.pathname.slice(1, window.location.pathname.length);
          if(sectionID=="ourBlog"){
            const anchor = document.querySelector(`#${sectionID}`)
            anchor.scrollIntoView({ behavior: 'smooth', block: 'center' })
          }
        }
        else if(currPathName==="/"){
          //const anchor = document.querySelector(`#top`)
          //anchor.scrollIntoView({ behavior: 'smooth', block: 'center' })
          window.scrollTo(0,0);
        }
        pastPathName=currPathName;
      }
    }, 300);
  }

  productsDemoModalToggle = () =>
    this.setState(
      {
        isProductDemoModalOpen: !this.state.isProductDemoModalOpen,
      },
      () => {
        console.log("isOpen: ", this.state.isProductDemoModalOpen);
      }
    );

  render() {
    return (
      <Container fluid className="px-0" onLoad={()=>addScrollToTop()} >
        <div className="scroll-button-progress my-0 py-0 hidden" id="scroll-button-progress">
          <button className="back-to-top" id="back-to-top">Scroll To Top</button>
          <div className="full-bar">
            <div className="progress-bar" id="progress-bar"></div>
          </div>
        </div>
        <section id="top" className="home-page-section " style={{boxShadow:"0px 4px 0px rgba(0, 0, 0, 0.25)", padingBottom:"284px", background:"#F8F8F8"}}>
          <Row className="home-page-main-row container p-0 mx-0" style={{paddingTop:"150px", marginLeft:"20px"}}>
            <Col className="pr-4 vitto-logo" xl={7} sm={12}>
              <img style={{maxWidth:'100%', width:'80%'}} src={LYTslogan}></img>
              <Row className="px-3 mt-3">
                <a className="d-inline-flex p-0" href="https://play.google.com/store/apps/details?id=ai.microfinance.lyt" style={{width:"auto"}} target="_blank" rel="noopener noreferrer"> 
                  <img src={getOnGoogle} style={{maxWidth:"278px", height:"82px", marginLeft:"-30px"}} className="download-button"></img> 
                </a>
                <p className="d-inline-flex or my-auto" style={{width:"auto"}}>OR</p>
                <Link to="/contactUS" className="d-inline-flex contact-us my-auto" style={{width:"199px", height:"56px", fontSize:"10px", textDecoration:"none"}}>
                  <Button className="partnerBtn m-auto" style={{width:"179px", justifyContent:"right", boxShadow:"0px 4px 4px rgba(0, 0, 0, 0.25)", zIndex:1}}>CONNECT WITH US</Button>
                </Link>
              </Row>
            </Col>
            <Col xl={5} sm={12} className="p-0 d-flex justify-content-center phone-logo-div" data-aos="fade-left"
            data-aos-anchor="#example-anchor"
            data-aos-offset="700"
            data-aos-duration="2000">
              <img src={bgArrow} className="arrow" style={{position:'absolute' ,right:'0'}}></img>
              <img src={phoneMockup} className="phone-mockup"></img>
            </Col>
          </Row>
        </section>
        <div className="threeSteps container p-5" style={{position:"relative", backgroundColor:"#EC224E", width:"auto"}}>
          <p className="mb-4" style={{color:"white", fontSize:"36px",textAlign:"center"}}>Get Quick Loan In Just 3 Steps!</p>
          <Row className="mt-3">
            <Col xl={4} lg={12} sm={12} className="mt-4" style={{textAlign:"center"}}>
              <div className="growOnHover m-0 mb-5 pb-4 p-0">
                <img src={step1Icon}></img>
              </div>
              <p className="stepp" style={{color:"white", fontSize:"24px", marginTop:"-50px"}}>Fill our 2-minute loan<br/>application</p>
            </Col>
            <Col xl={4} lg={12} sm={12} className="mt-4" style={{textAlign:"center"}}>
              <div className="growOnHover m-0 mb-5 pb-4 p-0">
                <img src={step2Icon}></img>
              </div>
              <p className="stepp" style={{color:"white", fontSize:"24px", marginTop:"-50px"}}>Get approval within<br/>24 hours *</p>
            </Col>
            <Col xl={4} lg={12} sm={12} className="mt-4" style={{textAlign:"center"}}>
              <div className="growOnHover m-0 mb-5 pb-4 p-0">
                <img src={step3Icon}></img>
              </div>
              <p className="stepp" style={{color:"white", fontSize:"24px", marginTop:"-50px"}}>Give bank details and<br/>receive money</p>
            </Col>
          </Row>
        </div>
        
        <div className="whyLYT pb-5" style={{marginTop:"-170px", position:"relative"}}>
          <Row className="pb-4">
            <Col className="pt-3" >
              <div style={{height:"6px", backgroundColor:"#EC224E"}}></div>
            </Col>
            <Col className="px-auto mx-auto" style={{maxWidth:"340px"}}>
              <img className="whyLYTimg" src={whyLYT}/>
            </Col>
            <Col className="pt-3">
              <div style={{height:"6px", backgroundColor:"#EC224E"}}></div>
            </Col>
          </Row>
          <Row className="mb-5 keyPoints">
            <Col xl={4} lg={6} md={6} xs={12} className="why-vitto-div">
              <Row>
                <Col style={{maxWidth:"58px"}}>
                  <div style={{backgroundColor:"#EC224E", height:"49px", width:"49px",borderRadius:"100%"}}>
                    <img className="inAppVideoIcon" src={inAppVideoIcon}></img>
                  </div>
                </Col>
                <Col className="py-auto px-3 my-auto">
                  <p className="m-0 why-vitto-pts">In - App Video Assistance</p>
                </Col>
              </Row>
            </Col>
            <Col xl={4} lg={6} md={6} xs={12} className="why-vitto-div">
              <Row>
                <Col style={{maxWidth:"58px"}}>
                  <div style={{backgroundColor:"#EC224E", height:"49px", width:"49px",borderRadius:"100%"}}>
                    <img className="simpleToUseIcon" src={simpleToUseIcon}></img>
                  </div>
                </Col>
                <Col className="py-auto px-3 my-auto">
                  <p className="m-0 why-vitto-pts">Very Simple To Use</p>
                </Col>
              </Row>
            </Col>
            <Col xl={4} lg={6} md={6} xs={12} className="why-vitto-div">
              <Row>
                <Col style={{maxWidth:"58px"}}>
                  <div style={{backgroundColor:"#EC224E", height:"49px", width:"49px",borderRadius:"100%"}}>
                    <img className="rocketIcon" src={rocketIcon}></img>
                  </div>
                </Col>
                <Col className="py-auto px-3 my-auto">
                  <p className="m-0 why-vitto-pts">Eligibility Boost Assistance</p>
                </Col>
              </Row>
            </Col>
            <Col xl={4} lg={6} md={6} xs={12} className="why-vitto-div">
              <Row>
                <Col style={{maxWidth:"58px"}}>
                  <div style={{backgroundColor:"#EC224E", height:"49px", width:"49px",borderRadius:"100%"}}>
                    <img className="minimalDocumentationIcon" src={minimalDocumentationIcon}></img>
                  </div>
                </Col>
                <Col className="py-auto px-3 my-auto">
                  <p className="m-0 why-vitto-pts">Minimal Documentation</p>
                </Col>
              </Row>
            </Col>
            <Col xl={4} lg={6} md={6} xs={12} className="why-vitto-div">
              <Row>
                <Col style={{maxWidth:"58px"}}>
                  <div style={{backgroundColor:"#EC224E", height:"49px", width:"49px",borderRadius:"100%"}}>
                    <img className="easyRepaymentsIcon" src={easyRepaymentsIcon}></img>
                  </div>
                </Col>
                <Col className="py-auto px-3 my-auto">
                  <p className="m-0 why-vitto-pts">Easy Repayments</p>
                </Col>
              </Row>
            </Col>
            <Col xl={4} lg={6} md={6} xs={12} className="why-vitto-div">
              <Row>
                <Col style={{maxWidth:"58px"}}>
                  <div style={{backgroundColor:"#EC224E", height:"49px", width:"49px",borderRadius:"100%"}}>
                    <img className="tfHoursApprovalIcon" src={tfHoursApprovalIcon}></img>
                  </div>
                </Col>
                <Col className="py-auto px-3 my-auto">
                  <p className="m-0 why-vitto-pts">Loan Approval in 24 Hours*</p>
                </Col>
              </Row>
            </Col>
            <Col xl={4} lg={6} md={6} xs={12} className="why-vitto-div">
              <Row>
                <Col style={{maxWidth:"58px"}}>
                  <div style={{backgroundColor:"#EC224E", height:"49px", width:"49px",borderRadius:"100%"}}>
                    <img className="paymentReminderIcon" src={paymentReminderIcon}></img>
                  </div>
                </Col>
                <Col className="py-auto px-3 my-auto">
                  <p className="m-0 why-vitto-pts">Early Payment Reminders</p>
                </Col>
              </Row>
            </Col>
            <Col xl={4} lg={6} md={6} xs={12} className="why-vitto-div">
              <Row >
                <Col style={{maxWidth:"58px"}}>
                  <div style={{backgroundColor:"#EC224E", height:"49px", width:"49px",borderRadius:"100%"}}>
                    <img className="educationalVideoIcon" src={educationalVideoIcon} ></img>
                  </div>
                </Col>
                <Col className="py-auto px-3 my-auto">
                  <p className="m-0 why-vitto-pts">Educational Videos</p>
                </Col>
              </Row>
            </Col>
            <Col xl={4} lg={6} md={6} xs={12} className="why-vitto-div">
              <Row>
                <Col style={{maxWidth:"58px"}}>
                  <div style={{backgroundColor:"#EC224E", height:"49px", width:"49px",borderRadius:"100%"}}>
                    <img className="rewardsIcon" src={rewardsIcon}></img>
                  </div>
                </Col>
                <Col className="py-auto px-3 my-auto">
                  <p className="m-0 why-vitto-pts">Rewards On Early Repayment</p>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
        <div className="userReview">
          <Row className="pt-5 pr-0 pb-5 pl-5 testimonial" style={{backgroundColor:"#EC224E"}}>
            <Col className="py-3 userComment" xl={7} lg={11} sm={11} xs={11}>
            <p style={{fontSize:"150px", maxHeight:"0px", maxWidth:"0px",margin:"0", position:"relative", top:"-73px", left:"120px"}} className="commas">“</p>
            <Slider {...settings}>
              <div>
                <div className="p-4 row">
                  <div className="col-desktop-3 col-md-4 col-xs-8 d-flex">
                    <img className="pb-2" style={{marginRight:"20px", borderRadius:"50%", height:'152px', width:'152px'}} src={userImage}/>
                  </div>
                  <p className="col-desktop-9 col-md-8 my-auto col-xs-12"> 
                    <img className="" src={fiveStar}/>
                    <span className="" style={{fontSize:"24px"}}> Divya Kumari, 31</span><br/>
                    VITTO helped us get a loan when nobody else was. It was so simple to use and the loan was sactioned within 24 hours. 
                    I would recommend them to all my friends!
                  </p>
                </div>
              </div>
              <div>
                <div className="p-4 row">
                  <div className="col-desktop-3 col-md-4 col-xs-8 d-flex">
                    <img className="pb-2" style={{marginRight:"20px", borderRadius:"50%", height:'152px', width:'152px'}} src={userImage}/>
                  </div>
                  <p className="col-desktop-9 col-md-8 my-auto col-xs-12" style={{marginLeft:'-10px'}}> 
                    <img className="" src={fiveStar}/> 
                    <span className="" style={{fontSize:"24px"}}> Divya Kumari, 31</span><br/> 
                      VITTO helped us get a loan when nobody else was. It was so simple to use and the loan was sactioned within 24 hours. 
                      I would recommend them to all my friends!
                  </p>
                </div>
              </div>
        </Slider>
            </Col>
            <Col className="my-auto p-0 playLink" xl={4} lg={12} sm={12}>
              <div style={{backgroundColor:"white", borderTopLeftRadius:"21px", borderBottomLeftRadius:"21px"}}>
                <a className="d-inline-flex p-0 growOnHover" href="https://play.google.com/store/apps/details?id=ai.microfinance.lyt" style={{width:"auto"}} target="_blank" rel="noopener noreferrer">
                  <img className="googlePlayIc" src={googlePlay}></img>
                </a>
              </div>
            </Col>
          </Row>
        </div>
        <div className="weAreMFI">
          <Row className="pb-5 pt-4 wermfiRow mx-0" style={{marginTop:"40px"}}>
            <div className="px-0 mx-0">
              <p className="weMFI mb-0 mx-0" style={{display:"inline-flex", left:0, position:"relative", marginLeft:"10px", background:"white"}}>We Are<span style={{color:"#EC224E"}}>&#160;MICROFINANCE.AI</span> </p>
              <div className="px-0">
                <div className="hr-weMFI" style={{backgroundColor:"#EC224E",  maxHeight:"6px",float:"none", overflow:"hidden"}}></div>
              </div>
            </div>
            {/* <Col xl={4} lg={5} md={5}>
              <p className="weMFI mb-0">We Are <span style={{color:"#EC224E"}}>MICROFINANCE.AI</span> </p>
            </Col>
            <Col className="my-auto" style={{backgroundColor:"#EC224E", height:"6px", maxHeight:"6px"}}>
            </Col> */}
          </Row>
          <div className="container weMFIBoldText px-0">
            ENGAGE, EDUCATE, EMPOWER, REPEAT.
          </div>
          <div className="container weMFIText pb-5 px-0" style={{fontSize:"18px"}}>
            Microfinance.AI simplifies micro-lending experience for end users through it’s assistive UI, local language support,<br></br>and data-science.
          </div>
          <div className="row weMFI-images">
            <img className="mx-0 px-0 col col-sm-6 col-md-3" src={groupImage1} style={{height:"auto"}}></img>
            <img className="mx-0 px-0 col col-sm-6 col-md-3" src={groupImage2} style={{height:"auto"}}></img>
            <img className="mx-0 px-0 col col-sm-6 col-md-3" src={groupImage3} style={{height:"auto"}}></img>
            <img className="mx-0 px-0 col col-sm-6 col-md-3" src={groupImage4} style={{height:"auto"}}></img>
          </div>
        </div>
        <section className="the-platform">
          <Row className="py-5 mx-0" style={{marginTop:"40px"}}>
            <div className="px-0 mx-0">
              <p className="weMFI mb-0 mx-0" style={{display:"inline-flex", position:"relative", left:0, marginLeft:"10px", background:"white"}}>The PLATFORM</p>
              <div className="px-0">
                <div className="hr-weMFI" style={{backgroundColor:"#EC224E",  maxHeight:"6px",float:"none", overflow:"hidden"}}></div>
              </div>
            </div>
          </Row>
          <Row className="red-bar">
            <div className="row weMFI vitto-app-designed"  style={{maxWidth:"1035px", backgroundColor:"#EC224E", boxShadow:"0px 4px 4px rgba(0, 0, 0, 0.25)", color:"white", 
              borderTopRightRadius:"25px", borderBottomRightRadius:"25px"}}>
              <div className="col-desktop-2 col-tablet-4 col-mobile-4 px-0"></div>
              <div className="col-desktop-4 col-tablet-8 col-mobile-5 pl-3 pr-0 d-flex align-items-center">
                <img src={lytLogo}></img>
                <span className="pt-2">&nbsp;&nbsp;APP</span>
              </div>
              <div className="col-desktop-6 d-none d-xl-block px-0 pt-2 d-flex align-items-center sub-text"><p> (Designed for borrowers)</p></div>
              <Row>
                <div className=" col-tablet-4 col-mobile-4 col-desktop-0 px-0"></div>
                <div className="col-tablet-8 col-mobile-8 d-lg-block d-xl-none px-0 pt-2 d-flex align-items-center sub-text"><p> (Designed for borrowers)</p></div>
              </Row>
            </div>
          </Row>
          <img data-aos="fade-right" data-aos-offset="200" data-aos-duration="1200" data-aos-easing="ease-in-sine" src={LYTapp} 
            className="vitto-app">
          </img>
          <Row style={{marginTop:"-9.5rem", padding:"0px"}} className="vitto-app-section">
            <Row className="mx-0 px-0">
              <Col xl={4} md={6} sm={12} className="p-4">
                <div className="featureCards">
                  <Row className="px-2" data-aos="fade-down" data-aos-easing="linear" data-aos-duration="800">
                    <Col xs={4}>
                      <img src={assitiveIcon1}/>
                    </Col>
                    <Col xs={4}>
                      <img src={assitiveIcon2}/>
                    </Col>
                    <Col xs={4}>
                      <img src={assitiveIcon3}/>
                    </Col>
                  </Row>
                  <p className="featureCardHeading">Assistive and Vernacular AI</p>
                  <p className="featureCardBody pb-4">
                    Designed to be used by all people with zero language or technological barrier, our app is offered in 
                    multiple local languages and guides the borrowers through every step of the process. 
                  </p>
                  <p className="featureCardFooter mt-2" style={{position:"absolute", bottom:"30px"}}> <Link to="/platform/assistiveAI" style={{textDecoration:"none"}}> <a href="" style={{color:"black",fontSize:"15px", textDecoration:"none", borderBottom:"2px solid #EC224E"}}>Know More</a> </Link> </p>
                </div>
              </Col>
              <Col xl={4} md={6} sm={12} className="p-4">
                <div className="featureCards">
                  <Row className="px-2" data-aos="fade-down" data-aos-easing="linear" data-aos-duration="800">
                    <Col xs={4}>
                      <img src={saveTimeIcon1}/>
                    </Col>
                    <Col xs={4}>
                      <img src={saveTimeIcon2}/>
                    </Col>
                    <Col xs={4}>
                      <img src={saveTimeIcon3}/>
                    </Col>
                  </Row>
                  <p className="featureCardHeading"><span className="save-time">Save Your Time</span></p>
                  <p className="featureCardBody pb-4">
                    Our platform provides various actionable customer insights and will assist from customer onboarding to field officer planning and assistance. With 
                    Our borrower centric mobile application, we bring the loan and other financial products, hassle free and completely online, at the users fingertips, 
                    thus empowering micro-lenders to maintain relation digitally.
                  </p>
                  <p> </p>
                  <p className="featureCardFooter" style={{position:"absolute", bottom:"30px"}}> <Link to="/platform/saveTime" style={{textDecoration:"none"}}> <a href="" style={{color:"black",fontSize:"15px", textDecoration:"none", borderBottom:"2px solid #EC224E"}}>Know More</a> </Link> </p>
                </div>
              </Col>
              <Col xl={4} md={6} sm={12} className="p-4">
                <div className="featureCards">
                  <Row className="px-2" data-aos="fade-down" data-aos-easing="linear" data-aos-duration="800">
                    <Col xs={4}>
                      <img src={reduceIcon1}/>
                    </Col>
                    <Col xs={4}>
                      
                    </Col>
                    <Col xs={4}>
                    
                    </Col>
                  </Row>
                  <p className="featureCardHeading">Reduce Cost</p>
                  <p className="featureCardBody pb-4">
                    Client onboarding and monitoring costs take up to 5% of the total operating costs. With our remote onboarding 
                    and client monitoring take control of this cost and reduce the total cost of upto 2% with wow experience for your clients.
                  </p>
                  <p className="featureCardFooter" style={{position:"absolute", bottom:"30px"}}> <Link to="/platform/reduceCost" style={{textDecoration:"none"}}> <a href="" style={{color:"black",fontSize:"15px", textDecoration:"none", borderBottom:"2px solid #EC224E"}}>Know More</a> </Link> </p>
                </div>
              </Col>
              <Col xl={4} md={6} sm={12} className="p-4">
                <div className="featureCards">
                  <Row className="px-2" data-aos="fade-down" data-aos-easing="linear" data-aos-duration="800">
                    <Col xs={4}>
                      <img src={controlIcon1}/>
                    </Col>
                    <Col xs={4}>
                      <img src={controlIcon2}/>
                    </Col>
                    <Col xs={4}>
                      <img src={controlIcon3}/>
                    </Col>
                  </Row>
                  <p className="featureCardHeading">Have More Control</p>
                  <p className="featureCardBody pb-4">
                    Our mobile application provides you with the loan status, payment reminders, Online payment, Top-up loans 
                    and much more. Complete KYC process online and rest free with our commitment to safety and privacy of your data.
                  </p>
                  <p className="featureCardFooter" style={{position:"absolute", bottom:"30px"}}> <Link to="/platform/moreControl" style={{textDecoration:"none"}}> <a href="" style={{color:"black",fontSize:"15px", textDecoration:"none", borderBottom:"2px solid #EC224E"}}>Know More</a> </Link> </p>
                </div>
              </Col>
              <Col xl={4} md={6} sm={12} className="p-4">
                <div className="featureCards">
                  <Row className="px-2" data-aos="fade-down" data-aos-easing="linear" data-aos-duration="800">
                    <Col xs={4}>
                      <img src={educationalIcon1}/>
                    </Col>
                    <Col xs={4}>
                      <img src={educationalIcon2}/>
                    </Col>
                    <Col xs={4}>
                      <img src={educationalIcon3}/>
                    </Col>
                  </Row>
                  <p className="featureCardHeading">Educational Content</p>
                  <p className="featureCardBody pb-4">
                    Our application also provides the borrowers with informative and educative bite sized videos on financial products 
                    to enable them to make an informed decision.
                  </p>
                  <p className="featureCardFooter" style={{position:"absolute", bottom:"30px"}}> <Link to="/platform/educationalContent" style={{textDecoration:"none"}}> <a href="" style={{color:"black",fontSize:"15px", textDecoration:"none", borderBottom:"2px solid #EC224E"}}>Know More</a> </Link> </p>
                </div>
              </Col>
            </Row>
          </Row>

          <Row className="dfLenders" style={{marginTop:"12rem"}}>
            <div className="row weMFI vitto-dashboard-designed vitto-app-designed" style={{backgroundColor:"#EC224E", boxShadow:"0px 4px 4px rgba(0, 0, 0, 0.25)", color:"white", 
              borderTopRightRadius:"25px", borderBottomRightRadius:"25px"}}>
              <div className="col-desktop-3 col-tablet-5 col-mobile-4 px-0"></div>
              <div className="col-desktop-4 col-tablet-7 col-mobile-5 px-0 d-flex align-items-center lytLogoCol">
                <img src={lytLogo}></img>
                <span className="pt-2 sub-text1">&nbsp;DASHBOARD</span>
              </div>
              <div className="col-desktop-5 d-none d-xl-block px-0 pt-2 d-flex align-items-center sub-text2"><p className="desFLenders"> (Designed for lenders)</p></div>
              <Row>
                <div className=" col-tablet-5 col-mobile-4 col-desktop-0 px-0"></div>
                <div className="col-tablet-7 col-mobile-7 d-lg-block d-xl-none px-0 pt-2 d-flex align-items-center sub-text2"><p> (Designed for lenders)</p></div>
              </Row>
            </div>
          </Row>
          <img data-aos="fade-right" className="laptop" data-aos-offset="200" data-aos-duration="1200" data-aos-easing="ease-in-sine" src={laptopIcon} style={{transform: "scale(0.8)"}}></img>
          <img data-aos="fade-right" data-aos-offset="120" className="laptop-screen" data-aos-duration="1200" data-aos-easing="ease-in-sine" src={dashboardScreenshot} style={{transform: "scale(0.8)"}}></img>
          <Row style={{marginTop:"-16rem"}} className="vitto-app-section px-0">
            <Row className="mx-0 px-0">
              <Col xl={4} md={6} sm={12} className="p-4">
                <div className="featureCards">
                  <Row className="px-2" data-aos="fade-down" data-aos-easing="linear" data-aos-duration="800">
                    <Col xs={4}>
                      <img src={liveTrackingIcon1}/>
                    </Col>
                    <Col xs={4}>
                      <img src={liveTrackingIcon2}/>
                    </Col>
                    <Col xs={4}>
                      <img src={liveTrackingIcon3}/>
                    </Col>
                  </Row>
                  <p className="featureCardHeading">{"Live Tracking & Dynamic"}</p>
                  <p className="featureCardBody">
                    Our partner web dashboard provides you with the live details and actionable insights at 
                    branch/field officer level to make your business more efficient and smart!
                  </p>
                  <p className="featureCardFooter" style={{position:"absolute", bottom:"30px"}}> <Link to="/platform/liveTracking" style={{textDecoration:"none"}}> <a href="" style={{color:"black",fontSize:"15px", textDecoration:"none", borderBottom:"2px solid #EC224E"}}>Know More</a> </Link> </p>
                </div>
              </Col>
              <Col xl={4} md={6} sm={12} className="p-4">
                <div className="featureCards">
                  <Row className="px-2" data-aos="fade-down" data-aos-easing="linear" data-aos-duration="800">
                    <Col xs={4}>
                      <img src={fraudDetectionIcon1}/>
                    </Col>
                    <Col xs={4}>
                      <img src={fraudDetectionIcon2}/>
                    </Col>
                    <Col xs={4}>
                      <img src={fraudDetectionIcon3}/>
                    </Col>
                  </Row>
                  <p className="featureCardHeading">Early Fraud Detection</p>
                  <p className="featureCardBody">
                    Our face recognition technology detects fake identification even before onboarding is completed.
                  </p>
                  <p className="featureCardFooter" style={{position:"absolute", bottom:"30px"}}> <Link to="/platform/fraudDetection" style={{textDecoration:"none"}}> <a href="" style={{color:"black",fontSize:"15px", textDecoration:"none", borderBottom:"2px solid #EC224E"}}>Know More</a> </Link> </p>
                </div>
              </Col>
              <Col xl={4} md={6} sm={12} className="p-4">
                <div className="featureCards">
                  <Row className="px-2" data-aos="fade-down" data-aos-easing="linear" data-aos-duration="800">
                    <Col xs={4}>
                      <img src={inAppCRMIcon1}/>
                    </Col>
                    <Col xs={4}>
                      
                    </Col>
                    <Col xs={4}>
                    
                    </Col>
                  </Row>
                  <p className="featureCardHeading">In - App CRM</p>
                  <p className="featureCardBody">
                    Our face recognition<br/> technology detects fake identification even before onboarding is completed.
                  </p>
                  <p className="featureCardFooter" style={{position:"absolute", bottom:"30px"}}> <Link to="/platform/inAppCRM" style={{textDecoration:"none"}}> <a href="" style={{color:"black",fontSize:"15px", textDecoration:"none", borderBottom:"2px solid #EC224E"}}>Know More</a> </Link> </p>
                </div>
              </Col>
            </Row>
          </Row>
          <Row className="ourBackersRow"  style={{backgroundColor:"#EC224E", marginTop:"200px", boxShadow:"0px 4px 4px rgba(0, 0, 0, 0.25)"}}>
            <Row className="my-5 mx-auto p-0 our-partners">
              <Col className="pt-4 px-0" >
                <div className="whiteLine px-0" style={{height:"6px", backgroundColor:"white"}}></div>
              </Col>
              <Col className="px-auto mx-auto bofo our-backers-text" style={{maxWidth:"340px", minWidth:"255px", color:"white", fontSize:"36px", textAlign:"center"}}>
                Our BACKERS
              </Col>
              <Col className="pt-4 whiteLine px-0">
                <div className="whiteLine px-0" style={{height:"6px", backgroundColor:"white"}}></div>
              </Col>
            </Row>
            <Container className="mt-5 mb-5 mx-auto backers-icons-section" style={{backgroundColor:"white", minHeight:"263px", boxShadow:"inset 0px 4px 4px rgba(0, 0, 0, 0.25)", borderRadius:"33px"}}>
              <Row className="py-5 px-5 d-flex justify-content-center">
                <Col className="col-mobile-6 col-tablet-4 col-fit-6 d-flex justify-content-center my-auto BWgrowOnHover"> <a href="https://www.lse.ac.uk/"> <img src={backers3} style={{maxWidth:"170px"}}/> </a> </Col>
                <Col className="col-mobile-6 col-tablet-4 col-fit-6 d-flex justify-content-center my-auto BWgrowOnHover"> <a href="https://ciie.co/"> <img src={backers4} style={{maxWidth:"170px"}}/> </a> </Col>
                <Col className="col-mobile-6 col-tablet-4 col-fit-6 d-flex justify-content-center my-auto BWgrowOnHover"> <a href="https://www.wefounderscircle.com/"> <img src={backers5} style={{maxWidth:"170px"}}/> </a> </Col>
                <Col className="col-mobile-6 col-tablet-4 col-fit-6 d-flex justify-content-center my-auto BWgrowOnHover"> <a href="https://gusec.edu.in/"> <img src={backers6} style={{maxWidth:"170px"}}/> </a></Col>
                <Col className="col-mobile-6 col-tablet-4 col-fit-6 d-flex justify-content-center my-auto BWgrowOnHover"> <a href="https://www.faad.in/"> <img src={backers7} style={{maxWidth:"170px"}}/> </a> </Col>
                <Col className="col-mobile-6 col-tablet-4 col-fit-6 d-flex justify-content-center my-auto BWgrowOnHover"> <a href="https://www.letsventure.com/"> <img src={backers8} style={{maxWidth:"170px"}}/> </a> </Col>
              </Row>
            </Container>
          </Row>
          <Row className="backers-icons-section" style={{marginTop:"8.5rem"}}>
            <div style={{border:"2px solid #717171", borderRadius:"22px", minHeight:"200px"}}>
              <p className="mx-auto bofo text-center ourPartnersText" style={{fontSize:"36px", backgroundColor:"white", maxWidth:"400px",position:"relative", top:"-29px"}}>Our PARTNERS</p>
              <div className="pb-5 px-4 d-flex row justify-content-center" style={{textAlign:"center", width:"100%", paddingTop:"1rem"}}>

                <a className="col-mobile-6 col-tablet-4 col-fit-7 d-flex justify-content-center my-auto BWgrowOnHover" href="https://www.startupindia.gov.in/" >  <img src={backers1} style={{maxWidth:"170px"}}/> </a>
                <a className="col-mobile-6 col-tablet-4 col-fit-7 d-flex justify-content-center my-auto BWgrowOnHover" href="https://www.sewainsurance.org/"><img src={backers2} style={{maxWidth:"170px"}}/>  </a>
                <a className="col-mobile-6 col-tablet-4 col-fit-7 d-flex justify-content-center my-auto BWgrowOnHover" href="https://samridhisociety.org/" style={{textDecoration:"none"}}> <img className="BWgrowOnHover our-partners-img-1 " src={ourPartnersIcon1} style={{marginLeft:"-20px"}}/> </a>
                <a className="col-mobile-6 ourPartners col-tablet-4 col-fit-7 d-flex justify-content-center my-auto BWgrowOnHover" href="https://sarala.co.in/" style={{textDecoration:"none"}}> <img className="BWgrowOnHover our-partners-img-2 " src={ourPartnersIcon2} style={{marginLeft:"-62px"}} /> </a>
                <a className="col-mobile-6 ourPartners col-tablet-4 col-fit-7 d-flex justify-content-center my-auto BWgrowOnHover" href="https://www.bobble.ai/en/home" style={{textDecoration:"none"}}> <img className="BWgrowOnHover our-partners-img-4" src={ourPartnersIcon4}/> </a>
                <a className="col-mobile-6 ourPartners col-tablet-4 col-fit-7 d-flex justify-content-center my-auto BWgrowOnHover" href="https://www.bobble.ai/en/home" style={{textDecoration:"none"}}> <img className="BWgrowOnHover our-partners-img-5" src={ourPartnersIcon5}/> </a>
                <a className="col-mobile-6 ourPartners col-tablet-4 col-fit-7 d-flex justify-content-center my-auto BWgrowOnHover" href="https://www.bobble.ai/en/home" style={{textDecoration:"none"}}> <img className="BWgrowOnHover our-partners-img-3" src={ourPartnersIcon3}/> </a>
              </div>
            </div>
          </Row>
          <Container className="px-0 featuredInCont" fluid style={{backgroundColor:"#F8F8F8", marginTop:"8rem", paddingTop:"85px", paddingBottom:"58px", boxShadow:"0px 4px 0px rgba(0, 0, 0, 0.25)"}}>
            <Row className="my-4 our-partners">
              <Col className="my-auto px-0" >
                <div className="redLine" style={{height:"6px", backgroundColor:"#EC224E", margin:"auto"}}></div>
              </Col>
              <Col className="bofo featuredText px-0" style={{maxWidth:"290px", color:"black", fontSize:"36px", textAlign:"center"}}>
                FEATURED In
              </Col>
              <Col className="my-auto px-0">
                <div className="redLine" style={{height:"6px", backgroundColor:"#EC224E"}}></div>
              </Col>
            </Row>
            <Row className="my-4 mx-auto backers-icons-section2 py-5" >
              <div className="tab-fit-6 col-sm-4 col-mobile-6 py-4 BWgrowOnHover featured-in">
                <a href="https://yourstory.com/2022/01/financial-inclusion-lab-fifth-cohort-startups-innovations/amp" style={{textDecoration:"none"}}> 
                  <img src={featuredInIcon1}/>
                </a>
              </div>
              <div className="tab-fit-6 col-sm-4 col-mobile-6 py-4 BWgrowOnHover featured-in">
                <a href="https://www.entrepreneur.com/article/400331" style={{textDecoration:"none"}}> 
                  <img src={featuredInIcon2}/> 
                </a>
              </div>
              <div className="tab-fit-6 col-sm-4 col-mobile-6 py-4 BWgrowOnHover featured-in">
                <a href="https://www.vccircle.com/we-founder-circle-backs-fintech-startup-microfinance-ai" style={{textDecoration:"none"}}> 
                  <img src={featuredInIcon3}/> 
                </a>
              </div>
              <div className="tab-fit-6 col-sm-4 col-mobile-6 py-4 BWgrowOnHover featured-in">
                <a href="https://techgraph.co/business/startups/microfinance-ai-raises-200k-from-wfc-faad-others/" style={{textDecoration:"none"}}> 
                  <img src={featuredInIcon4}/> 
                </a>
              </div>
              <div className="tab-fit-6 col-sm-4 col-mobile-6 py-4 BWgrowOnHover featured-in">
                <a href="https://entrackr.com/2021/12/funding-and-acquisitions-in-indian-startups-this-week-29-nov-04-dec/" style={{textDecoration:"none"}}> 
                  <img src={featuredInIcon5}/> 
                </a>
              </div>
              <div className="tab-fit-6 col-sm-4 col-mobile-6 py-4 BWgrowOnHover featured-in">
                <a href="https://entrackr.com/2021/12/funding-and-acquisitions-in-indian-startups-this-week-29-nov-04-dec/" style={{textDecoration:"none"}}> 
                  <img src={featuredInIcon6}/> 
                </a>
              </div>
            </Row>
            <div className="backers-icons-section">
              <Row className="mx-auto px-0" style={{maxWidth:"1244px", backgroundColor:"#EC224E", fontSize:"36px", color:"white", borderRadius:"22px", marginTop:"150px", marginBottom:"200px"}}>
                <p className="py-4 my-1 bofo" style={{textAlign:"center"}}>Our LEADERSHIP</p>
              </Row>  
            </div>
          </Container>
          <Row className="container mx-auto px-0" style={{marginLeft:"4rem", marginRight:"4rem", position:"relative", top:"-200px", minHeight:"480px", justifyContent:"center"}}>
            <Col xl={3} md={6} sm={12} className="px-5 pb-4"  style={{paddingTop:'5rem'}}>
              <div className="ourLeadershipCard p-3 mx-auto">
                <img style={{position:"relative", top:"-90px"}} src={ourFoundersIcon1}/>
                <p className="ourLeadersName" style={{color:"#EA334D"}}>Swati Sinha</p>
                <p className="ourLeadersRole">Founder</p>
                <p className="aboutOurLeader px-3">
                  Swati has more than 8 years of experience. She has been working for economic
                  empowerment of women and women's equality. She led the cooperative movement in 
                  Bihar with more than 2,00,000 members.
                </p>
                <Container style={{position:"relative", bottom:"82px"}}>
                  <a href="https://www.linkedin.com/in/swati-sinha-95304338/"> <img className="growOnHover" src={blueLinkedinIcon}/> </a>
                </Container>
              </div>
            </Col>
            <Col xl={3} md={6} sm={12} className="px-5 pb-4" style={{paddingTop:'5rem'}}>
              <div className="ourLeadershipCard p-3 mx-auto">
                <img style={{position:"relative", top:"-90px"}} src={ourFoundersIcon2}/>
                <p className="ourLeadersName" style={{color:"#EA334D"}}>Kapil Patil</p>
                <p className="ourLeadersRole">Founder</p>
                <p className="aboutOurLeader px-3">
                  Kapil has more than 7 years of experience in Fintech, MSME, NFBC and Public Policy.
                </p>
                <Container style={{position:"relative", bottom:"-22px"}}>
                  <a href="https://twitter.com/kapilpatil89" style={{textDecoration:"none"}}> <img className="px-3 growOnHover" src={redTwitterIcon}/> </a>
                  <a href="https://www.linkedin.com/in/kapilpatil89/" style={{textDecoration:"none"}}> <img className="growOnHover" src={blueLinkedinIcon} /> </a>
                </Container>
              </div>
            </Col>
            <Col xl={3} md={6} sm={12} className="px-5 pb-4" style={{paddingTop:'5rem'}}>
              <div className="ourLeadershipCard p-3 mx-auto">
                <img style={{position:"relative", top:"-90px"}} src={ourFoundersIcon3}/>
                <p className="ourLeadersName" style={{color:"#EA334D"}}>Ankit Prasad</p>
                <p className="ourLeadersRole">Advisor</p>
                <p className="aboutOurLeader px-3">
                  Ankit comes from one of the most reputed university in India he brings more than 10 years 
                  of his experience in building and scaling up a startup into the table.
                </p>
                <Container style={{position:"relative", bottom:"20px"}}>
                  <a href="https://twitter.com/TheBobbleGuy" style={{textDecoration:"none"}}> <img className="px-3 growOnHover" src={redTwitterIcon}/> </a>
                  <a href="https://www.linkedin.com/in/prasadankit/" style={{textDecoration:"none"}}> <img className="growOnHover" src={blueLinkedinIcon} /> </a>
                </Container>
              </div>
            </Col>
            <Col xl={3} md={6} sm={12} className="px-5 pb-4" style={{paddingTop:'5rem'}}>
              <div className="ourLeadershipCard p-3 mx-auto">
                <img style={{position:"relative", top:"-90px"}} src={ourFoundersIcon4}/>
                <p className="ourLeadersName" style={{color:"#EA334D"}}>Rahul Prasad</p>
                <p className="ourLeadersRole">Tech - Advisor</p>
                <p className="aboutOurLeader px-3">
                  Rahul comes from a very humble background. He has an experience of more than 10 years of
                  building a tech startup and scaling it to very large scale.
                </p>
                <Container style={{position:"relative", bottom:"20px"}}>
                  <a href="https://www.linkedin.com/in/therahulprasad/"> <img className="growOnHover" src={blueLinkedinIcon} /> </a>
                </Container>
              </div>
            </Col>
          </Row>
        </section>
        <div className="px-0 mx-0">
          <p className="weMFI mb-0 mx-0" style={{display:"inline-flex", left:0, position:"relative", marginLeft:"10px", background:"white"}}>Our BLOG </p>
          <div className="px-0">
            <div className="hr-weMFI" style={{backgroundColor:"#EC224E",  maxHeight:"6px",float:"none", overflow:"hidden"}}></div>
          </div>
        </div>
        {this.state.blogs}
        {/* <Row className="py-5" id="ourBlog" style={{marginTop:"-120px"}}>
            <Col style={{maxWidth:"400px"}}>
              <img className="weMFI mb-0" src={MDigiFinTalkIcon} style={{marginLeft:"2rem", paddingLeft:"3rem"}}/>
            </Col>
            <Col className="my-auto" style={{backgroundColor:"#306CB5", height:"6px", maxHeight:"6px"}}>
            </Col>
        </Row>
        <Row id="ourBlog" className="py-5 px-5 finTalk" style={{marginLeft:"3rem"}}>
          <Col className="p-1" style={{ minHeight:"120px", maxWidth:"265px",backgroundColor:"#306CB5", borderRadius:"22px", filter:"drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))", marginRight:"auto"}}>
            <img src={FintechIcon} style={{borderRadius:"20px 20px 0px 0px", maxHeight:"260px"}}/>
            <p className="m-3" style={{fontSize:"19px", color:"white"}}>{"Social Capital & "} <br/> Digital Financial Services</p>
            <p className="m-3" style={{fontSize:"14px", color:"white"}}>01 January 2020</p>
          </Col>
          <Col className="mx-auto p-1" style={{ minHeight:"120px", maxWidth:"265px",backgroundColor:"#306CB5", borderRadius:"22px", filter:"drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))"}}>
            <img src={FintechIcon} style={{borderRadius:"20px 20px 0px 0px", maxHeight:"260px"}}/>
            <p className="m-3" style={{fontSize:"19px", color:"white"}}>{"Social Capital & "} <br/> Digital Financial Services</p>
            <p className="m-3" style={{fontSize:"14px", color:"white"}}>01 January 2020</p>
          </Col>
          <Col className="mx-auto p-1" style={{ minHeight:"120px", maxWidth:"265px",backgroundColor:"#306CB5", borderRadius:"22px", filter:"drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))"}}>
            <img src={FintechIcon} style={{borderRadius:"20px 20px 0px 0px", maxHeight:"260px"}}/>
            <p className="m-3" style={{fontSize:"19px", color:"white"}}>{"Social Capital & "} <br/> Digital Financial Services</p>
            <p className="m-3" style={{fontSize:"14px", color:"white"}}>01 January 2020</p>
          </Col>
          <Col className="mx-auto p-1" style={{ minHeight:"120px", maxWidth:"265px",backgroundColor:"#306CB5", borderRadius:"22px", filter:"drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))"}}>
            <img src={FintechIcon} style={{borderRadius:"20px 20px 0px 0px", maxHeight:"260px"}}/>
            <p className="m-3" style={{fontSize:"19px", color:"white"}}>{"Social Capital & "} <br/> Digital Financial Services</p>
            <p className="m-3" style={{fontSize:"14px", color:"white"}}>01 January 2020</p>
          </Col>
        </Row> */}
        <section className="connectWithUs" style={{marginBottom:"6rem"}}>
          <Row className="my-4 mx-auto p-0 our-partners">
            <Col className="my-auto px-0" >
              <div className="redLine" style={{height:"6px", backgroundColor:"#EC224E", margin:"auto"}}></div>
            </Col>
            <Col className="bofo connectText" style={{maxWidth:"360px", color:"black", textAlign:"center"}}>
              CONNECT WITH US
            </Col>
            <Col className="my-auto px-0">
              <div className="redLine" style={{height:"6px", backgroundColor:"#EC224E"}}></div>
            </Col>
          </Row>
          <form className="mb-5 contact-form our-partners our-partners2 mx-auto p-0" onSubmit={this.contactUS} style={{paddingLeft:"12px"}}>
            <input
              type="text"
              id="name"
              title='Name should contain minimum 2 alphabets.'
              oninput="this.setCustomValidity('')"
              pattern="[\w]{2,}[a-zA-Z\s]{1,}"
              className="my-2 connect-input"
              placeholder="* Name" required
              value={this.state.name}
              onChange={(e)=>{this.setState({name:e.target.value})}}
              style={{width:"100%", paddingLeft:'36px', border:"1px solid #717171", borderRadius:"18px", height:"68px", fontSize:"24px"}}
            />
            <input
              type="email"
              id="email"
              pattern="[a-zA-Z][0-9a-zA-Z\.\-]{2,}[@][a-z]{2,}([\.][a-z]{2,3}){1,2}"
              className="my-2 connect-input" required
              placeholder="* Email Address"
              value={this.state.email}
              onChange={(e)=>{this.setState({email:e.target.value})}}
              style={{width:"100%", paddingLeft:'36px', border:"1px solid #717171", borderRadius:"18px", height:"68px", fontSize:"24px"}}
            />
            <input
              type="tel"
              id="phone"
              pattern="[6-9][\d]{9}"
              title='Contact Number length should be 10 digits long and should begin with any digit between 6-9.'
              oninput="this.setCustomValidity('')"
              className="my-2 connect-input" required
              placeholder="* Contact Number"
              value={this.state.number}
              onChange={(e)=>{this.setState({number:e.target.value})}}
              style={{width:"100%", paddingLeft:'36px', border:"1px solid #717171", borderRadius:"18px", height:"68px", fontSize:"24px"}}
            />
            <input
              type="text"
              id="companyName"
              className="my-2 connect-input"
              pattern="[\w]{1,}[a-zA-Z\d\s\.]{1,}"
              title='Company Name should begin with an alphabet and can contain only digits, alphabets, spaces and "." .'
              oninput="this.setCustomValidity('')"
              placeholder="* Company" required
              value={this.state.company}
              onChange={(e)=>{this.setState({company:e.target.value})}}
              style={{width:"100%", paddingLeft:'36px', border:"1px solid #717171", borderRadius:"18px", height:"68px", fontSize:"24px"}}
            />
            <input
              type="text"
              id="message"
              className="my-2 connect-input"
              placeholder="* Message" required
              value={this.state.message}
              onChange={(e)=>{this.setState({message:e.target.value})}}
              style={{width:"100%", paddingLeft:'36px', border:"1px solid #717171", borderRadius:"18px", height:"68px", fontSize:"24px"}}
            />
            <input 
              type="submit" 
              value="SUBMIT"
              id="mySubmit"
              className="mt-5"
              style={{backgroundColor:"#EC224E", height:"56px", width:"180px", color:"white", border:"none", boxShadow:"0px 4px 4px rgba(0, 0, 0, 0.25)", borderRadius:"5px"}}
            />
          </form>
        </section>
        <Snackbar open={this.state.successOpen} autoHideDuration={5000} onClose={this.handleClose}>
          <Alert onClose={this.handleClose} severity="success" sx={{ width: '100%' }}>
            Thank You for contacting us!
          </Alert>
        </Snackbar>
        <Snackbar open={this.state.errorOpen} autoHideDuration={5000} onClose={this.handleClose}>
          <Alert onClose={this.handleClose} severity="error" sx={{ width: '100%' }}>
            Please provide correct details!
          </Alert>
        </Snackbar>
        <OurProductDemoModal
          productsDemoModalToggle={this.productsDemoModalToggle}
          ourProductModalIsOpen={this.state.isProductDemoModalOpen}
        />
      </Container>
    );
  }
}

export default HomePage;
