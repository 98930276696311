import React from "react";
import { Container, Row, Col } from "reactstrap";

class LendboxAgreement extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <Container fluid className="p-3" style={{ maxWidth: "1000px", lineHeight:"1.5" }}>
        <center><h3><b><u>LENDBOX AGREEMENT</u></b></h3></center><br/><br/>
        <p>
          This Loan Agreement (“Agreement”) is executed at <b>Delhi</b> on this <b> «&#10100;date&#10101;» </b> day of <b> «&#10100;month&#10101;», «&#10100;Year&#10101;» </b>
        </p>
        <br/>
        <p><center>BY AND BETWEEN</center></p>
        <br />
        <p>
          Lender(s) registered with Transactree Technologies Private Limited, a P2P NBFC licensed by the Reserve Bank of India, on the platform <a href="https://www.lendbox.in" target="_blank">www.lendbox.in</a>;
        </p>
        <br/>
        <p><center>AND</center></p>
        <br />
        <p>
          <b>«&#10100;BorTitle&#10101;» «&#10100;BorName&#10101;»</b>, an Indian national holding PAN <b>«&#10100;BorPan&#10101;» , «&#10100;noitaler&#10101;» «&#10100;BorFather&#10101;»</b> , Resident of <b>«&#10100;BorAddress&#10101;»</b>, hereinafter referred to as the “Borrower” which expression unless repugnant to the context shall mean and includes its legal representatives, permitted assigns and successors.
        </p>
        <br />
        <br />
        <br />
        <p>
          The Lender and the Borrower are hereinafter individually referred to as the <b>“Party”</b> and collectively as the <b>“Parties”</b>. 
        </p>
        <br />
        <p>
          <b>WHEREAS</b> the parties have agreed to enter into this agreement on the terms and conditions contained hereinafter as follows:
        </p>
        <br/>
        <ol type="1">
          <li>
            The Lender agrees to lend to the Borrower and the Borrower agrees to borrow a sum of Rs <b>«&#10100;LoanAmt&#10101;»</b> to be repaid in <b>«&#10100;LoanDur&#10101;»</b> equated monthly 
            installments (“EMIs”) starting from one month from the date of disbursement, at an interest of <b>«&#10100;LoanRate&#10101;»</b>  per annum in accordance with the terms 
            and conditions set out in this Agreement including the repayment schedule specified in Schedule 1 herein. 
          </li>
          <br/>
          <li>
            Lender to this loan may be more than one depending on the total loan amount. For detailed lender information visit <a href="https://www.lendbox.in" target="_blank">www.lendbox.in</a>
          </li>
          <br/>
          <li>
            Lendbox has entered into an agreement with <b>XXX</b> (hereinafter referred to as the “Partner”). The Borrower is a customer registered on the PARTNER’S 
            platform and BOTH PARTIES agree that the terms of this agreement are subject to the terms and conditions laid down in the Service Agreement dated XX/XX/202X  
            signed between Lendbox & PARTNER – described in detail in Annexure 1.
          </li>
          <br/>
          <li>
            The Parties acknowledge that the transaction of lending and repayment is being facilitated by <b>Transactree Technologies Private Limited</b> (hereinafter <b>“Lendbox”</b>)
            through their platform <a href="https://www.lendbox.in" target="_blank">www.lendbox.in</a>
          </li>
          <br/>
          <li>
            The Lender shall deposit the amount in the unique virtual escrow account being operated by Lendbox details of which have been provided to the Lender. 
            The amount shall be transferred into Partner’s account by Lendbox no later than 48 hours from the time of funding of the escrow account by the Lender, 
            subject to any banking holiday and such delay if any will not change the EMI Due Date in any manner. It is the partner’s responsibility to transfer the 
            amount into Borrower’s account.  The Borrower shall deposit the EMIs with the partner and the partner shall transfer these EMIs in the account specified by 
            Lendbox, being the escrow account of Lendbox for repayments. On the due date for the EMI (“EMI Due Date”) of every calendar month based on the EMI commencement 
            date as input by the system, on behalf of the Lender.
          </li>
          <br/>
          <li>
            The Borrower as collateral shall provide 2 (two) cheques to Lendbox for the loan to be repaid. The Borrower shall also sign and handover, prior to the 
            simultaneously with the disbursement of the loan, the National Automated Clearing House mandate <b>“NACH Mandate”</b> as implemented by the National Payments 
            Corporation of India authorizing Lendbox or an authorized representative of Lendbox, to deduct the EMI from the Borrower’s bank account of choice one day 
            prior to the EMI Due Date. 
          </li>
          <br/>
          <li>
            The Borrower shall repay the Loan (including interest, additional interest and other charges thereon) by way of Equated Monthly Instalments (EMI) 
            payable month, commencing from the month succeeding the month of disbursement of Loan. The EMI date will be the date on which the loan is disbursed. 
            For example, if a loan is disbursed on 25th, then the EMI date will also be 25th of every month. The EMIs shall continue till full repayment of the 
            Loan (including interest, additional interest, and other charges thereon). This clause supersedes and over-writes the dates set out in the repayment 
            schedule and any other contradictory clause(s) in the Agreement.
          </li>
          <br/>
          <li>
            The Borrower understands that the account of the Borrower shall be deducted by an automated system based on the NACH mandate given and agrees to 
            ensure that the account has sufficient balance 1 Business Day prior to the due date of the EMI.
          </li>
          <br/>
          <li>
            In the event of a failure by the Borrower to pay an EMI, the Borrower shall be liable to pay an additional interest at the rate of 18% per 
            annum (<b>“additional interest”</b>) on the entire outstanding loan amount plus the interest accruing as per the repayment schedule specified in Schedule 
            1 till the date of final repayment and charges of Rs. 500/- (Rupees Five Hundred) for every time a cheque or the NACH mandate is not honored. 
          </li>
          <br/>
          <li>
            The Borrower may, subject to applicable law, prepay the entire outstanding Loan Amount along with interest, additional interest and other charges as 
            applicable, at any time as per the repayment schedule specified in schedule 1. 
          </li>
          <br/>
          <li>
            Each Party represents and warrants that it is entitled to enter into this Agreement and to undertake the obligations herein contained. The Lender
            represents and warrants that the funds for the loan as specified in Clause 1 shall be ready and available in the escrow account of Lendbox on or 
            prior to the date of this Agreement, failing which the Repayment Schedule and the EMI Due Date would stand altered.
          </li>
          <br/>
          <li>
            Each Party further represents and warrants that its respective signatory, as applicable, to this Agreement are duly authorized to execute the 
            same in a manner binding upon the Party and that all approvals and procedures necessary for vesting such authority in its signatory have been 
            duly obtained and complied with.
          </li>
          <br/>
          <li>
            The occurrence of any of the following events, or events similar thereto, shall each constitute an event of default (<b>“Event of Default”</b>):
            <ol type="a">
              <li>
                The Borrower does not pay any amount due and payable pursuant to this Agreement in accordance with the terms of this Agreement on the 
                date such amount is payable;
              </li>
              <br/>
              <li>
                The breach of, or omission to observe, or default by the Borrower in observing any of its obligations, covenants, warranties, undertakings 
                under this Agreement;
              </li>
              <br/>
              <li>
                Any representation or statement made or deemed to be made by the Borrower in this Agreement or any other document delivered by or on behalf 
                of the Borrower under or in connection with the Agreement being or being proven to have been incorrect or misleading when made or deemed to 
                be made; or
              </li>
              <br/>
              <li>
                The Borrower is declared insolvent or files for insolvency or any attachment, distress execution or any other such process is initiated against the Borrower.
              </li>
            </ol>
          </li>
          <br/>
          <li>
            Upon the occurrence of an Event of Default, the Lender and Lendbox on behalf of the Lender, shall, without the requirement of serving any notice/default 
            notice to the Borrower:
            <ol type="a">
              <li>
                declare that the Loan Amount, together with accrued interest, additional interest and all other amounts accrued or outstanding under this 
                Agreement be immediately due and payable, whereupon they shall become immediately due and payable;
              </li>
              <br/>
              <li>
                encash or present for encashment, the undated, postdated, signed cheques provided as Collateral  in order to recover any or all of the 
                amounts outstanding to the Lender; or
              </li>
              <br/>
              <li>
                exercise such other rights as may be available to the Lender under Applicable Law.
              </li>
            </ol>
          </li>
          <br/>
          <li>
            This Agreement shall come into force on the date of execution of this Agreement and shall remain in full force until the repayment of 
            the entire portion of the Loan Amount along with all interest, additional interest, dues, charges, taxes as may be applicable.
          </li>
          <br/>
          <li>
            The Parties agree and undertake that they shall, at all times, during the continuance of this Agreement, ensure that all the terms 
            and conditions of this Agreement and all information relating to the Loan Amount shall be kept confidential (<b>“Confidential Information”</b>) 
            and they shall not disclose any information relating to this Agreement to any third party, without the prior written consent of the other Party 
            other than information relating to:
            <ol type="a">
              <li>
                information and data relating to it;
              </li>
              <br/>
              <li>
                information or data relating to any credit facility availed by / to be availed by it; 
              </li>
              <br/>
              <li>
                default if any committed by it in discharge of our such obligation, as the Lender may deem appropriate and necessary to disclose and furnish 
                to credit information agencies and any other agency authorized in this behalf by Reserve Bank of India; which shall not be considered confidential in nature.
              </li>
            </ol>
          </li>
          <br/>
          <li>
            The Borrower agrees and undertakes that the information and data furnished by it to the Lender and to Lendbox pursuant to this Agreement and in relation thereto, 
            are true and correct and consents that any agency authorized by the Lender or Lendbox may use and process the information and data provided under this agreement.
          </li>
          <br/>
          <li>
            The Borrower shall indemnify and hold the Lender and Lendbox harmless from and against any and all loss, damage or other consequences which may arise or result 
            from giving the Loan to the Borrower and shall reimburse the Lender and/or Lendbox upon demand all such sums and shall upon request appear and defend at the 
            Borrower’s own cost and expenses any action which may be brought against the Lender in connection therewith and shall accept the statement of account presented 
            by the party claiming the loss as conclusive proof of the correctness of any such claim to be due from him. 
          </li>
          <br/>
          <li>
            The Agreement shall be governed by the laws of India and courts at [Delhi] shall have exclusive jurisdiction to settle any disputes which may arise out of or 
            in connection with this Agreement. The Parties irrevocably agree to submit to that jurisdiction, to the exclusion of other Courts.
          </li>
          <br/>
          <li>
            The Borrower shall reimburse and pay to the Lender all costs, charges and expenses, including stamp duty and legal costs on actual basis and other charges 
            and expenses which may be incurred in preparation of these presents and related to and or incidental documents and enforcement of the rights of the Lender 
            there under or any other document security created / executed by the Borrower in favour of the Lender.
          </li>
          <br/>
          <li>
            The Borrower shall not assign any of its rights or obligations under this Agreement. The Lender may assign this Agreement and all rights hereunder or 
            transfer the benefit of the whole or any part of this Agreement to any third party through Lendbox, without the prior consent of the Borrower. 
            Lendbox shall inform the Borrower of such assignment through their platform <a href="https://www.lendbox.in" target="_blank">www.lendbox.in</a> within 5 working days of such assignment
          </li>
          <br/>
          <li>
            Notwithstanding anything contained herein, the parties agree that they have authorized Lendbox to appoint a suitable agency as the recovery 
            agent (“Recovery Agent”) for recovery of any amounts due and payable under this Agreement. The Recovery Agents shall take all such reasonable 
            steps as might be necessary for recovery; however, the Recovery Agents do not guarantee an assured recovery of dues. That the charges being levied 
            by the Recovery Agent shall be to the account of the Lender. The Parties understand that Lendbox is only offering its assistance in recovery on account 
            of a default and in no manner is liable for any default, being a marketplace for prospective lenders and borrowers to meet and interact.
          </li>
          <br/>
          <li>
            The rights, powers and remedies given to the Lender by this Agreement shall be in addition to all rights, powers and remedies given to the Lender by 
            virtue of any other security, statute or rule of law. 
          </li>
          <br/>
          <li>
            The Parties hereto confirm and acknowledge that this Agreement shall constitute the entire agreement between them and shall supersede and override all 
            previous communications, either oral or written, between the Parties with respect to the subject matter of this Agreement, and no agreement or understanding 
            varying or extending the same shall be binding upon any Party hereto unless arising out of the specific provisions of this Agreement.
          </li>
          <br/>
          <li>
            No amendment, modification or addition to this Agreement shall be binding on all the Parties hereto unless set forth in writing and executed by them or 
            through their duly authorized representatives.
          </li>
          <br/>
          <li>
            If any term, provision, covenant or restriction of this Agreement is held by a court of competent jurisdiction to be invalid, void or unenforceable, 
            the remainder of the terms, provisions, covenants and restrictions of this Agreement shall remain in full force and effect and shall in no way be affected, 
            impaired or invalidated.
          </li>
          <br/>
          <li>
            Any notice or demand hereunder shall be in writing and sent through post at the address specified above or the address last known to the Party sending the 
            notice. Any notice sent under this agreement shall be deemed to be delivered 2 business days following the dispatch of the notice. 
          </li>
        </ol>
        <br />
        <br />
        <br />
        <br />
        <p>
          IN WITNESS WHEREOF, THE PARTIES HAVE EXECUTED AND DELIVERED THIS AGREEMENT ON THE DAY AND YEAR FIRST ABOVE WRITTEN.
        </p>
        <br/>
        <br/>
        <br/>
        <Row>
          <Col lg={3}>
            <p style={{fontSize:"14px"}}>
              For and on behalf of Lender <br/> <br/>
              ………………………………………………   <br/> <br/>
              <b>«&#10100;UserName&#10101;»</b>
            </p>
          </Col>
          <Col lg={6}>
          </Col>
          <Col lg={3}>
            <p style={{fontSize:"14px"}}>
              For and on behalf of Borrower <br/> <br/>
              ………………………………………………   <br/> <br/>
              <b>«&#10100;BorName&#10101;»</b>
            </p>
          </Col>
        </Row>
        <br/>

        <h><u><b><center style={{paddingTop:"200px"}}>SCHEDULE I</center></b></u></h>
        <br/>
        <b><center style={{fontSize:"16px"}}>Repayment Schedule</center></b>
        <br/>
        <p>
          Borrower Name: <b>«&#10100;BorName&#10101;»</b> <br/>
          Loan Amount: <b>«&#10100;LoanAmt&#10101;»</b> <br/>
          Partner/Loan Interest Rate: <b>«&#10100;LoanRate&#10101;»</b> per annum <br/>
          Lender Rate of Interest: <br/>
          Tenure: <b>«&#10100;LoanDur&#10101;»</b> <br/>
          EMI per month: <b>«&#10100;EMIVal&#10101;»</b> <br/>
        </p>
        <br />
        <br/>
        <table style={{border:"solid 1px black", width:"100%"}}>
          <tr>
            <th className="ps-2" style={{border:"solid 1px black"}}>Month</th>
            <th className="ps-2" style={{border:"solid 1px black"}}>Month #</th>
            <th className="ps-2" style={{border:"solid 1px black"}}>EMI</th>
            <th className="ps-2" style={{border:"solid 1px black"}}>Principal</th>
            <th className="ps-2" style={{border:"solid 1px black"}}>Interest</th>
            <th className="ps-2" style={{border:"solid 1px black"}}>Balance</th>
          </tr>
          <tr>
            <td className="ps-2" style={{border:"solid 1px black"}}>Ib_month</td>
            <td className="ps-2" style={{border:"solid 1px black"}}>Ib_month_no</td>
            <td className="ps-2" style={{border:"solid 1px black"}}>Ib_emi</td>
            <td className="ps-2" style={{border:"solid 1px black"}}>Ib_princi</td>
            <td className="ps-2" style={{border:"solid 1px black"}}>Ib_inter</td>
            <td className="ps-2" style={{border:"solid 1px black"}}>Ib_bal</td>
          </tr>
        </table>
        <br/>
        <br/>
        <p className="px-4" style={{fontSize:"16px"}}>
          <b>PLEASE NOTE: THE ACTUAL EMI START DATE CAN VARY BASED ON THE DATE THE FUNDS HAVE BEEN TRANSFERRED TO THE BORROWER. LENDBOX ACCEPTS NO RESPONSIBILITY FOR DELAY IN TRANSFERS DUE TO BANK HOLIDAYS, BANK SYSTEM OUTAGES OR DELAYS IN TRANSFERRING FUNDS BY ITS USERS.</b>
        </p>
        <br/>

        <h><u><b><center style={{paddingTop:"200px"}}>Annexure 1</center></b></u></h>
        <br/>
        <br/>
        <p>
          PARTNER: <b>XXXXX</b>(CIN –<b>XXXXXX</b>) 
        </p>
        <p style={{marginBottom:"300px"}}>
          Lendbox has entered into a service agreement with <b>XXXXX</b> dated <b>XX/XX/202X</b> to provide EMI based loans to their customers.
        </p>
        <br />

      </Container>
    );
  }
}

export default LendboxAgreement;
