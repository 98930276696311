import React from "react";
import { Link } from "react-router-dom";
import OurTeamMembers from "./components/our-team-members";
import { Container, Col } from "reactstrap";
import SubscribeNewsletter from "./components/subscribe-newsletter";
import lia4 from "../assets/images/lia4.svg";
import "./styles/about-us.scss";

class AboutUs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    return (
      <Container fluid className="px-0 pt-1">
        <section>
          <Col
            md={6}
            className="my-5 text-center mx-auto"
            style={{ fontFamily: "Inter", fontWeight: 500, fontSize: "20px" }}
          >
            <p
              style={{
                color: "#212121",
                marginTop: "50px",
              }}
            >
              About Us
            </p>
            <p
              className="px-3"
              style={{
                marginTop: "70px",
                marginBottom: "70px",
                fontSize: "16px",
                fontWeight: "normal",
              }}
            >
              microfinance.ai's is an AI-powered mobile platform featuring
              simple to use customer onboarding, screening and product
              management tools in a secure and safe environment. The platform
              addresses language gaps, uses a voice-assisted user interface and
              speech-to-text for app inputs. We offer micro-lending partners a
              dedicated web dashboard to see and manage the borrower profile,
              append alternate data for underwriting in addition to credit score
              integration, digital communication with borrowers and AI-powered
              early warning on a possible loan default. In addition, the
              platform enables the simple integration of add-on products such as
              digital micro-insurance to enable partners to increase its revenue
              stream. Our mobile platform can be linked with any back-end
              software or work independently and help partner institutions to
              win in this digital transformation.
            </p>
          </Col>
        </section>
        <section>
          <Container className="py-5 my-5 text-center">
            <p className="home-title-text" style={{ fontSize: "18px" }}>
              Meet the team
            </p>
            <OurTeamMembers />
          </Container>
        </section>
        <section>
          <Container fluid className="join-our-team-div">
            <p className="join-text">
              Interested in joining our team?{" "}
              <br className="d-block d-md-none d-lg-none d-xl-none" />
              <Link to="/careers" style={{ textDecoration: "none" }}>
                <span style={{ color: "#194379" }}>see our open positions</span>
              </Link>
            </p>
            <img src={lia4} alt="lia" className="lia-img" />
          </Container>
        </section>
        <section>
          <Container fluid className="text-center d-flex py-5 my-5">
            <SubscribeNewsletter />
          </Container>
        </section>
      </Container>
    );
  }
}

export default AboutUs;
