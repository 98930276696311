import React from "react";
import {
  Collapse,
  Navbar,
  Container,
  Row,
  Col,
  NavbarToggler,
  NavbarBrand,
  Nav,
  Button,
  NavItem,
  NavLink,
} from "reactstrap";
import { X, Maximize } from "react-feather";
import { Link } from "react-router-dom";
import Select, { components } from 'react-select';
import dropdownIndicator from "../../assets/images/dropdownIndicator.svg";
import linkedinNavbarIcon from "../../assets/images/linkedin-navbar-icon.png";
import mailNavbarIcon from "../../assets/images/mailIcon.svg";
import instaNavbarIcon from "../../assets/images/navbarInstagram.svg";
import fbNavbarIcon from "../../assets/images/facebookNavbar.svg";
import mailIcon from "../../assets/images/mail.svg";
import twitterNavbarIcon from "../../assets/images/twitterNavbar.svg";
import ytNavbarIcon from "../../assets/images/youtubeNavbar.svg";
import liNavbarIcon from "../../assets/images/linkedin.svg";
import navbarDropdown from "../../assets/images/navbarDropdown.svg";
import lytLogo from "../../assets/images/lyt-logo.png";
import mfiText from "../../assets/images/mfiText.svg";
import "../styles/navbar.scss";
import fi_menu from "../../assets/images/fi_menu.svg";
import { backDropColor } from "../../utils/commonUtils";

const CaretDownIcon = () => {
  return <img src={dropdownIndicator} />
};

const DropdownIndicator = props => {
  return (
    <components.DropdownIndicator {...props}>
      <CaretDownIcon />
    </components.DropdownIndicator>
  );
};

function openCareers() {
  window.open('https://angel.co/company/microfinanceai/jobs', '_blank')
}
class Navigationbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      closeRibbon:false,
      options: [
        {value:"0",label:"ENGLISH"},
        {value:"0",label:"HINDI"},
      ],
    };
  }

  componentDidMount(){
    setInterval(() => {
      if(window.scrollY>200 || window.innerWidth<1200){
        this.setState({
          closeRibbon:true,
        })
      } else{
        this.setState({
          closeRibbon:false,
        })
      }
    }, 100);
  }

  toggle = () => {
    console.log(document.getElementById("parent-container"));
    if (!this.state.isOpen) {
      document.getElementById("parent-container").style.backgroundColor = backDropColor;
      console.log('Done');
    }
    else {
      document.getElementById("parent-container").style.backgroundColor = "white";
      console.log('Done2');
    }
    this.setState({ isOpen: !this.state.isOpen }, ()=>{
      console.log(this.state.isOpen);
    });
  };

  customStyles = {
    control: (provided, state) => ({
      ...provided,
      background: '#fff',
      borderColor: '#EC224E',
      minHeight: '2.9vh',
      height: '2.9vh',
      boxShadow: state.isFocused ? null : null,
    }),

    valueContainer: (provided, state) => ({
      ...provided,
      height: '2.9vh',
      padding: '0 6px'
    }),

    input: (provided, state) => ({
      ...provided,
      margin: '0px',
    }),
    indicatorSeparator: state => ({
      display: 'none',
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: '2.9vh',
    }),
  };
  render() {
    return (
      <>
        <Row className="m-auto" style={{width:"100%", display: this.state.closeRibbon ?"none":"flex", backgroundColor:"#EC224E"}}>
          <div className="topRibbon" >
            <h4 className="mx-0 my-auto ribbonHeading d-inline-flex"> WE ARE NOW HIRING !</h4>
            <Button className="d-inline-flex mb-0" style={{color:"white", marginTop:'-11px', height:'30px', background:'transparent', border:'none', textDecoration:'underline', marginLeft:"0rem"}} onClick={openCareers}>
              Know More
            </Button>
            <div className="d-inline-flex" style={{justifyContent:"right", textAlign:"right", float:"right", marginRight:"101px"}}>
              <img className="my-auto" src={mailNavbarIcon}></img>
              <a href='mailto:contact@microfinance.ai' style={{textDecoration:"none", color:"white", display: "inline-block",marginTop: "1px"}}>
                <p className="my-auto">contact@microfinance.ai</p>
              </a>
              <Row style={{marginLeft:"4rem", marginRight:"4rem"}}>
                <Col>
                  <a href="https://www.instagram.com/vittofin/" target="_blank" rel="noopener noreferrer">
                    <img className="my-auto" src={instaNavbarIcon}></img>
                  </a>
                </Col>
                <Col>
                  <a href="https://www.facebook.com/VittoFinance" target="_blank" rel="noopener noreferrer">
                    <img className="my-auto" src={fbNavbarIcon}></img>
                  </a>
                </Col>
                <Col>
                  <a href="https://twitter.com/MicrofinanceAi" target="_blank" rel="noopener noreferrer">
                    <img className="my-auto" src={twitterNavbarIcon}></img>
                  </a>
                </Col>
                <Col>
                  <a href="https://www.youtube.com/channel/UC4Pm30VhwXY3-3phnkWIrVg/featured" target="_blank" rel="noopener noreferrer">
                    <img className="my-auto" style={{marginTop:'5px'}} src={ytNavbarIcon}></img>
                  </a>
                </Col>
                <Col>
                  <a href="https://www.linkedin.com/company/56422789" target="_blank" rel="noopener noreferrer">
                    <img style={{height:'27px', marginTop:'-3px', marginLeft:'-4px'}} src={liNavbarIcon}></img>
                  </a>
                </Col>
              </Row>
              <div style={{zIndex:"100000"}}>
                <select className="language-select" disabled>
                  <option>ENGLISH</option>  
                  <option>HINDI</option>  
                </select>
              </div>
            </div>
          </div>
        </Row>
        <Navbar light expand="md" className="navigationBar my-0">
          <Link to="/" style={{ textDecoration: "none" }}>
            <NavbarBrand
              className="mfai-privilaged"
              style={{ fontWeight: 600 }}
            >
              <img src={lytLogo} alt="lyt-logo" className="lytNavLogo" />{" "}
            </NavbarBrand>
          </Link>
          <button aria-label="Toggle navigation" type="button" class="navbar-toggler text-right" onClick={()=>this.toggle()}>
            <span class="navbar-toggler-icon">
              {this.state.isOpen?<span><X color="#EC224E" className={"d-block m-auto"}/></span>:<img src={fi_menu} alt="toggler-icon"></img>}
              
            </span>
          </button>
          <Collapse isOpen={false} navbar id="collapse">
            <Nav className="m-auto" navbar>
              <NavItem className="mfai-nav-item navbar-selected py-auto px-2">
                <Link to="/" style={{ textDecoration: "none" }}>
                  <NavLink
                    href="/"
                    className={
                      window.location.pathname === "/" ? "activeComp" : ""
                    }
                  >
                    HOME
                  </NavLink>
                </Link>
              </NavItem>
              <NavItem className="mfai-nav-item px-2">
                <Link to="/platform" style={{ textDecoration: "none" }}>
                  <NavLink
                    href="/platform"
                    className={
                      window.location.pathname === "/platform" ||
                      window.location.pathname.slice(0,9) === "/platform"
                        ? "activeComp"
                        : ""
                    }
                  >
                    THE PLATFORM
                  </NavLink>
                </Link>
              </NavItem>
              {/* <NavItem className="mfai-nav-item px-2">
                <Link to="/ourBlog" style={{ textDecoration: "none" }}>
                  <NavLink
                    href="/ourBlog"
                    className={
                      window.location.pathname === "/ourBlog" ? "activeComp" : ""
                    }
                  >
                    OUR BLOG
                  </NavLink>
                </Link>
              </NavItem> */}
              <NavItem className="mfai-nav-item px-2">
                {/* <Link to="/careers" style={{ textDecoration: "none" }}> */}
                <a href="https://angel.co/company/microfinanceai/jobs" style={{ textDecoration: "none" }}>
                  <NavLink
                    href="https://angel.co/company/microfinanceai/jobs"
                    className={
                      window.location.pathname.match(
                        /^\/careers(|(\/(\w|-|\d)*))$/gm
                      )
                        ? "activeComp"
                        : ""
                    }
                  >
                    CAREERS
                  </NavLink>
                </a>
                {/* </Link> */}
              </NavItem>
              <NavItem className="mfai-nav-item px-2">
                <Link to="/contactUS" style={{ textDecoration: "none" }}>
                  <NavLink
                    href="/contactUS"
                    className={
                      window.location.pathname === "/contactUS" ? "activeComp" : ""
                    }
                  >
                    CONTACT US
                  </NavLink>
                </Link>
              </NavItem>
            </Nav>
            <Link to="/contactUS" className="d-inline-flex" style={{maxWidth:"190px", textDecoration:"none"}}> 
              <Button className="partnerBtn partnerwithus" style={{justifyContent:"right", boxShadow:"0px 4px 4px rgba(0, 0, 0, 0.25)"}}>PARTNER WITH US</Button>
            </Link>
          </Collapse>
        </Navbar>
        <Container
          className={`mx-0 nav-items-mobile-menu px-0${
            this.state.isOpen ? " opened" : " closed"
          }`} style={{display:this.state.isOpen?"inherit": "none"}}
        >
          <hr className="mt-0 mb-2 hr" />
          <Col style={{ fontSize: "18px", fontWeight: 600, color: "#263640" }}>
            <Link to="/" style={{ textDecoration: "none" }}>
              <p
                className="nav-links"
                style={{ color: "#263640" }}
                onClick={this.toggle}
              >
                HOME
              </p>
            </Link>
            <Link to="/platform" style={{ textDecoration: "none" }}>
              <p
                className="nav-links"
                style={{ color: "#263640" }}
                onClick={this.toggle}
              >
                THE PLATFORM
              </p>
            </Link>
            {/* <Link to="/" style={{ textDecoration: "none" }}>
              <p
                className="nav-links"
                style={{ color: "#263640" }}
                onClick={this.toggle}
              >
                OUR BLOG
              </p>
            </Link> */}
            <a href="https://angel.co/company/microfinanceai/jobs" style={{ textDecoration: "none" }}>
              <p
                className="nav-links"
                style={{ color: "#263640" }}
                onClick={this.toggle}
              >
                CAREERS
              </p>
            </a>
            <Link to="/contactUS" style={{ textDecoration: "none" }}>
              <p
                className="nav-links"
                style={{ color: "#263640" }}
                onClick={this.toggle}
              >
                CONTACT US
              </p>
            </Link>
            <Button className="partnerBtn partnerwithus m-auto" style={{width:"199px", justifyContent:"right", boxShadow:"0px 4px 4px rgba(0, 0, 0, 0.25)", zIndex:10}}>PARTNER WITH US</Button>
            <Button className="contact-email"><img className="my-0" src={mailIcon}></img> contact@microfinance.ai</Button>
            <span className="contact-email">WE ARE NOW HIRING! <Link style={{color:'#EC224E'}} to="https://angel.co/company/microfinanceai/jobs">KNOW MORE</Link></span>
            <select className="language" style={{border:"0px", color:"#EC224E", display:"inline-block", background:'transparent', marginTop:'30px'}}>
              <option id="1" defaultChecked>ENGLISH</option>
              <option id="2">HINDI</option>
            </select>
          </Col>
        </Container>
      </>
    );
  }
}

export default Navigationbar;
