import React from "react";
import "./styles/contactUs.scss";
import { Container, Row, Col, Form } from "reactstrap";
import {contactus} from "../utils/apiUtils";
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import OurProductDemoModal from "./components/ourProductDemoModal";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});


class contactUs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isProductDemoModalOpen: false,
      subject:"",
      name:"",
      email:"",
      number: "",
      company:"",
      message:"",
      email:"",
      successOpen:false,
      errorOpen: false,
      disabledButton: true,
    };
  }

  handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({
      successOpen:false,
      errorOpen:false,
    })
  };

  contactUSForm =async (e)=>{
    e.preventDefault();
    try{
      document.getElementById("mySubmit").disabled = true;
      document.getElementById("mySubmit").style.backgroundColor = "#EC224E";
      let body={
        "recipients":[ "akash@uthaan.co.in","kapil@uthaan.co.in","swati@uthaan.co.in" ],
        "subject": "Contact Form",
        "body": `<div style="background-color: #f8f8f8;">
        <div style="max-width: 480px; background-color: #fff; margin: auto; position: relative;">
            <div style="padding-top: 22px; display: flex;">
                <span style="display: inline-block; padding-left: 41px; border-bottom: 4px solid #e5e5e5; font-family: 'Product Sans'; font-weight: 400; color: #EC224E; font-size: 20px;">Contact Us</span>
                <img style="height: 26px; margin-left: auto; margin-right: 9px; width: 76px;" src="https://s3.ap-south-1.amazonaws.com/microfinance.ai-website/mfiLogo.png" alt="">
            </div>
            <div style="padding-left: 40px; padding-right: 40px;">
                <p style="font-size: 18px; color: #000; margin-bottom: 0px;">Name: </p>
                <p style="font-size: 16px; color: #717171; margin-top: 0px;">${this.state.name}</p>
            </div>
            <div style="padding-left: 40px; padding-right: 40px;">
                <p style="font-size: 18px; color: #000; margin-bottom: 0px;">Email Id: </p>
                <p style="font-size: 16px; color: #717171; margin-top: 0px;">${this.state.email}</p>
            </div>
            <div style="padding-left: 40px; padding-right: 40px;">
                <p style="font-size: 18px; color: #000; margin-bottom: 0px;">Contact: </p>
                <a href="tel:${this.state.number}" style="font-size: 16px; color: #15c; margin-top: 0px;">${this.state.number}</a>
            </div>
            <div style="padding-left: 40px; padding-right: 40px;">
                <p style="font-size: 18px; color: #000; margin-bottom: 0px;">Company: </p>
                <p style="font-size: 16px; color: #717171; margin-top: 0px;">${this.state.company}</p>
            </div>
            <div style="padding-left: 40px; padding-right: 40px;">
                <p style="font-size: 18px; color: #000; margin-bottom: 0px;">Message: </p>
                <p style="font-size: 16px; color: #717171; margin-top: 0px; text-align: justify;">${this.state.message}</p>
            </div>
            <div style="height: 217px;"></div>
            <div style="height: 138px; border-right: 13px solid #EC224E;"></div>
        </div>
    </div>`,
        "isHtml": true ,
        "useCase": "mfai-web-contactUs",
      }
      let res= await contactus(body)
      if(res.status==="success"){
        this.setState({successOpen:true});
      }
    } catch(err){
      // document.getElementById("mySubmit").style.backgroundColor = "rgb(48, 108, 181)";
      // this.setState({disabledButton:false})
      this.setState({errorOpen:true});
      console.log(err);
    }
    document.getElementById("mySubmit").disabled = false;
  }

  productsDemoModalToggle = () =>
    this.setState(
      {
        isProductDemoModalOpen: !this.state.isProductDemoModalOpen,
      },
      () => {
        console.log("isOpen: ", this.state.isProductDemoModalOpen);
      }
    );

  render(){
    return( 
      <Container className="mx-auto contactUsMain" fluid style={{maxWidth:"1440px", marginBottom:"100px"}}>
        <Row className="px-4 mainRow">
          <Col xl={6} lg={12} className="px-4 py-5 d-none d-xl-block">
            <div className="px-4 py-5" style={{backgroundColor:"#F8F8F8", borderRadius:"25px", boxShadow:"0px 4px 4px rgba(0, 0, 0, 0.25)"}}>
              <Row>
                <Col className="cardHeading px-2">
                  Connect With Us
                </Col>
                <Col className="my-auto" style={{width:"90%"}}>
                  <div className="my-auto mx-0" style={{backgroundColor:"#EC224E", height:"4px",}}></div>
                </Col>
              </Row>
              <Form className="my-5 mx-auto p-0" onSubmit={(e)=>this.contactUSForm(e)} style={{paddingLeft:"12px", maxWidth:"1244px"}}>
                <input
                  type="text"
                  id="name"
                  title='Name should contain minimum 2 alphabets.'
                  onInput="this.setCustomValidity('')"
                  pattern="[\w]{2,}[a-zA-Z\s]{1,}"
                  className="px-4 my-2"
                  placeholder="* Name" required
                  value={this.state.name}
                  onChange={(e)=>{this.setState({name:e.target.value})}}
                  style={{width:"99%", border:"2px solid #717171", borderRadius:"18px", height:"88px", fontSize:"24px", fontColor:"#306CB5"}}
                />
                <input
                  type="email"
                  id="email"
                  pattern="(\w|\d|\-|\.){2,}@(\w|\d|\-){1,}([\.](\w){2,3}){1,2}"
                  className="px-4 my-2"
                  placeholder="* Email Address" required
                  value={this.state.email}
                  onChange={(e)=>{this.setState({email:e.target.value})}}
                  style={{width:"99%", border:"2px solid #717171", borderRadius:"18px", height:"88px", fontSize:"24px", fontColor:"#306CB5"}}
                />
                <input
                  type="tel"
                  id="phone"
                  pattern="[6-9][\d]{9}"
                  title='Contact Number length should be 10 digits long and should begin with any digit between 6-9.'
                  onInput="this.setCustomValidity('')"
                  className="px-4 my-2"
                  placeholder="* Contact Number" required
                  value={this.state.number}
                  onChange={(e)=>{this.setState({number:e.target.value})}}
                  style={{width:"99%", border:"2px solid #717171", borderRadius:"18px", height:"88px", fontSize:"24px", fontColor:"#306CB5"}}
                />
                <input
                  type="text"
                  id="companyName"
                  pattern="[a-zA-Z\s\.]{2,}"
                  title='Company Name should begin with an alphabet and can contain only digits, alphabets, spaces and "." .'
                  onInput="this.setCustomValidity('')"
                  className="px-4 my-2"
                  placeholder="* Company" required
                  value={this.state.company}
                  onChange={(e)=>{this.setState({company:e.target.value})}}
                  style={{width:"99%", border:"2px solid #717171", borderRadius:"18px", height:"88px", fontSize:"24px", fontColor:"#306CB5"}}
                />
                <input
                  type="text"
                  id="message"
                  className="px-4 my-2"
                  placeholder="* Message" required
                  value={this.state.message}
                  onChange={(e)=>{this.setState({message:e.target.value})}}
                  style={{width:"99%", border:"2px solid #717171", borderRadius:"18px", height:"88px", fontSize:"24px", fontColor:"#306CB5"}}
                />
                {this.disabledButton==true?<input type="submit" value="SUBMIT" disabled="true" id="mySubmit" className="mt-5" style={{backgroundColor:"#666", letterSpacing:"1px", height:"56px", width:"100%", color:"white", border:"none", boxShadow:"0px 4px 4px rgba(0, 0, 0, 0.25)", borderRadius:"5px"}} />:<input type="submit" value="SUBMIT" id="mySubmit" className="mt-5" style={{backgroundColor:"#EC224E", height:"56px", width:"100%", color:"white", border:"none", boxShadow:"0px 4px 4px rgba(0, 0, 0, 0.25)", borderRadius:"5px"}}/>}
              </Form>
            </div>
          </Col>
          <Col xl={6} lg={12} className="px-4 py-5 menuCol">
            <div className="px-4 py-5 helpSection">
              <p className="cardHeading px-2">How Can We Help?</p>
              <p className="px-2" style={{fontSize:"20px", paddingTop:"20px"}}>Please select a topic below related to your inquiry. If you don't find what you need, fill out our contact form.</p>
              <Row className="mx-2 mt-5" style={{borderBottom:"2px solid #EC224E", paddingBottom:"10px"}}>
                <Col className="px-0" >
                  <p className="mb-2" style={{fontSize:"20px"}}>Book a Demo</p>
                  <p className="descrptionText">Request a demo from one of our Microfinance.AI specialists.</p>
                </Col>
                <Col className="my-auto" lg={1} style={{maxWidth:"30px"}} onClick={()=>{this.setState({heading:"Book a Demo"},this.productsDemoModalToggle)}}>
                  <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M12 6.5L0.749998 12.9952L0.749999 0.00480889L12 6.5Z" fill="#717171"/>
                  </svg>
                </Col>
              </Row>
              <Row className="mx-2 mt-5" style={{borderBottom:"2px solid #EC224E", paddingBottom:"10px"}}>
                <Col className="px-0">
                  <p className="mb-2" style={{fontSize:"20px"}}>Become A Partner</p>
                  <p className="descrptionText">Join the revolution and be a part of changing almost a billion lives.</p>
                </Col>
                <Col className="my-auto" lg={1} style={{maxWidth:"30px"}} onClick={()=>{this.setState({heading:"Become A Partner"},this.productsDemoModalToggle)}}>
                  <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M12 6.5L0.749998 12.9952L0.749999 0.00480889L12 6.5Z" fill="#717171"/>
                  </svg>
                </Col>
              </Row>
              <Row className="mx-2 mt-5" style={{borderBottom:"2px solid #EC224E", paddingBottom:"10px"}}>
                <Col className="px-0">
                  <p className="mb-2" style={{fontSize:"20px"}}>Get Customer Support</p>
                  <p className="descrptionText">Drop us a message and our experts will get back to you within 24 hours.</p>
                </Col>
                <Col className="my-auto" lg={1} style={{maxWidth:"30px"}} onClick={()=>{this.setState({heading:"Get Customer Support"},this.productsDemoModalToggle)}}>
                  <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M12 6.5L0.749998 12.9952L0.749999 0.00480889L12 6.5Z" fill="#717171"/>
                  </svg>
                </Col>
              </Row>
            </div>
          </Col>
          <Col xl={6} lg={12} className="px-4 py-5 d-lg-block d-xl-none lowerForm">
            <div className="px-4 py-5 lowerFormBody" style={{backgroundColor:"#F8F8F8", borderRadius:"25px", boxShadow:"0px 4px 4px rgba(0, 0, 0, 0.25)"}}>
              <Row>
                <Col className="cardHeading px-2">
                  Connect With Us
                </Col>
                <Col className="my-auto" style={{width:"90%"}}>
                  <div className="my-auto mx-0" style={{backgroundColor:"#EC224E", height:"4px",}}></div>
                </Col>
              </Row>
              <Form className="my-5 mx-auto p-0" onSubmit={(e)=>this.contactUSForm(e)} style={{paddingLeft:"12px", maxWidth:"1244px"}}>
                <input
                  type="text"
                  id="name"
                  className="px-4 my-2"
                  pattern="[\w]{2,}[a-zA-Z\s]{1,}"
                  title='Name should contain minimum 2 alphabets.'
                  onInput="this.setCustomValidity('')"
                  placeholder="* Name" required
                  value={this.state.name}
                  onChange={(e)=>{this.setState({name:e.target.value})}}
                  style={{width:"99%", border:"2px solid #717171", borderRadius:"18px", height:"88px", fontSize:"24px", fontColor:"#306CB5"}}
                />
                <input
                  type="email"
                  id="email"
                  className="px-4 my-2"
                  placeholder="* Email Address" required
                  pattern="(\w|\d|\-|\.){2,}@(\w|\d|\-){1,}([\.](\w){2,3}){1,2}"
                  value={this.state.email}
                  onChange={(e)=>{this.setState({email:e.target.value})}}
                  style={{width:"99%", border:"2px solid #717171", borderRadius:"18px", height:"88px", fontSize:"24px", fontColor:"#306CB5"}}
                />
                <input
                  type="tel"
                  id="phone"
                  pattern="[6-9][\d]{9}"
                  title='Contact Number length should be 10 digits long and should begin with any digit between 6-9.'
                  onInput="this.setCustomValidity('')"
                  className="px-4 my-2"
                  placeholder="* Contact Number" required
                  value={this.state.number}
                  onChange={(e)=>{this.setState({email:e.target.value})}}
                  style={{width:"99%", border:"2px solid #717171", borderRadius:"18px", height:"88px", fontSize:"24px", fontColor:"#306CB5"}}
                />
                <input
                  type="text"
                  id="companyName"
                  className="px-4 my-2"
                  pattern="[\w]{1,}[a-zA-Z\d\s\.]{1,}"
                  title='Company Name should begin with an alphabet and can contain only digits, alphabets, spaces and "." .'
                  onInput="this.setCustomValidity('')"
                  placeholder="* Company" required
                  value={this.state.company}
                  onChange={(e)=>{this.setState({company:e.target.value})}}
                  style={{width:"99%", border:"2px solid #717171", borderRadius:"18px", height:"88px", fontSize:"24px", fontColor:"#306CB5"}}
                />
                <input
                  type="text"
                  id="message"
                  className="px-4 my-2"
                  placeholder="* Message" required
                  value={this.state.message}
                  onChange={(e)=>{this.setState({message:e.target.value})}}
                  style={{width:"99%", border:"2px solid #717171", borderRadius:"18px", height:"88px", fontSize:"24px", fontColor:"#306CB5"}}
                />
                <input 
                  type="submit" 
                  value="SUBMIT"
                  id="mySubmit"
                  className="mt-5"
                  style={{backgroundColor:"#EC224E", height:"56px", width:"100%", color:"white", border:"none", boxShadow:"0px 4px 4px rgba(0, 0, 0, 0.25)", borderRadius:"5px"}}
                />
              </Form>
            </div>
          </Col>
        </Row>
        <Snackbar open={this.state.successOpen} autoHideDuration={5000} onClose={this.handleClose}>
          <Alert onClose={this.handleClose} severity="success" sx={{ width: '100%' }}>
            Thank You for contacting us!
          </Alert>
        </Snackbar>
        <Snackbar open={this.state.errorOpen} autoHideDuration={5000} onClose={this.handleClose}>
          <Alert onClose={this.handleClose} severity="error" sx={{ width: '100%' }}>
            Please provide correct details!
          </Alert>
        </Snackbar>
        <OurProductDemoModal
          productsDemoModalToggle={this.productsDemoModalToggle}
          ourProductModalIsOpen={this.state.isProductDemoModalOpen}
          heading={this.state.heading}
        />
      </Container>
    )
  }
}

export default contactUs;