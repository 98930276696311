import React from "react";
import "./styles/thePlatform.scss";
import { Container, Row, Col } from "reactstrap";
import lytLogo from "../assets/images/lyt-logo.svg";
import LYTwhite from "../assets/images/LYTwhite.svg";
import LYTapp from "../assets/images/LYTapp.png";
import laptopIcon from "../assets/images/laptop.png";
import dashboardScreenshot from "../assets/images/dashboardScreenShot.png";
import videoAssistanceIcon from "../assets/images/videoAssistance.png";
import simpleUseIcon from "../assets/images/simpleUse.png";
import localLanguageIcon from "../assets/images/localLanguage.png";
import minimalStepsIcon from "../assets/images/minimalSteps.svg";
import zeroTypingIcon from "../assets/images/zeroTyping.svg";
import twoMinutesIcon from "../assets/images/twoMinutes.png";
import reducedCostIcon from "../assets/images/reducedCost.png";
import loanStatusIndicatorIcon from "../assets/images/loanStatusIndicator.png";
import paymentRemindersIcon from "../assets/images/paymentReminders.png";
import onlinePaymentIcon from "../assets/images/onlinePayment.png";
import topupLoansIcon from "../assets/images/topupLoans.png";
import customerSupportIcon from "../assets/images/customerSupport.png";
import animatedContentIcon from "../assets/images/animatedContent.png";
import tutorialVideosIcon from "../assets/images/tutorialVideos.png";
import gamesQuizzesIcon from "../assets/images/gamesQuizzes.png";
import portfolioPerformanceIcon from "../assets/images/portfolioPerformance.png";
import customerExposureIcon from "../assets/images/customerExposure.png";
import deliquencyReportsIcon from "../assets/images/deliquencyReports.png";
import customerHistoryIcon from "../assets/images/customerHistory.png";
import dataScanningIcon from "../assets/images/dataScanning.png";
import facialRecognitionIcon from "../assets/images/facialRecognition.png";
import fraudDetectionIcon from "../assets/images/fraudDetection.png";
import inAppCRMIcon from "../assets/images/inAppCRM.png";

class thePlatform extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount(){
    if(window.location.pathname.length > 9){
      const sectionID= window.location.pathname.slice(10, window.location.pathname.length);
      const anchor = document.querySelector(`#${sectionID}`)
      anchor.scrollIntoView({ behavior: 'smooth', block: 'center' })
    }
  }

  render(){
    return(
      <Container fluid className="mx-0 px-0 platformFeatures" style={{marginTop:"2rem"}}>
        <Row className="mx-0 pt-3" style={{marginTop:"40px"}}>
          <div style={{marginTop:"55px"}} >
            <p className="weMFI mb-0 mx-0" style={{display:"inline-flex", left:0, position:"relative", marginLeft:"10px", background:"white"}}>The PLATFORM</p>
            <div className="px-0">
              <div className="hr-weMFI" style={{backgroundColor:"#EC224E",  maxHeight:"6px",float:"none", overflow:"hidden"}}></div>
            </div>
          </div>
        </Row>
        <Row className="red-bar platform-red-bar">
            <div className="row weMFI vitto-app-designed"  style={{maxWidth:"1035px", backgroundColor:"#EC224E", boxShadow:"0px 4px 4px rgba(0, 0, 0, 0.25)", color:"white", 
              borderTopRightRadius:"25px", borderBottomRightRadius:"25px"}}>
              <div className="col-desktop-2 col-tablet-4 col-mobile-4 px-0"></div>
              <div className="col-desktop-10 col-tablet-8 col-mobile-5 pl-3 pr-0 d-flex align-items-center">
                <img src={lytLogo}></img>
                <span className="pt-2">&nbsp;&nbsp;APP <span className="only-desktop">(Designed for borrowers)</span></span>
              </div>
              <Row>
                <div className=" col-tablet-4 col-mobile-4 col-desktop-0 px-0"></div>
                <div className="col-tablet-8 col-mobile-8 d-lg-block d-xl-none px-0 pt-2 d-flex align-items-center sub-text"><p> (Designed for borrowers)</p></div>
              </Row>
            </div>
          </Row>
        <img data-aos="fade-right" data-aos-offset="200" data-aos-duration="1200" data-aos-easing="ease-in-sine" src={LYTapp} 
          className="vitto-app">
        </img>
        <Container className="py-5 menuContainer"  style={{marginTop:"-180px"}}>
          <section id="assistiveAI">
            <h3 className="platform-headings"> Assistive and Vernacular AI </h3>
            <p className="mt-2 platform-paras">Designed to be used by all people with zero language or technological barrier, our app is offered in multiple local 
              languages and guides the borrowers through every step of the process.
            </p>
            <Row className="my-5">
              <Col>
                <img className="growOnHover growOnHover2" src={videoAssistanceIcon}/>
                <h4 className="mt-3 imgTitle" style={{position:"relative", top:"17px"}}>Video Assistance</h4>
              </Col>
              <Col>
                <img className="growOnHover growOnHover2" src={simpleUseIcon}/>
                <h4 className="mt-3 imgTitle" style={{position:"relative", top:"10px"}}>Simple To Use UI</h4>
              </Col>
              <Col>
                <img className="growOnHover growOnHover2" src={localLanguageIcon}/>
                <h4 className="mt-3 imgTitle">Local Language Support</h4>
              </Col>
              <Col className="d-none d-xl-block"></Col>
              <Col className="d-none d-xl-block"></Col>
            </Row>
          </section>
          <section id="saveTime" style={{marginTop:"6rem"}}>
            <h3 className="platform-headings"> Save Your Time </h3>
            <p className="mt-2 platform-paras">Our platform provides various actionable customer insights and will assist from 
              customer onboarding to field officer planning and assistance. With Our borrower centric mobile 
              application, we bring the loan and other financial products, hassle free and completely online, 
              at the users fingertips, thus empowering micro-lenders to maintain relation digitally.
            </p>
            <Row className="my-5">
              <Col>
                <img className="growOnHover growOnHover2" src={minimalStepsIcon}/>
                <h4 className="mt-3 imgTitle">Minimal Number Of Steps</h4>
              </Col>
              <Col>
                <img className="growOnHover growOnHover2" src={zeroTypingIcon}/>
                <h4 className="mt-3 imgTitle" style={{position:"relative", left:"-12px"}}>Zero typing</h4>
              </Col>
              <Col>
                <img className="growOnHover growOnHover2" src={twoMinutesIcon}/>
                <h4 className="mt-3 imgTitle">2 Minutes Loan Application</h4>
              </Col>
              <Col className="d-none d-xl-block"></Col>
              <Col className="d-none d-xl-block"></Col>
            </Row>
          </section>
          <section id="reduceCost" style={{marginTop:"6rem"}}>
            <h3 className="platform-headings"> Reduce Cost </h3>
            <p className="mt-2 platform-paras">Client onboarding and monitoring costs take up to 5% of the 
              total operating costs. With our remote onboarding and client monitoring take control 
              of this cost and reduce the total cost of upto 2% with wow experience for your clients.
            </p>
            <Row className="my-5">
              <Col>
                <img className="growOnHover growOnHover2" src={reducedCostIcon}/>
                <h4 className="mt-3 imgTitle">Reduction In Operating Cost</h4>
              </Col>
              <Col></Col>
              <Col></Col>
              <Col className="d-none d-xl-block"></Col>
              <Col className="d-none d-xl-block"></Col>
            </Row>
          </section>
          <section id="moreControl" style={{marginTop:"6rem"}}>
            <h3 className="platform-headings"> Have More Control  </h3>
            <p className="mt-2 platform-paras"> Our mobile application provides you with the loan status, 
              payment reminders, Online payment, Top-up loans and much more. Complete KYC process 
              online and rest free with our commitment to safety and privacy of your data.
            </p>
            <Row className="my-5 d-none d-xl-flex">
              <Col>
                <img className="growOnHover growOnHover2" src={loanStatusIndicatorIcon}/>
                <h4 className="mt-3 imgTitle" style={{position:"relative", top:"24px"}}>Loan Status Indicator</h4>
              </Col>
              <Col>
                <img className="growOnHover growOnHover2" src={paymentRemindersIcon}/>
                <h4 className="mt-3 imgTitle" style={{position:"relative", top:"15px"}}>Early Payment Reminders</h4>
              </Col>
              <Col>
                <img className="growOnHover growOnHover2" src={onlinePaymentIcon}/>
                <h4 className="mt-3 imgTitle">Online Payment</h4>
              </Col>
              <Col>
                <img className="growOnHover growOnHover2" src={topupLoansIcon}/>
                <h4 className="mt-3 imgTitle" style={{position:"relative", top:"25px"}}>Top - Up  Loans</h4>
              </Col>
              <Col>
                <img className="growOnHover growOnHover2" src={customerSupportIcon}/>
                <h4 className="mt-3 imgTitle" style={{position:"relative", top:"5px"}}>Customer Support</h4>
              </Col>
            </Row>
            <Row className="my-5 d-inherit d-xl-none">
              <Col className="mt-2" lg={4} md={4} sm={4} xs={4} style={{textAlign:"center"}}>
                <img className="growOnHover growOnHover2" src={loanStatusIndicatorIcon}/>
                <h4 className="mt-2 imgTitle" >Loan Status Indicator</h4>
              </Col>
              <Col className="mt-2" lg={4} md={4} sm={4} xs={4} style={{textAlign:"center"}}>
                <img className="growOnHover growOnHover2" src={paymentRemindersIcon}/>
                <h4 className="mt-2 imgTitle">Early Payment Reminders</h4>
              </Col>
              <Col className="mt-2" lg={4} md={4} sm={4} xs={4} style={{textAlign:"center"}}>
                <img className="growOnHover growOnHover2" src={onlinePaymentIcon}/>
                <h4 className="mt-2 imgTitle">Online Payment</h4>
              </Col>
              <Col className="mt-2" lg={4} md={4} sm={4} xs={4} style={{textAlign:"center"}}>
                <img className="growOnHover growOnHover2" src={topupLoansIcon}/>
                <h4 className="mt-2 imgTitle" >Top - Up  Loans</h4>
              </Col>
              <Col className="mt-2" lg={4} md={4} sm={4} xs={4} style={{textAlign:"center"}}>
                <img className="growOnHover growOnHover2" src={customerSupportIcon}/>
                <h4 className="mt-2 imgTitle">Customer Support</h4>
              </Col>
            </Row>
          </section>
          <section id="educationalContent" style={{marginTop:"6rem"}}>
            <h3 className="platform-headings"> Educational Content  </h3>
            <p className="mt-2 platform-paras"> Our application also provides the borrowers with informative and 
              educative bite sized videos on financial products to enable them to make an informed decision.
            </p>
            <Row className="my-5">
              <Col>
                <img className="growOnHover growOnHover2" src={animatedContentIcon}/>
                <h4 className="mt-3 imgTitle" style={{position:"relative", top:"-10px"}}>Engaging Animated Content</h4>
              </Col>
              <Col>
                <img className="growOnHover growOnHover2" src={tutorialVideosIcon}/>
                <h4 className="mt-3 imgTitle" style={{position:"relative", top:"15px"}}>Tutorial Videos</h4>
              </Col>
              <Col>
                <img className="growOnHover growOnHover2" src={gamesQuizzesIcon}/>
                <h4 className="mt-3 imgTitle">{"Games & Quizes"}</h4>
              </Col>
              <Col className="d-none d-xl-block"></Col>
              <Col className="d-none d-xl-block"></Col>
            </Row>
          </section>
        </Container>
        <Row style={{marginTop:"1rem"}}>
          <div className="row weMFI vitto-dashboard-designed vitto-app-designed" style={{backgroundColor:"#EC224E", boxShadow:"0px 4px 4px rgba(0, 0, 0, 0.25)", color:"white", 
            borderTopRightRadius:"25px", borderBottomRightRadius:"25px"}}>
            <div className="col-desktop-3 col-tablet-5 col-mobile-4 px-0" style={{maxWidth:"270px"}}></div>
            <div className="col-desktop-4 col-tablet-7 col-mobile-8 px-0 d-flex align-items-center lytLogoCol">
              <img src={lytLogo}></img>
              <span className="pt-2 sub-text1">&nbsp;DASHBOARD</span>
            </div>
            <div className="col-desktop-4 d-none d-xl-block px-0 pt-2 d-flex align-items-center sub-text2" style={{marginLeft:"30px"}}><p className="mt-1"> (Designed for lenders)</p></div>
            <Row>
              <div className=" col-tablet-5 col-mobile-4 col-desktop-0 px-0"></div>
              <div className="col-tablet-7 col-mobile-8 d-lg-block d-xl-none px-0 pt-2 d-flex align-items-center sub-text2"><p> (Designed for lenders)</p></div>
            </Row>
          </div>
        </Row>
        <img data-aos="fade-right" className="laptop" data-aos-offset="200" data-aos-duration="1200" data-aos-easing="ease-in-sine" src={laptopIcon} style={{transform: "scale(0.9)"}} ></img>
        <img data-aos="fade-right" data-aos-offset="120" className="laptop-screen" data-aos-duration="1200" data-aos-easing="ease-in-sine" src={dashboardScreenshot} style={{transform: "scale(0.9)"}}></img>
        <Container className="pb-5  menuContainer2"  style={{marginTop:"-130px"}}>
          <section id="liveTracking">
            <h3 className="platform-headings"> {"Live Tracking & Dynamic"} </h3>
            <p className="mt-2 platform-paras">Our partner web dashboard provides you with the live details 
              and actionable insights at branch/field officer level to make your business more 
              efficient and smart!
            </p>
            <Row className="my-5 d-none d-xl-flex">
              <Col>
                <img className="growOnHover growOnHover2" src={portfolioPerformanceIcon}/>
                <h4 className="mt-3 imgTitle" style={{position:"relative", top:"15px"}}>Portfolio Performance</h4>
              </Col>
              <Col>
                <img className="growOnHover growOnHover2" src={customerExposureIcon}/>
                <h4 className="mt-3 imgTitle" style={{position:"relative", top:"22px"}}>{"Customer & JLG Exposures"}</h4>
              </Col>
              <Col>
                <img className="growOnHover growOnHover2" src={deliquencyReportsIcon}/>
                <h4 className="mt-3 imgTitle" >Deliquency Reports</h4>
              </Col>
              <Col>
                <img className="growOnHover growOnHover2" src={customerHistoryIcon}/>
                <h4 className="mt-3 imgTitle" style={{position:"relative", top:"20px"}}>Customer Historic Performance</h4>
              </Col>
              <Col></Col>
            </Row>
            <Row className="my-5 d-inherit d-xl-none">
              <Col className="mt-2" lg={4} md={4} sm={4} xs={4} style={{textAlign:"center"}}>
                <img className="growOnHover growOnHover2" src={portfolioPerformanceIcon}/>
                <h4 className="mt-3 imgTitle" style={{position:"relative", top:"15px"}}>Portfolio Performance</h4>
              </Col>
              <Col lg={4} md={4} sm={4} xs={4} style={{textAlign:"center"}}>
                <img className="growOnHover growOnHover2" src={customerExposureIcon}/>
                <h4 className="mt-3 imgTitle" style={{position:"relative", top:"22px"}}>{"Customer & JLG Exposures"}</h4>
              </Col>
              <Col lg={4} md={4} sm={4} xs={4} style={{textAlign:"center"}}>
                <img className="growOnHover growOnHover2" src={deliquencyReportsIcon}/>
                <h4 className="mt-3 imgTitle" >Deliquency Reports</h4>
              </Col>
              <Col className="mt-4" lg={4} md={4} sm={4} xs={4} style={{textAlign:"center"}}>
                <img className="growOnHover growOnHover2" src={customerHistoryIcon}/>
                <h4 className="mt-3 imgTitle" style={{position:"relative", top:"20px"}}>Customer Historic Performance</h4>
              </Col>
              <Col></Col>
            </Row>
          </section>
          <section id="fraudDetection" style={{marginTop:"6rem"}}>
            <h3 className="platform-headings"> Early Fraud Detection  </h3>
            <p className="mt-2 platform-paras"> Our face recognition technology detects fake identification 
              even before onboarding is completed.
            </p>
            <Row className="my-5">
              <Col>
                <img className="growOnHover growOnHover2" src={dataScanningIcon}/>
                <h4 className="mt-3 imgTitle" style={{position:"relative", top:"6px"}}>ID Data Scanning</h4>
              </Col>
              <Col>
                <img className="growOnHover growOnHover2" src={facialRecognitionIcon}/>
                <h4 className="mt-3 imgTitle">Facial Recognition</h4>
              </Col>
              <Col>
                <img className="growOnHover growOnHover2" src={fraudDetectionIcon}/>
                <h4 className="mt-3 imgTitle">Instant Fraud Detection</h4>
              </Col>
              <Col className="d-none d-xl-block"></Col>
              <Col className="d-none d-xl-block"></Col>
            </Row>
          </section>
          <section id="inAppCRM" style={{marginTop:"6rem", marginBottom:"3rem"}}>
            <h3 className="platform-headings"> In - App CRM  </h3>
            <p className="mt-2 platform-paras"> Our face recognition technology detects fake identification 
              even before onboarding is completed.
            </p>
            <Row className="my-5">
              <Col>
                <img className="growOnHover growOnHover2" src={inAppCRMIcon}/>
                <h4 className="mt-3 imgTitle">Interactive chat assistant</h4>
              </Col>
              <Col></Col>
              <Col></Col>
              <Col className="d-none d-xl-block"></Col>
              <Col className="d-none d-xl-block"></Col>
            </Row>
          </section>
        </Container>
      </Container>
    )
  }
}
export default thePlatform;