import React from "react";
import { Container } from "reactstrap";
import Navigationbar from "./components/navbar";
import Footer from "./components/footer";
import "./styles/_display-utillity-styles.scss";

class DefaultLayout extends React.Component {
  render() {
    return (
      <Container
        fluid
        id="parent-container"
        className="m-0 p-0"
        style={{ overflowX: "hidden", overflowY:"hidden" }}
      >
        <Container
          fluid
          className="mb-5 p-0"
          style={{ position: "fixed", backgroundColor: "white", zIndex: "10", borderBottom:"1px solid #EC224E" }}
        >
          <Navigationbar />
          <hr className="m-0 p-0 display-on-phone" />
        </Container>
        <div className="px-0 navbar-padding">
          {this.props.children}
        </div>
        <Footer />
      </Container>
    );
  }
}

export default DefaultLayout;
