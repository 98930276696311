import React from "react";
import { Container, Row, Col } from "reactstrap";
import "./styles/career-details.scss";
import careerDetailsJSON from "../assets/jsons/career-details.json";
import lia4 from "../assets/images/lia4.svg";
const defaultCareerDetailsTemplate = {
  name: "",
  requiredQualification: "",
  requiredExperience: "",
  whatToExpect: [],
  skillsRequired: [],
  formLink: "",
};

class CareerDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      careerDetails: defaultCareerDetailsTemplate,
      present: false,
    };
  }
  componentDidMount() {
    window.scrollTo(0, 0);
    let pathSplits = window.location.pathname.split("/");
    let careerDetails = careerDetailsJSON[pathSplits[pathSplits.length - 1]]
      ? careerDetailsJSON[pathSplits[pathSplits.length - 1]]
      : defaultCareerDetailsTemplate;
    let present = !(careerDetails === defaultCareerDetailsTemplate);
    this.setState({ careerDetails, present });
  }
  render() {
    return (
      <Container fluid className="career-details">
        <Col md={11} lg={8} className="m-auto pt-4">
          {this.state.present ? (
            <>
              <p
                className="career-details-heading mb-5"
                style={{ color: "#194379" }}
              >
                {this.state.careerDetails.name}
              </p>
              <p className="career-details-heading">
                Required Educational Qualifications
              </p>
              <p className="mb-4">
                {this.state.careerDetails.requiredQualification}
              </p>
              <p className="career-details-heading">Required Experience</p>
              <p className="mb-4">
                {this.state.careerDetails.requiredExperience}
              </p>
              <p className="career-details-heading">
                What you will be doing here
              </p>
              <ul>
                {this.state.careerDetails.whatToExpect.map((expectation) => {
                  return <li className="my-2">{expectation}</li>;
                })}
              </ul>
              <br />
              <p className="career-details-heading">Skills Required</p>
              <ul>
                {this.state.careerDetails.whatToExpect.map((expectation) => {
                  return <li className="my-2">{expectation}</li>;
                })}
              </ul>
              <br />
              <br />
              <p className="career-details-heading">
                If this sounds interesting to you then please fill out the{" "}
                <a
                  href={this.state.careerDetails.formLink}
                  target="_blank"
                  rel="noreferrer"
                >
                  form
                </a>
                , if you're a good fit we'll certainly reach out to you.
              </p>
              <br />
              <br />
              <br />
              <br />
            </>
          ) : (
            <Container fluid className="d-flex flex-column my-5 py-5">
              <Col md={6} className="m-auto">
                <Row className="jusitfy-content-evenly">
                  <p
                    className="career-details-heading"
                    style={{ width: "fit-content" }}
                  >
                    Sorry!, we don't hold this offer now
                  </p>
                  <div style={{ width: "90px", height: "150px" }}>
                    <img src={lia4} alt="lia4" />
                  </div>
                </Row>
              </Col>
            </Container>
          )}
        </Col>
      </Container>
    );
  }
}

export default CareerDetails;
