import React from "react";
import { Container, Row, Col } from "reactstrap";

class LendboxAgreementHindi extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <Container fluid className="p-3" style={{ maxWidth: "1000px", lineHeight:"1.5" }}>
        <center><h3><b><u>LENDBOX AGREEMENT</u></b></h3></center><br/><br/>
        <p>
          यह ऋण समझौता ("अनुबंध") इस <b> «&#10100; तिथि &#10101;» </b> दिन <b> «&#10100; माह &#10101;»</b>, <b> «&#10100; वर्ष &#10101;» </b> पर <b>दिल्ली</b> में निष्पादित किया जाता है 
        </p>
        <br/>
        <p><center>द्वारा और के बीच</center></p>
        <br />
        <p>
          भारतीय रिजर्व बैंक द्वारा लाइसेंस प्राप्त एक पी2पी एनबीएफसी, ट्रांसएकट्री टेक्नोलॉजीज प्राइवेट लिमिटेड के साथ पंजीकृत ऋणदाता, <a href="https://www.lendbox.in" target="_blank">www.lendbox.in</a> प्लेटफॉर्म पर;
        </p>
        <br/>
        <p><center>तथा</center></p>
        <br />
        <p>
          <b>«&#10100; BorTitle &#10101;»</b> <b>«&#10100; BorName &#10101;»</b>, पैन रखने वाला एक भारतीय नागरिक <b>«&#10100; BorPan &#10101;»</b>, <b>«&#10100; noitaler &#10101;»</b> <b>«&#10100; BorFather &#10101;»</b>, <b>«&#10100; BorAddress &#10101;»</b> का निवासी, जिसे इसके बाद "उधारकर्ता" के रूप में जाना जाता है। अभिव्यक्ति जब तक कि संदर्भ के प्रतिकूल न हो, का अर्थ होगा और इसमें इसके कानूनी प्रतिनिधि, अनुमत समनुदेशिती और उत्तराधिकारी शामिल हैं।
        </p>
        <br />
        <br />
        <br />
        <p>
          ऋणदाता और उधारकर्ता को इसके बाद व्यक्तिगत रूप से <b>"पार्टी"</b> और सामूहिक रूप से <b>"पार्टियों"</b> के रूप में संदर्भित किया जाता है।
        </p>
        <br />
        <p>
          <b>जबकि</b> पार्टियों ने इसके बाद में निहित नियमों और शर्तों पर इस समझौते में प्रवेश करने के लिए सहमति व्यक्त की है:
        </p>
        <br/>
        <ol type="1">
          <li>
            ऋणदाता ऋणी को उधार देने के लिए सहमत होता है और ऋण लेने वाला <b>«&#10100;LoanAmt&#10101;»</b> की राशि उधार लेने के लिए सहमत होता है, जिसे <b>«&#10100;LoanDur&#10101;»</b> समान मासिक किश्तों (“ईएमआई”) 
            में चुकाने के लिए संवितरण की तारीख से एक महीने से शुरू होता है, यहां अनुसूची 1 में निर्दिष्ट पुनर्भुगतान अनुसूची सहित इस अनुबंध में निर्धारित नियमों और शर्तों के अनुसार प्रति वर्ष <b>«&#10100;LoanRate&#10101;»</b> के ब्याज पर।
          </li>
          <br/>
          <li>
            इस ऋण का ऋणदाता कुल ऋण राशि के आधार पर एक से अधिक हो सकता है। ऋणदाता की विस्तृत जानकारी के लिए देखें <a href="https://www.lendbox.in" target="_blank">www.lendbox.in</a>
          </li>
          <br/>
          <li>
            लेंडबॉक्स ने <b>XXX</b> (बाद में "पार्टनर" के रूप में संदर्भित) के साथ एक समझौता किया है। उधारकर्ता पार्टनर के प्लेटफॉर्म पर पंजीकृत ग्राहक है और दोनों पक्ष सहमत हैं कि इस समझौते की शर्तें लेंडबॉक्स 
            और पार्टनर के बीच हस्ताक्षरित XX/XX/202X के सेवा अनुबंध में निर्धारित नियमों और शर्तों के अधीन हैं - अनुबंध 1 में विस्तार से वर्णित है
          </li>
          <br/>
          <li>
            पार्टियां स्वीकार करती हैं कि लेन-देन और पुनर्भुगतान के लेन-देन को <b>ट्रांजैक्ट्री टेक्नोलॉजीज प्राइवेट लिमिटेड</b> (इसके बाद <b>"लेंडबॉक्स"</b>) द्वारा उनके प्लेटफॉर्म <a href="https://www.lendbox.in" target="_blank">www.lendbox.in</a> के माध्यम से सुगम बनाया जा रहा है।
          </li>
          <br/>
          <li>
            ऋणदाता राशि को लेंडबॉक्स द्वारा संचालित अद्वितीय वर्चुअल एस्क्रो खाते में जमा करेगा जिसका विवरण ऋणदाता को प्रदान किया गया है। किसी भी बैंकिंग अवकाश के अधीन, ऋणदाता द्वारा एस्क्रो खाते के वित्त 
            पोषण के समय से 48 घंटे के बाद ऋणदाता द्वारा राशि को पार्टनर के खाते में स्थानांतरित कर दिया जाएगा और इस तरह की देरी से ईएमआई की देय तिथि किसी भी तरह से नहीं बदलेगी। उधारकर्ता के 
            खाते में राशि स्थानांतरित करना भागीदार की ज़िम्मेदारी है। उधारकर्ता पार्टनर के साथ ईएमआई जमा करेगा और पार्टनर इन ईएमआई को लेंडबॉक्स द्वारा निर्दिष्ट खाते में ट्रांसफर करेगा, जो पुनर्भुगतान के लिए 
            लेंडबॉक्स का एस्क्रो खाता है। ऋणदाता की ओर से सिस्टम द्वारा इनपुट के रूप में ईएमआई प्रारंभ होने की तारीख के आधार पर प्रत्येक कैलेंडर माह की ईएमआई ("ईएमआई देय तिथि") के लिए नियत 
            तारीख पर।
          </li>
          <br/>
          <li>
            संपार्श्विक के रूप में उधारकर्ता ऋण चुकाने के लिए लेंडबॉक्स को 2 (दो) चेक प्रदान करेगा। ऋणी ऋण के संवितरण के साथ-साथ, राष्ट्रीय स्वचालित समाशोधन गृह अधिदेश <b>"एनएसीएच मैंडेट"</b> पर हस्ताक्षर करेगा 
            और सौंपेगा, जैसा कि लेंडबॉक्स या लेंडबॉक्स के अधिकृत प्रतिनिधि को अधिकृत करने के लिए भारतीय राष्ट्रीय भुगतान निगम द्वारा लागू किया गया है। ईएमआई देय तिथि से एक दिन पहले उधारकर्ता के पसंद के 
            बैंक खाते से ईएमआई।
          </li>
          <br/>
          <li>
            ऋणी समान मासिक किश्तों (ईएमआई) के माध्यम से ऋण का भुगतान (ब्याज, अतिरिक्त ब्याज और अन्य शुल्क सहित) देय महीने के माध्यम से करेगा, जो ऋण के वितरण के महीने के बाद के महीने से 
            शुरू होगा। ईएमआई की तारीख वह तारीख होगी जिस दिन ऋण वितरित किया जाता है। उदाहरण के लिए, यदि कोई ऋण 25 तारीख को वितरित किया जाता है, तो ईएमआई की तारीख भी हर महीने की
            25 तारीख होगी। ईएमआई ऋण की पूर्ण चुकौती (ब्याज, अतिरिक्त ब्याज और उस पर अन्य शुल्क सहित) तक जारी रहेगी। यह खंड पुनर्भुगतान अनुसूची में निर्धारित तिथियों और अनुबंध में किसी भी अन्य 
            विरोधाभासी खंड (ओं) को अधिक्रमित और अधिलेखित करता है।
          </li>
          <br/>
          <li>
            उधारकर्ता समझता है कि उधारकर्ता के खाते को एनएसीएच आदेश के आधार पर एक स्वचालित प्रणाली द्वारा काट लिया जाएगा और यह सुनिश्चित करने के लिए सहमत है कि खाते में ईएमआई की
            देय तिथि से 1 व्यावसायिक दिन पहले पर्याप्त शेष राशि है।
          </li>
          <br/>
          <li>
            ऋणी द्वारा ईएमआई का भुगतान करने में विफल रहने की स्थिति में, उधारकर्ता संपूर्ण बकाया ऋण राशि पर 18% प्रति वर्ष (<b>"अतिरिक्त ब्याज"</b>) की दर से अतिरिक्त ब्याज का भुगतान करने के लिए 
            उत्तरदायी होगा, साथ ही इसके अनुसार अर्जित ब्याज अंतिम चुकौती की तारीख तक अनुसूची 1 में निर्दिष्ट चुकौती अनुसूची और रु. 500/- (रुपये पांच सौ) हर बार चेक या एनएसीएच मैंडेट का सम्मान 
            नहीं किया जाता है।
          </li>
          <br/>
          <li>
            ऋणी, लागू कानून के अधीन, अनुसूची 1 में निर्दिष्ट पुनर्भुगतान अनुसूची के अनुसार किसी भी समय ब्याज, अतिरिक्त ब्याज और अन्य शुल्कों के साथ संपूर्ण बकाया ऋण राशि का पूर्व भुगतान कर सकता है।
          </li>
          <br/>
          <li>
            प्रत्येक पक्ष प्रतिनिधित्व करता है और वारंट करता है कि वह इस समझौते में प्रवेश करने और इसमें निहित दायित्वों को पूरा करने का हकदार है। ऋणदाता प्रतिनिधित्व करता है और वारंट करता है कि 
            खंड 1 में निर्दिष्ट ऋण के लिए धन इस समझौते की तारीख को या उससे पहले लेंडबॉक्स के एस्क्रो खाते में तैयार और उपलब्ध होगा, जिसके विफल होने पर चुकौती अनुसूची और ईएमआई देय तिथि 
            बदल जाएगी .
          </li>
          <br/>
          <li>
            प्रत्येक पक्ष आगे प्रतिनिधित्व करता है और वारंट करता है कि इस समझौते पर लागू होने वाले संबंधित हस्ताक्षरकर्ता, पार्टी पर बाध्यकारी तरीके से इसे निष्पादित करने के लिए विधिवत अधिकृत हैं और 
            इस तरह के अधिकार को अपने हस्ताक्षरकर्ता में निहित करने के लिए आवश्यक सभी अनुमोदन और प्रक्रियाएं विधिवत प्राप्त की गई हैं और के साथ अनुपालन।
          </li>
          <br/>
          <li>
            निम्नलिखित में से किसी भी घटना, या उससे मिलती-जुलती घटनाओं की घटना, प्रत्येक डिफ़ॉल्ट की घटना (<b>"डिफ़ॉल्ट की घटना"</b>) का गठन करेगी:
            <ol type="a">
              <li>
                ऋणी इस समझौते के अनुसार देय और देय किसी भी राशि का भुगतान इस समझौते की शर्तों के अनुसार उस तारीख को नहीं करता है जब ऐसी राशि देय होती है;
              </li>
              <br/>
              <li>
                इस समझौते के तहत अपने दायित्वों, वाचाओं, वारंटी, उपक्रमों में से किसी का पालन करने में उधारकर्ता द्वारा उल्लंघन, या पालन करने में चूक या चूक;
              </li>
              <br/>
              <li>
                इस समझौते में उधारकर्ता द्वारा दिया गया कोई भी प्रतिनिधित्व या बयान या माना गया कोई अन्य दस्तावेज या किसी अन्य दस्तावेज के तहत या समझौते के संबंध में या उसके संबंध में उधारकर्ता 
                की ओर से दिया गया या गलत या भ्रामक साबित हुआ जब इसे बनाया गया या समझा गया बना हुआ; या
              </li>
              <br/>
              <li>
                उधारकर्ता को दिवालिया घोषित कर दिया जाता है या दिवालियेपन या किसी कुर्की, संकट निष्पादन या ऐसी कोई अन्य प्रक्रिया के लिए उधारकर्ता के खिलाफ फाइल शुरू की जाती है।
              </li>
            </ol>
          </li>
          <br/>
          <li>
            डिफ़ॉल्ट की घटना होने पर, ऋणदाता की ओर से ऋणदाता और लेंडबॉक्स, उधारकर्ता को कोई नोटिस/डिफ़ॉल्ट नोटिस देने की आवश्यकता के बिना:
            <ol type="a">
              <li>
                घोषित करें कि ऋण राशि, उपार्जित ब्याज, अतिरिक्त ब्याज और इस समझौते के तहत अर्जित या बकाया अन्य सभी राशियों के साथ तुरंत देय और देय हो, 
                जिस पर वे तुरंत देय और देय हो जाएंगे;
              </li>
              <br/>
              <li>
                नकदीकरण या नकदीकरण के लिए उपस्थित, ऋणदाता को बकाया किसी भी या सभी राशियों की वसूली के लिए संपार्श्विक के रूप में प्रदान किए गए अदिनांकित, 
                उत्तर दिनांकित, हस्ताक्षरित चेक; या
              </li>
              <br/>
              <li>
                ऐसे अन्य अधिकारों का प्रयोग करें जो लागू कानून के तहत ऋणदाता को उपलब्ध हों।
              </li>
            </ol>
          </li>
          <br/>
          <li>
            यह समझौता इस समझौते के निष्पादन की तिथि पर लागू होगा और सभी ब्याज, अतिरिक्त ब्याज, बकाया, शुल्क, करों के साथ ऋण राशि के पूरे हिस्से की चुकौती होने तक पूरी तरह से लागू रहेगा।
          </li>
          <br/>
          <li>
            पक्ष सहमत हैं और वचन देते हैं कि वे इस समझौते की निरंतरता के दौरान, हर समय, यह सुनिश्चित करेंगे कि इस समझौते के सभी नियम और शर्तें और ऋण राशि से संबंधित सभी जानकारी 
            को गोपनीय रखा जाएगा ("गोपनीय जानकारी") और वे इस समझौते से संबंधित किसी भी जानकारी को अन्य पक्ष की पूर्व लिखित सहमति के बिना, संबंधित जानकारी के अलावा किसी भी तीसरे 
            पक्ष को प्रकट नहीं करेगा:
            <ol type="a">
              <li>
                इससे संबंधित जानकारी और डेटा;
              </li>
              <br/>
              <li>
                किसी भी क्रेडिट सुविधा से संबंधित जानकारी या डेटा जो इसके द्वारा प्राप्त/प्राप्त की जानी है;
              </li>
              <br/>
              <li>
              चूक अगर कोई है जो हमारे ऐसे दायित्व के निर्वहन में प्रतिबद्ध है, जैसा कि ऋणदाता उचित और आवश्यक समझे और क्रेडिट सूचना एजेंसियों और भारतीय रिजर्व बैंक द्वारा इस संबंध में 
              अधिकृत किसी भी अन्य एजेंसी को प्रकट करने और प्रस्तुत करने के लिए आवश्यक हो; जिसे गोपनीय प्रकृति का नहीं माना जाएगा।
              </li>
            </ol>
          </li>
          <br/>
          <li>
            उधारकर्ता सहमत है और वचन देता है कि इस समझौते के अनुसार और उसके संबंध में ऋणदाता और लेंडबॉक्स को दी गई जानकारी और डेटा सही और सही है और सहमति है कि ऋणदाता या 
            लेंडबॉक्स द्वारा अधिकृत कोई भी एजेंसी जानकारी का उपयोग और संसाधित कर सकती है और इस समझौते के तहत उपलब्ध कराए गए डेटा।
          </li>
          <br/>
          <li>
            ऋणी ऋणदाता को ऋण देने के परिणामस्वरूप उत्पन्न होने वाले या उत्पन्न होने वाले किसी भी और सभी नुकसान, क्षति या अन्य परिणामों से और उसके विरुद्ध ऋणदाता और लेंडबॉक्स को क्षतिपूर्ति 
            करेगा और रखेगा और ऐसी सभी राशियों की मांग करने पर ऋणदाता और/या लेंडबॉक्स की प्रतिपूर्ति करेगा और अनुरोध करने पर उधारकर्ता की अपनी लागत और खर्च पर किसी भी कार्रवाई का 
            बचाव करेगा जो कि इसके संबंध में ऋणदाता के खिलाफ लाया जा सकता है और पार्टी द्वारा प्रस्तुत खाते के विवरण को स्वीकार करेगा जो नुकसान का दावा करने वाले किसी भी दावे की शुद्धता के 
            निर्णायक सबूत के रूप में होगा। उसके कारण हो।
          </li>
          <br/>
          <li>
            समझौता भारत के कानूनों द्वारा शासित होगा और [दिल्ली] की अदालतों के पास इस समझौते से या इसके संबंध में उत्पन्न होने वाले किसी भी विवाद को निपटाने का विशेष अधिकार क्षेत्र 
            होगा। पक्ष अन्य न्यायालयों के बहिष्करण के लिए, उस क्षेत्राधिकार को प्रस्तुत करने के लिए अपरिवर्तनीय रूप से सहमत हैं।
          </li>
          <br/>
          <li>
            ऋणी प्रतिपूर्ति करेगा और ऋणदाता को सभी लागतों, शुल्कों और खर्चों का भुगतान करेगा, जिसमें वास्तविक आधार पर स्टांप शुल्क और कानूनी लागत और अन्य शुल्क और खर्च शामिल हैं 
            जो इन उपहारों को तैयार करने और संबंधित और या प्रासंगिक दस्तावेजों और प्रवर्तन के लिए खर्च किए जा सकते हैं। उसके तहत ऋणदाता के अधिकार या ऋणदाता के पक्ष में उधारकर्ता द्वारा 
            निर्मित/निष्पादित कोई अन्य दस्तावेज़ सुरक्षा।
          </li>
          <br/>
          <li>
            उधारकर्ता इस समझौते के तहत अपने किसी भी अधिकार या दायित्व को निर्दिष्ट नहीं करेगा। ऋणदाता इस अनुबंध और इसके अंतर्गत सभी अधिकारों को असाइन कर सकता है या उधारकर्ता की पूर्व 
            सहमति के बिना, लेंडबॉक्स के माध्यम से इस समझौते के पूरे या किसी हिस्से का लाभ किसी तीसरे पक्ष को हस्तांतरित कर सकता है। लेंडबॉक्स ऐसे असाइनमेंट के 5 कार्य दिवसों के भीतर अपने 
            प्लेटफॉर्म <a href="https://www.lendbox.in" target="_blank">www.lendbox.in</a> के माध्यम से उधारकर्ता को इस तरह के असाइनमेंट के बारे में सूचित करेगा।
          </li>
          <br/>
          <li>
            यहां कुछ भी शामिल होने के बावजूद, पार्टियां सहमत हैं कि उन्होंने लेंडबॉक्स को इस समझौते के तहत देय और देय किसी भी राशि की वसूली के लिए वसूली एजेंट ("वसूली एजेंट") के रूप में
            एक उपयुक्त एजेंसी नियुक्त करने के लिए अधिकृत किया है। वसूली एजेंट ऐसे सभी उचित कदम उठाएंगे जो वसूली के लिए आवश्यक हो सकते हैं; तथापि, वसूली एजेंट देय राशि की सुनिश्चित 
            वसूली की गारंटी नहीं देते हैं। वसूली एजेंट द्वारा लगाए जा रहे प्रभार ऋणदाता के खाते में होंगे। पार्टियां समझती हैं कि लेंडबॉक्स केवल एक डिफ़ॉल्ट के कारण वसूली में अपनी सहायता की 
            पेशकश कर रहा है और किसी भी तरह से किसी भी डिफ़ॉल्ट के लिए उत्तरदायी नहीं है, संभावित उधारदाताओं और उधारकर्ताओं से मिलने और बातचीत करने के लिए एक बाज़ार होने के नाते।
          </li>
          <br/>
          <li>
            इस समझौते द्वारा ऋणदाता को दिए गए अधिकार, शक्तियां और उपचार किसी अन्य सुरक्षा, क़ानून या कानून के शासन के आधार पर ऋणदाता को दिए गए सभी अधिकारों, शक्तियों और 
            उपचारों के अतिरिक्त होंगे।
          </li>
          <br/>
          <li>
            पक्ष इस बात की पुष्टि करते हैं और स्वीकार करते हैं कि यह समझौता उनके बीच पूरे समझौते का गठन करेगा और इस समझौते की विषय वस्तु के संबंध में पार्टियों के बीच मौखिक या लिखित 
            सभी पिछले संचारों का स्थान लेगा और ओवरराइड करेगा, और कोई समझौता या समझ अलग-अलग नहीं होगी या इसका विस्तार करना किसी भी पार्टी के लिए बाध्यकारी होगा जब तक कि इस 
            समझौते के विशिष्ट प्रावधानों से उत्पन्न न हो।
          </li>
          <br/>
          <li>
            इस समझौते में कोई भी संशोधन, संशोधन या परिवर्धन यहां सभी पार्टियों के लिए बाध्यकारी नहीं होगा जब तक कि लिखित रूप में निर्धारित और उनके द्वारा या उनके विधिवत अधिकृत प्रतिनिधियों के 
            माध्यम से निष्पादित नहीं किया जाता है।
          </li>
          <br/>
          <li>
            यदि इस समझौते का कोई भी शब्द, प्रावधान, वाचा या प्रतिबंध सक्षम अधिकार क्षेत्र के न्यायालय द्वारा अमान्य, शून्य या अप्रवर्तनीय माना जाता है, तो इस समझौते के शेष नियम, प्रावधान, अनुबंध 
            और प्रतिबंध पूर्ण बल और प्रभाव में रहेंगे और किसी भी तरह से प्रभावित, बिगड़ा या अमान्य नहीं होगा।
          </li>
          <br/>
          <li>
            इसके तहत कोई भी नोटिस या मांग लिखित रूप में और ऊपर निर्दिष्ट पते पर डाक के माध्यम से या नोटिस भेजने वाली पार्टी को अंतिम रूप से ज्ञात पते पर भेजी जाएगी। इस समझौते के 
            तहत भेजे गए किसी भी नोटिस को नोटिस के प्रेषण के 2 व्यावसायिक दिनों के बाद वितरित माना जाएगा।
          </li>
        </ol>
        <br />
        <br />
        <br />
        <br />
        <p>
          इसके साक्ष्य में, पार्टियों ने इस अनुबंध को पहले दिन और वर्ष को ऊपर लिखा है और निष्पादित और वितरित किया है।
        </p>
        <br/>
        <br/>
        <br/>
        <Row>
          <Col lg={3}>
            <p style={{fontSize:"14px"}}>
              ऋणदाता के लिए और उसकी ओर से   <br/> <br/>
              ………………………………………………   <br/> <br/>
              <b>«&#10100; उपयोगकर्ता नाम &#10101;»</b>
            </p>
          </Col>
          <Col lg={6}>
          </Col>
          <Col lg={3}>
            <p style={{fontSize:"14px"}}>
              उधारकर्ता के लिए और उसकी ओर से <br/> <br/>
              ………………………………………………   <br/> <br/>
              <b>«&#10100; उधारकर्ता का नाम &#10101;»</b>
            </p>
          </Col>
        </Row>
        <br/>

        <h><u><b><center style={{paddingTop:"200px"}}>अनुसूची I </center></b></u></h>
        <br/>
        <b><center style={{fontSize:"16px"}}>पुनः भुगतान कार्यक्रम</center></b>
        <br/>
        <p>
          उधारकर्ता का नाम: <b>«&#10100;BorName&#10101;»</b> <br/>
          ऋण राशि: <b>«&#10100;LoanAmt&#10101;»</b> <br/>
          भागीदार/ऋण ब्याज दर: <b>«&#10100;LoanRate&#10101;»</b> per annum <br/>
          ब्याज की ऋणदाता दर: <br/>
          कार्यकाल: <b>«&#10100;LoanDur&#10101;»</b> <br/>
          ईएमआई प्रति माह: <b>«&#10100;EMIVal&#10101;»</b> <br/>
        </p>
        <br />
        <br/>
        <table style={{border:"solid 1px black", width:"100%"}}>
          <tr>
            <th className="ps-2" style={{border:"solid 1px black"}}>महीना </th>
            <th className="ps-2" style={{border:"solid 1px black"}}>महीना  #</th>
            <th className="ps-2" style={{border:"solid 1px black"}}>ईएमआई </th>
            <th className="ps-2" style={{border:"solid 1px black"}}>मूलधन</th>
            <th className="ps-2" style={{border:"solid 1px black"}}>ब्याज</th>
            <th className="ps-2" style={{border:"solid 1px black"}}>शेष</th>
          </tr>
          <tr>
            <td className="ps-2" style={{border:"solid 1px black"}}>Ib_month</td>
            <td className="ps-2" style={{border:"solid 1px black"}}>Ib_month_no</td>
            <td className="ps-2" style={{border:"solid 1px black"}}>Ib_emi</td>
            <td className="ps-2" style={{border:"solid 1px black"}}>Ib_princi</td>
            <td className="ps-2" style={{border:"solid 1px black"}}>Ib_inter</td>
            <td className="ps-2" style={{border:"solid 1px black"}}>Ib_bal</td>
          </tr>
        </table>
        <br/>
        <br/>
        <p className="px-4" style={{fontSize:"16px"}}>
          <b>कृपया ध्यान दें: वास्तविक ईएमआई प्रारंभ तिथि उस तिथि के आधार पर भिन्न हो सकती है जब ऋणकर्ता को निधि हस्तांतरित की गई हो। 
            बैंक की छुट्टियों, बैंक सिस्टम के बंद होने या इसके उपयोगकर्ताओं द्वारा फंड ट्रांसफर करने में देरी के कारण हस्तांतरण में देरी के लिए 
            लेंडबॉक्स कोई जिम्मेदारी स्वीकार नहीं करता है।
          </b>
        </p>
        <br/>

        <h><u><b><center style={{paddingTop:"200px"}}>अनुलग्नक 1</center></b></u></h>
        <br/>
        <br/>
        <p>
          पार्टनर: <b>XXXXX</b>(सीआईएन –<b>XXXXXX</b>) 
        </p>
        <p style={{marginBottom:"300px"}}>
          लेंडबॉक्स ने अपने ग्राहकों को ईएमआई आधारित ऋण प्रदान करने के लिए <b>XXXXX</b> दिनांक <b>XX/XX/202X</b> के साथ एक सेवा समझौता किया है।
        </p>
        <br />

      </Container>
    );
  }
}

export default LendboxAgreementHindi;
