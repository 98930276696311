import React from "react";
import { Row, Col, Input, Button } from "reactstrap";
import newsletterHomeIcon from "../../assets/images/newsletter-home-icon.png";
import newsLetterIcon from "../../assets/images/newsletterIcon.png";
import "../styles/newsletter-div.scss";

class SubscribeNewsletter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <>
        <Col
          style={{ maxWidth: "500px" }}
          className="d-flex flex-column m-auto"
        >
          <a
            href="https://kapilpatil.substack.com/"
            target="_blank"
            rel="noreferrer"
          >
            <img
              src={newsLetterIcon}
              alt="newsletterIcon"
              width="180px" //Previously was 64px
              className="d-block m-auto"
            />
          </a>
          <p className="home-sub-title-text" style={{ fontSize: "16px" }}>
            Subscribe to our newsletter to get montly updates about our services
            and products. Visit our{" "}
            <a
              href="https://kapilpatil.substack.com/"
              target="_blank"
              rel="noreferrer"
            >
              substack
            </a>{" "}
            for more
          </p>
          <Row className="newsletter-div-row">
            <Input
              className="py-2 mx-1 px-3"
              type="email"
              placeholder="Email"
              style={{ width: "60%", borderRadius: "25px", height: "50px" }}
            />
            <Button
              className="py-2 px-4 mx-1"
              style={{
                maxWidth: "fit-content",
                width: "max-content",
                outlineColor: "#194379",
                border: "0px",
                height: "50px",
                color: "white",
                background:
                  "linear-gradient(90deg, #194379 -103.05%, #6FCCDC 218.29%), #6FCCDC",
                fontFamily: "Inter",
                fontSize: "14px",
                fontWeight: "600",
                borderRadius: "25px",
              }}
            >
              Subscribe
            </Button>
          </Row>
        </Col>
      </>
    );
  }
}
export default SubscribeNewsletter;
