export let redirectTo = () => {
  let origin = window.location.origin;
  return origin + "";
};

export let scrollToBottom = () => {
  window.scrollTo(0, 10000);
};

export let backDropColor = "rgba(0,0,0,0.6)";
